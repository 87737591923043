import React, { useState } from 'react'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import { SEASON_LIST } from '../urls'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import SeasonUpdate from '../components/SeasonUpdate'
import { getDateTime } from '../utils/date'
import { getTypeSeason } from '../utils/position'
import SeasonTabs from '../components/SeasonTabs'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import useTrans from '../hooks/trans'
import { roleMain } from '../utils/role'

export default function Season() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const season = useLoad({ url: SEASON_LIST, params: { page } }, [page])
    const t = useTrans()

    const [showSeasonUpdateModal, hideCreateModal] = useModal(
        <SeasonUpdate onSuccess={() => {
            season.request()
            hideCreateModal()
        }} onCancel={() => {
            hideCreateModal()
        }} />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <SeasonTabs />

            <div className="is-flex is-justify-content-space-between is-align-items-center mt-5 mb-0">
                <h1 className="title has-text-weight-normal"><span>{t('seasons')}</span></h1>

                <Button
                    className="is-pulled-right is-outlined is-link"
                    text={t('change')}
                    onClick={showSeasonUpdateModal} />
            </div>

            <div>
                <Table
                    loading={season.loading}
                    totalCount={season.response ? season.response.count : 0}
                    activePage={page}
                    onPageChange={setPage}
                    items={season.response ? season.response.results : []}
                    columns={{ type: t('period'), createdAt: t('createdAt') }}
                    renderItem={(item) => (
                        <tr key={item.id}>
                            <td>{t(getTypeSeason(item.type))}</td>

                            <td>{getDateTime(item.createdAt)}</td>
                        </tr>
                    )} />
            </div>
        </Layout>
    )
}
