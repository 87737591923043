import React from 'react'
import { NavLink } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'

export default function PlanDistrictItem({ districtItem }) {
    return (
        <tr>
            <td>
                <NavLink className={css(styles.itemLink)} to={`/district/${districtItem.id}/plan`}>
                    {districtItem.name}
                </NavLink>
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    itemLink: {
        color: '#000000',
        ':hover': {
            color: '#0000FF',
        },
    },
})
