import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Input from './common/Input'
import Button from './common/Button'
import useTrans from '../hooks/trans'

export default function UserForm({ onSubmit, loading }) {
    const t = useTrans()

    return (
        <Formik initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="oldPassword"
                    type="password"
                    label={t('addOldPassword')}
                    placeholder={t('placeholderOldPassword')}
                    disabled={loading}
                    validate={required} />

                <Input
                    name="newPassword"
                    type="password"
                    label={t('addNewPassword')}
                    placeholder={t('placeholderNewPassword')}
                    disabled={loading}
                    validate={required} />

                <Input
                    name="confirmPassword"
                    type="password"
                    label={t('addNewPasswordElse')}
                    placeholder={t('placeholderNewPasswordElse')}
                    disabled={loading}
                    validate={required} />

                <Button
                    loading={loading}
                    text={t('save')}
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
