const uz = {
    actions: 'Harakatlar',
    FirstFillCoordinateTable: 'Bu kanal uchun koordinatalar jadvali to\'ldirilmagan!',
    irrigationDay: 'Sug\'orilgan maydon',
    waterConsumptionDay: 'Sarflangan suv miqdori',
    ga: 'ga',
    mln: 'mln m3',
    SFK: 'SFK',
    region: 'Quyidagi tumanlar',
    fillIn: 'kechagi ma\'lumotlarni to\'ldirmadi',
    waterRunOffRegion: 'Mintaqalar bo\'yicha suv oqimi',
    waterRunOffDate: 'Sana bo\'yicha suv oqimi',
    allData: 'Barcha tumanlar uchun ma\'lumotlar to\'ldirilgan',
    forToday: 'Bugungi',
    archive: 'Arxiv',
    control: 'Boshqaruv',
    controlTerritory: 'Hududlarni boshqarish',
    controlPlan: 'Rejalarni boshqarish',
    seasons: 'Davrlar',
    controlIrrigation: 'Sug\'orishlarni boshqarish',
    users: 'Foydalanuvchilar',
    reporters: 'Ma\'sullar',
    reportersTitle: 'Nomi',
    reportersType: 'Turi',
    itb: 'Irrigatsiya tizim boshqarmasi',
    district_irrigation_department: 'Tuman irrigatsiya bulimi',
    deleteReporter: 'Ma\'sulni o\'chirmoqchimisiz?',
    district: 'Tuman',
    listDistricts: 'Tumanlar ro\'yxati',
    listSectorDistricts: 'Tumanlarning sektorlari ro\'yxati',
    listTerritories: 'Hududlar ro\'yxati',
    listSectorTerritories: 'Hududlarning sektorlari ro\'yxati',
    listBranch: 'Sug\'orishni nazorat qilish tizimlari ro\'yxati',
    listChannels: 'Kanallar ro\'yxati',
    territoryReportReplenishment: 'Hisobotni to\'ldirish',
    name: 'Nomlanishi',
    total: 'Jami',
    channel: 'Kanal',
    forMorning: 'Ertalabki',
    forEvening: 'Kechki',
    forAverage: 'O\'rtacha',
    download: 'Yuklab olish',
    add: 'Qo\'shish',
    adding: 'Malumot Qo\'shish',
    cancel: 'Bekor qilish',
    m3: 'm3',
    successSave: 'Muvaffaqiyatli saqlandi',
    noChannels: 'Hech qanday kanal yo\'q. Kanal qo\'shing!!!',
    fillFieldsThenSave: 'Maydonlarni to\'ldiring, keyin saqlang',
    save: 'Saqlash',
    noReport: '{date} sanasida {name} uchun hisobot to\'ldirilmagan!',
    limitExpense: 'Limit va suv sarfi',
    limitWater: 'Suv limiti',
    used: 'Ishlatilgan',
    difference: 'Farq',
    water: 'Suv',
    limit: 'Limit',
    dashboard: 'Dashboard',
    regions: 'Hududlar',
    tib: 'Kanallar',
    season: 'Mavsum',
    logIn: 'Kirish',
    exit: 'Chiqish',
    editing: 'Tahrirlash',
    enterName: 'Nomlanishini kiriting',
    period: 'Davr',
    createdAt: 'Ma\'lumot yaratilgan vaqt',
    vegetation: 'Vegetatsion',
    noVegetation: 'No Vegetatsion',
    change: 'O\'zgartirish',
    irrigationType: 'Sug\'orish turi',
    irrigationCount: 'Sug\'orish miqdori',
    status: 'Holati',
    filledIn: 'To\'ldirilgan',
    m3sek: 'm3/sek',
    grain: 'G\'alla sug\'orish',
    cotton: 'G\'o\'za sug\'orish',
    cottonWash: 'Pushta sug\'orish',
    repeatIrrigation: 'Takroriy sug\'orish',
    garden: 'Tomorqa va boshqalarni sug\'orish',
    preparation: 'Sho\'r yuvishga tayyorlash',
    saltWash: 'Sho\'r yuvish',
    saltWashPlow: 'Shudgor yerlarini sho\'rini yuvish',
    grainPreparation: 'G\'alla sug\'orish qishgi',
    potatoPreparation: 'Kartoshka yerlarini shurini yuvish',
    deleteSector: 'Haqiqatan ham ushbu sektorni arxivlashni xohlaysizmi?',
    addSeason: 'Mavsumda sug\'orishni qo\'shing !',
    addTerritoryToRegion: 'Ushbu hududga hudud qo\'shing.',
    fullDate: '{date} raqamida mintaqa bo\'yicha hisobot tugallanmagan!',
    channels: 'Kanallar',
    required: 'Bu talab qilinadigan maydon',
    changeRegion: 'Hududni o\'zgartirish:',
    branches: 'Filiallar',
    back: 'Orqaga',
    addNewRegion: 'Yangi hudud qo\'shing:',
    fullName: 'Ism',
    surname: 'Familiya',
    role: 'Rol',
    roleProperties: 'Rol xususiyatlari',
    deleteUser: 'Haqiqatan ham foydalanuvchini oʻchirib tashlamoqchimisiz?',
    changeSector: 'Sektorni o\'zgartirish',
    empty: 'Bo\'sh',
    archived: 'Arxiv',
    archiveRegionSector: 'Haqiqatan ham ushbu sektor va uning hududlarini arxivlashni xohlaysizmi?',
    addSector: 'Yangi sektor qo\'shing:',
    position: 'Lavozim',
    informationChanged: 'Ma\'lumotlar o\'zgartirildi',
    passwordChanged: 'Kiritilgan parollar mos emas',
    oldPassword: 'Iltimos, eski parolingizni to\'g\'ri kiriting.',
    newPassword: 'Parol o\'zgartirildi',
    addOldPassword: 'Eski parol',
    placeholderOldPassword: 'Eski parolingizni kiriting',
    addNewPassword: 'Yangi parol',
    placeholderNewPassword: 'Yangi parolni kiriting',
    addNewPasswordElse: 'Yana yangi parol',
    placeholderNewPasswordElse: 'Yangi parolni yana kiriting',
    account: 'Foydalanuvchi hisobi',
    selectStatus: 'Holatlardan kamida bittasini tanlang',
    success: 'Muvaffaqiyatli o\'zgartirildi',
    setting: 'Profil sozlamalari',
    welcome: 'Tizimga xush kelibsiz',
    logInSystem: 'Kirish',
    auth: 'Kirish',
    docs: 'Ma\'lumotnoma',
    categories: 'Kategoriyalar',
    categoriesList: 'Kategoriyalar ro\'yxati',
    topics: 'Mavzular',
    topicList: 'Mazular ro\'yxati',
    title: 'Sarlavha',
    shortTitle: 'Qizqacha sarlavha',
    slug: 'Slug',
    body: 'Ma\'lumot',
    category: 'Kategoriya',
    changeDocs: 'Ma\'lumonomani o\'zgartirish',
    territoryName: 'Hududlar nomi',
    plan: 'Reja',
    irrigation: 'sug\'orish',
    waterLimit: 'Sug\'orish uchun berilgan suv limiti',
    amountWaterPreviousDay: 'O\'tgan kun suv miqdori',
    amountWater: 'Berilgan suv miqdori',
    irrigationCapacity: 'Sug\'orish imkoniyati',
    totalIrrigation: 'Jami sug\'orilgan maydon',
    forOneDay: '1 kunda',
    watermanCount: 'Suvchilar soni',
    themes: 'Temalar',
    primaryTheme: 'Firuza rang',
    linkTheme: 'Baland ko\'k rang',
    infoTheme: 'Havo rang',
    successTheme: 'Yashil rang',
    warningTheme: 'Sariq rang',
    dangerTheme: 'Qizil rang',
    blackTheme: 'Qora rang',
    darkTheme: 'Ochiq qora rang',
    lightTheme: 'Kul rang',
    whiteTheme: 'Oq rang',
    print: 'Chop etish',
    enterTitle: 'Sarlavhani kiriting',
    enterShortTitle: 'Qizqa sarlavhani kiriting',
    enterSlug: 'Slugni kirgizing',
    to: 'Kimga',
    isDoneTitle: 'Bajarildi',
    fileTitle: 'Biriktirilgan fayl',
    listMails: 'Xatlar ro\'yxati',
    sendMail: 'Xatni yuborish',
    isDone: 'Bajarildi',
    mailDelete: 'Siz rostdan ham o\'chirmoqchimisiz?',
    send: 'Yuborish',
    isNotDone: 'Bajarilmagan',
    mails: 'Xatlar',
    listReports: 'Malumotlar ro\'yxati',
    date: 'Sana',
    statusNotFilled: 'Hisobot to\'ldirilmagan.',
    statusWaiting: 'Hisobot tasdiqlanishini kuting. Yuboruvchi {responsible}. Iltimos kuting',
    statusAccepted: 'Hisobot qabul qilindi. Yuboruvchi {responsible}. Qabul qiluvchi {acceptedBy}',
    statusRejected: 'Hisobot qabul qilinmadi. Yuboruvchi {responsible}. Tekshiruvchi {rejectedBy}. Sabab {reason}',
    newReportMessage: '{name} yangi hisobot.',
    newReportLink: 'Hisobotga o\'tish.',
    accept: 'Qabul qilish',
    reject: 'Rad qilish',
    reason: 'Sabab',
    enterReason: 'Sababni kiriting',
    waiting: 'Kutilmoqda',
    pumps: 'Nasoslar',
    pumpStation: 'Nasos stansiyasi',
    listPumpStations: 'Nasos stansiyalari ro\'yxati',
    reservoir: 'Suv omborlari',
    listReservoir: 'Suv omborlari ro\'yxati',
    aggregates: 'Aggregatlar soni',
    energy: 'Sarflanadigan elektr energiyasi quvvati',
    waterCapability: 'Suv chiqarish qobiliyati',
    waterHeight: 'Suv ko\'tarish balandligi',
    totalEnergy: 'Jami elektr energiyasi quvvati',
    totalWaterCapability: 'Jami suv chiqarish qobiliyati',
    mgwt: 'mgWatt/soat',
    metr: 'metr',
    enterAggregates: 'Agreratlar sonini kiriting',
    enterEnergy: 'Elektr energiyasi quvvatini kiriting',
    enterWaterCapability: 'Suv chiqarish qobiliyatini kiriting',
    enterWaterHeight: 'Suv ko\'tarish balandligini kiriting',
    yesterdayMorning: 'O\'tgan kun ertalab',
    lastYear: 'O\'tgan yil',
    fill: 'To\'ldirish',
    filled: 'To\'ldirilgan',
    activeAggregates: 'Ishlab turgan aggregatlar',
    enterActiveAggregates: 'Ishlab turgan aggregatlar sonini kiriting',
    dinnerActiveAggregates: 'Ishlab turgan aggregatlar (kechki)',
    enterDinnerActiveAggregates: 'Kechki shlab turgan aggregatlar sonini kiriting',
    addingPumpStationReport: '{pumpStation} nasos stansiyasining bugungi ma\'lumoti',
    addingReservoirReport: 'Suv omborining bugungi ma\'lumoti',
    maxNumber: 'Limitdan katta bo\'lgan qilmat kiritilishi mumkin emas',
    ru: 'Ruscha',
    uz: 'O\'zbekcha',
    kir: 'Kirilcha (O\'zb)',
    en: 'Inglizcha',
    sources: 'Manbalar',
    recipients: 'Qabul qiluvchilar',
    type: 'Tur',
    knot: 'Gidrouzel',
    branchLevel: 'Koordinatalar jadvali',
    branchLevelCorrection: 'To\'g\'rilash',
    level: 'Darajasi',
    create: 'Yaratish',
    deleteConfirm: 'Haqiqatan ham tizimdan mutloq oʻchirib tashlamoqchimisiz?',
    periods: 'Даврлар',
    channelLevel: 'Kanal darajasi',
    border: 'Chegara kanali',
    reporter: 'Mas\'ul',
    notSelected: 'Belgilanmagan',
    waterIn: 'Suv tushishi',
    waterOut: 'Suv chiqishi',
    totalAmount: 'Umumiy hajm',
    aboutPumpStation: '"{pumpStation}" nasos stansiyasi haqida ma\'lumot',
    aboutChannel: '"{channel}" kanali haqida ma\'lumot',
    aboutKnot: '"{knot}" gidrouzeli haqida ma\'lumot',
    aboutReservoir: '"{reservoir}" suv ombori haqida ma\'lumot',
    close: 'Yopish',
    yes: 'Ha',
    no: 'Yo\'q',
    noInfo: 'Boshqa ma\'lumotlar kiritilmagan',
    showXlsx: 'Excelda ko\'rsatish',
    districts: 'Tumanlar',
    parent: 'Asosiy',
    listHydroposts: 'Gidropostlar Ro\'yxati',
    hydroposts: 'Gidropostlar',
    hydropost: 'Gidropost',
    addingHydropostReport: 'Gidropost hisoboti',
    activePeriods: 'Aktiv davrlar',
    irrigationSystem: 'Irrigatsion tizim boshqarmasi',
    copied: 'Ko\'chirildi',
}

const kir = {
    actions: 'Харакатлар',
    FirstFillCoordinateTable: 'Бу канал учун координаталар жадвали тулдирилмаган!',
    irrigationDay: 'Сугорилган майдон',
    waterConsumptionDay: 'Сарфланган сув микдори',
    ga: 'га',
    mln: 'млн м3',
    SFK: 'СФК',
    region: 'Куйидаги туманлар',
    fillIn: 'кечаги маълумотлаарни тулдирмади',
    waterRunOffRegion: 'Минтақалар бўйича сув оқими',
    waterRunOffDate: 'Сана бўйича сув оқими',
    allData: 'Барча туманлар учун маълумотлар тўлдирилган',
    forToday: 'Бугунги',
    archive: 'Архив',
    control: 'Бошқарув',
    controlTerritory: 'Ҳудудларни бошқариш',
    controlPlan: 'Режаларни бошқариш',
    seasons: 'Даврлар',
    controlIrrigation: 'Суғоришларни бошқариш',
    users: 'Фойдаланувчилар',
    reporters: 'Маъсуллар',
    reportersTitle: 'Номи',
    reportersType: 'Тури',
    itb: 'Ирригация тизим бошкармаси',
    district_irrigation_department: 'Туман ирригация булими',
    deleteReporter: 'Маъсулни учирмоқчимисиз ?',
    district: 'Туман',
    listDistricts: 'Туманлар рўйхати',
    listSectorDistricts: 'Туманларнинг секторлари рўйхати',
    listTerritories: 'Ҳудудлар рўйхати',
    listSectorTerritories: 'Ҳудудларнинг секторлари рўйхати',
    listBranch: 'Суғоришни назорат қилиш тизимлари рўйхати',
    listChannels: 'Каналлар рўйхати',
    territoryReportReplenishment: 'Ҳисоботни тўлдириш',
    name: 'Номланиши',
    total: 'Жами',
    channel: 'Канал',
    forMorning: 'Эрталабки',
    forEvening: 'Кечки',
    forAverage: 'Ўртача',
    download: 'Юклаб олиш',
    add: 'Қўшиш',
    adding: 'Малумот Қўшиш',
    cancel: 'Бекор қилиш',
    m3: 'м3',
    successSave: 'Муваффақиятли сақланди',
    noChannels: 'Ҳеч қандай канал йўқ. Канал қўшинг!!!',
    fillFieldsThenSave: 'Майдонларни тўлдиринг кейин сақланг',
    save: 'Сақлаш',
    noReport: '{date} санасида {name} учун ҳисобот тўлдирилмаган!',
    limitExpense: 'Лимит ва сув сарфи',
    limitWater: 'Сув лимити',
    used: 'Ишлатилган',
    difference: 'Фарқ',
    water: 'Сув',
    limit: 'Лимит',
    dashboard: 'Дашбоард',
    regions: 'Ҳудудлар',
    tib: 'Каналлар',
    season: 'Мавсум',
    logIn: 'Кириш',
    exit: 'Чиқиш',
    editing: 'Таҳрирлаш',
    enterName: 'Номланишини киритинг',
    period: 'Давр',
    createdAt: 'Маълумот яратилган вақт',
    vegetation: 'Вегетацион',
    noVegetation: 'Но Вегетацион',
    change: 'Ўзгартириш',
    irrigationType: 'Суғориш тури',
    irrigationCount: 'Суғориш миқдори',
    status: 'Ҳолати',
    filledIn: 'Тўлдирилган',
    m3sek: 'м3/сек',
    grain: 'Ғалла суғориш',
    cotton: 'Ғўза суғориш',
    cottonWash: 'Пушта суғориш',
    repeatIrrigation: 'Такрорий суғориш',
    garden: 'Томорқа ва бошқаларни суғориш',
    preparation: 'Шўр ювишга тайёрлаш',
    saltWash: 'Шўр ювиш',
    saltWashPlow: 'Шудгор ерларини шўрини ювиш',
    grainPreparation: 'Ғалла суғориш қишги',
    potatoPreparation: 'Картошка ерларини шурини ювиш',
    deleteSector: 'Ҳақиқатан ҳам ушбу секторни архивлашни хоҳлайсизми?',
    addSeason: 'Мавсумда суғоришни қўшинг !!!',
    addTerritoryToRegion: 'Ушбу ҳудудга ҳудуд қўшинг.',
    fullDate: '{date} рақамида минтақа бўйича ҳисобот тугалланмаган!',
    channels: 'Каналлар',
    required: 'Бу талаб қилинадиган майдон',
    changeRegion: 'Ҳудудни ўзгартириш:',
    branches: 'Филиаллар',
    back: 'Орқага',
    addNewRegion: 'Янги ҳудуд қўшинг:',
    fullName: 'Исм',
    surname: 'Фамилия',
    role: 'Роль',
    roleProperties: 'Роль хусусиятлари',
    deleteUser: 'Ҳақиқатан ҳам фойдаланувчини ўчириб ташламоқчимисиз?',
    changeSector: 'Секторни ўзгартириш',
    empty: 'Бўш',
    archived: 'Архив',
    archiveRegionSector: 'Ҳақиқатан ҳам ушбу сектор ва унинг ҳудудларини архивлашни хоҳлайсизми?',
    addSector: 'Янги сектор қўшинг:',
    position: 'Лавозим',
    informationChanged: 'Маълумотлар ўзгартирилди',
    passwordChanged: 'Киритилган пароллар мос эмас',
    oldPassword: 'Илтимос, эски парольингизни тўғри киритинг.',
    newPassword: 'Пароль ўзгартирилди',
    addOldPassword: 'Эски пароль',
    placeholderOldPassword: 'Эски паролингизни киритинг',
    addNewPassword: 'Янги парол',
    placeholderNewPassword: 'Янги парольни киритинг',
    addNewPasswordElse: 'Яна янги пароль',
    placeholderNewPasswordElse: 'Янги парольни яна киритинг',
    account: 'Фойдаланувчи ҳисоби',
    selectStatus: 'Ҳолатлардан камида биттасини танланг',
    success: 'Муваффақиятли ўзгартирилди',
    setting: 'Профил созламалари',
    welcome: 'Тизимга хуш келибсиз',
    logInSystem: 'Кириш',
    auth: 'Кириш',
    docs: 'Маълумотнома',
    categories: 'Категориялар',
    categoriesList: 'Категориялар рўйхати',
    topics: 'Мавзулар',
    topicList: 'Мазулар рўйхати',
    title: 'Сарлавҳа',
    shortTitle: 'Қизқача сарлавҳа',
    slug: 'Слуг',
    body: 'Маълумот',
    category: 'Категория',
    changeDocs: 'Маълумономани ўзгартириш',
    territoryName: 'Ҳудудлар номи',
    plan: 'Режа',
    irrigation: 'суғориш',
    waterLimit: 'Суғориш учун берилган сув лимити',
    amountWaterPreviousDay: 'Ўтган кун сув миқдори',
    amountWater: 'Берилган сув миқдори',
    irrigationCapacity: 'Суғориш имконияти',
    totalIrrigation: 'Жами суғорилган майдон',
    forOneDay: '1 кунда',
    watermanCount: 'Сувчилар сони',
    themes: 'Темалар',
    primaryTheme: 'Фируза ранг',
    linkTheme: 'Баланд кўк ранг',
    infoTheme: 'Ҳаво ранг',
    successTheme: 'Яшил ранг',
    warningTheme: 'Сариқ ранг',
    dangerTheme: 'Қизил ранг',
    blackTheme: 'Қора ранг',
    darkTheme: 'Очиқ қора ранг',
    lightTheme: 'Кул ранг',
    whiteTheme: 'Оқ ранг',
    print: 'Чоп этиш',
    enterTitle: 'Сарлавҳани киргизинг',
    enterShortTitle: 'Қизқа сарлавҳани киргизинг',
    enterSlug: 'Слугни киргизинг',
    to: 'Кимга',
    isDoneTitle: 'Бажарилди',
    fileTitle: 'Бириктирилган файл',
    listMails: 'Хатлар рўйхати',
    sendMail: 'Хатни юбориш',
    isDone: 'Бажарилди',
    mailDelete: 'Сиз ростдан ҳам ўчирмоқчимисиз?',
    send: 'Юбориш',
    isNotDone: 'Бажарилмаган',
    mails: 'Хатлар',
    listReports: 'Малумотлар рўйхати',
    date: 'Сана',
    statusNotFilled: 'Хисобот тулдирилмаган.',
    statusWaiting: 'Ҳисобот тасдикланишини кутинг. Юборувчи {responsible}',
    statusAccepted: 'Ҳисобот қабул қилинди. Юборувчи {responsible}. Қабул қилувчи {acceptedBy}',
    statusRejected: 'Ҳисобот қабул қилинмади. Юборувчи {responsible}. Текширувчи {rejectedBy}. Сабаб {reason}',
    newReportMessage: '{name} янги ҳисобот.',
    newReportLink: 'Ҳисоботга ўтиш.',
    accept: 'Қабул қилиш',
    reject: 'Бекор қилиш',
    reason: 'Сабаб',
    enterReason: 'Сабабни киритинг',
    waiting: 'Кутилмоқда',
    pumps: 'Насослар',
    pumpStation: 'Насос стансияси',
    listPumpStations: 'Насос стансиялари рўйхати',
    reservoir: 'Сув омборлари',
    listReservoir: 'Сув омборлари рўйхати',
    aggregates: 'Аггрегатлар сони',
    energy: 'Сарфланадиган электр энергияси қуввати',
    waterCapability: 'Сув чиқариш қобилияти',
    waterHeight: 'Сув кўтариш баландлиги',
    totalEnergy: 'Жами электр энергияси қуввати',
    totalWaterCapability: 'Жами сув чиқариш қобилияти',
    mgwt: 'мгWатт/соат',
    metr: 'метр',
    enterAggregates: 'Агрератлар сонини киритинг',
    enterEnergy: 'Электр энергияси қувватини киритинг',
    enterWaterCapability: 'Сув чиқариш қобилиятини киритинг',
    enterWaterHeight: 'Сув кўтариш баландлигини киритинг',
    yesterdayMorning: 'Ўтган кун эрталаб',
    lastYear: 'Ўтган йил',
    fill: 'Тўлдириш',
    filled: 'Тўлдирилган',
    activeAggregates: 'Ишлаб турган аггрегатлар',
    enterActiveAggregates: 'Ишлаб турган аггрегатлар сонини киритинг',
    dinnerActiveAggregates: 'Ишлаб турган аггрегатлар (кечки)',
    enterDinnerActiveAggregates: 'Кечки шлаб турган аггрегатлар сонини киритинг',
    addingPumpStationReport: '{pumpStation} насос стансиясининг бугунги маълумоти',
    addingReservoirReport: 'Сув омборининг бугунги маълумоти',
    maxNumber: 'Лимитдан катта бўлган қилмат киритилиши мумкин эмас',
    ru: 'Русча',
    uz: 'Ўзбекча',
    en: 'Инглизча',
    sources: 'Манбалар',
    recipients: 'Қабул қилувчилар',
    type: 'Тур',
    knot: 'Гидроузел',
    branchLevel: 'Координаталар жадвали',
    branchLevelCorrection: 'Тўрилаш',
    level: 'Даражаси',
    create: 'Яратиш',
    deleteConfirm: 'Ҳақиқатан ҳам тизимдан мутлоқ ўчириб ташламоқчимисиз?',
    periods: 'Даврлар',
    channelLevel: 'Канал даражаси',
    border: 'Чегара канали',
    reporter: 'Масъул',
    notSelected: 'Белгиланмаган',
    waterIn: 'Сув тушиши',
    waterOut: 'Сув чиқиши',
    totalAmount: 'Умумий ҳажм',
    aboutPumpStation: '"{pumpStation}" насос стансияси ҳақида маълумот',
    aboutChannel: '"{channel}" канали ҳақида маълумот',
    aboutKnot: '"{knot}" гидроузели ҳақида маълумот',
    aboutReservoir: '"{reservoir}" сув омбори ҳақида маълумот',
    close: 'Ёпиш',
    yes: 'Ҳа',
    no: 'Йўқ',
    noInfo: 'Бошқа маълумотлар киритилмаган',
    showXlsx: 'Эхcелда кўрсатиш',
    districts: 'Туманлар',
    parent: 'Асосий',
    listHydroposts: 'Гидропостлар Рўйхати',
    hydroposts: 'Гидропостлар',
    hydropost: 'Гидропост',
    addingHydropostReport: 'Гидропост ҳисоботи',
    activePeriods: 'Актив даврлар',
    irrigationSystem: 'Ирригацион тизим бошқармаси',
    copied: 'Кучирилди',
}

const ru = {
    actions: 'Действия',
    FirstFillCoordinateTable: 'Таблица координат не заполнен для данного канала!',
    irrigationDay: 'Было орошено',
    waterConsumptionDay: 'Расход воды',
    ga: 'га',
    mln: 'млн м3',
    SFK: 'СФК',
    region: 'Район',
    fillIn: 'не заполнили данные за вчера',
    waterRunOffRegion: 'Сток воды по регионам ',
    waterRunOffDate: 'Сток воды по дате',
    allData: 'Все районы заполнили данные за',
    forToday: 'За сегодняшний день',
    archive: 'Архив',
    control: 'Управление',
    controlTerritory: 'Управления территориями',
    controlPlan: 'Управление планами',
    seasons: 'Сезоны',
    controlIrrigation: 'Управление орошением',
    users: 'Пользователи',
    reporters: 'Докладчики',
    reportersTitle: 'Название',
    reportersType: 'Тип',
    itb: 'Администрация ирригационной системы',
    district_irrigation_department: 'Районное управление ирригации',
    deleteReporter: 'Хотите удалить докладчика ?',
    district: 'Район',
    listDistricts: 'Список районов',
    listSectorDistricts: 'Список секторов района',
    listTerritories: 'Список Территории',
    listSectorTerritories: 'Список секторов Территории',
    listBranch: 'Список ирригационных систем управления',
    listChannels: 'Список каналов',
    territoryReportReplenishment: 'Заполнение отчёта',
    name: 'Название',
    total: 'Итого',
    channel: 'Канал',
    forMorning: 'Утренний',
    forEvening: 'Вечерний',
    forAverage: 'Средний',
    download: 'Скачать',
    add: 'Добавить',
    adding: 'Добавление информации',
    cancel: 'Отмена',
    m3: 'м3',
    successSave: 'Успешно сохранён',
    noChannels: 'Нет каналов. Добавьте канал !!!',
    fillFieldsThenSave: 'Заполните поля потом сохраните',
    save: 'Сохранить',
    noReport: 'В {date} отчет по {name} не заполнен!',
    limitExpense: 'Лимит и расход',
    limitWater: 'Лимит воды',
    used: 'Использовано',
    difference: 'Разница',
    water: 'Вода',
    limit: 'Лимит',
    dashboard: 'Дашборд',
    regions: 'Районы',
    tib: 'Каналы',
    season: 'Сезон',
    logIn: 'Авторизоваться',
    exit: 'Выйти',
    editing: 'Редактирование',
    enterName: 'Введите название',
    period: 'Период',
    createdAt: 'Дата создания',
    vegetation: 'Вигитатцион',
    noVegetation: 'Не Вигитатцион',
    change: 'Изменить',
    irrigationType: 'Вид орошения',
    irrigationCount: 'Количество орошения',
    status: 'Статус',
    filledIn: 'Заполнено',
    m3sek: 'м3/сек',
    grain: 'Орошение зерна',
    cotton: 'Орошение хлопка',
    cottonWash: 'Порошковое орошение',
    repeatIrrigation: 'Повторный полив',
    garden: 'Орошение земли и др.',
    preparation: 'Подготовка к промыванию',
    saltWash: 'Промывка',
    saltWashPlow: 'Промывка пашни',
    grainPreparation: 'Орошение зерна зимное',
    potatoPreparation: 'Промышка земли картофелья',
    deleteSector: 'Вы действительно хотите архивировать этот сектор?',
    addSeason: 'Добавьте орошения в сезон !!!',
    addTerritoryToRegion: 'Добавьте территория к этому району.',
    fullDate: 'В {date} числе отчет по районом не заполнено!',
    channels: 'Каналы',
    required: 'Это обязательное поле',
    changeRegion: 'Изменить район:',
    branches: 'Филиалы',
    back: 'Назад',
    addNewRegion: 'Добавить новый район:',
    fullName: 'Имя',
    surname: 'Фамилия',
    role: 'Роль',
    roleProperties: 'Свойство ролей',
    deleteUser: 'Вы действительно хотите удалить пользователя?',
    changeSector: 'Изменить сектор:',
    empty: 'Пусто',
    archived: 'Архивировать',
    archiveRegionSector: 'Вы действительно хотите архивировать этот сектор и его районы?',
    addSector: 'Добавить новый сектор:',
    position: 'Должность',
    informationChanged: 'Информация изменен',
    passwordChanged: 'Введенные пароли не совпадают',
    oldPassword: 'Введете старый пароль правильно.',
    newPassword: 'Пароль изменен',
    addOldPassword: 'Старый пароль',
    placeholderOldPassword: 'Введите старый пароль',
    addNewPassword: 'Новый пароль',
    placeholderNewPassword: 'Введите новый пароль',
    addNewPasswordElse: 'Новый пароль ещё раз',
    placeholderNewPasswordElse: 'Введите новый пароль ещё раз',
    account: 'Аккаунт пользователя',
    selectStatus: 'Выберите хотя бы один из статусов',
    success: 'Успешно изменено',
    setting: 'Настройки профиля',
    welcome: 'Добро пожаловать в систему',
    logInSystem: 'Логин',
    auth: 'Войти',
    docs: 'Документация',
    categories: 'Категории',
    categoriesList: 'Список категории',
    topics: 'Темы',
    topicList: 'Список Тем',
    title: 'Название',
    shortTitle: 'Короткое название',
    slug: 'Slug',
    body: 'Контент',
    category: 'Категория',
    changeDocs: 'Изменить документацию',
    territoryName: 'Название территории',
    plan: 'План',
    irrigation: 'орошение',
    waterLimit: 'Лимит воды для орошения',
    amountWaterPreviousDay: 'Количество воды в предыдущий день',
    amountWater: 'Количество воды, подаваемое',
    irrigationCapacity: 'Оросительная способность',
    totalIrrigation: 'Общая орошаемая площадь',
    forOneDay: '1 день',
    watermanCount: 'Количество пловцов',
    themes: 'Темы',
    primaryTheme: 'Бирюзовый',
    linkTheme: 'Тёмно-синий',
    infoTheme: 'Голубой',
    successTheme: 'Зелёный',
    warningTheme: 'Жёлтый',
    dangerTheme: 'Красный',
    blackTheme: 'Черный',
    darkTheme: 'Светло-черный',
    lightTheme: 'Серый',
    whiteTheme: 'Белый',
    print: 'Печать',
    enterTitle: 'Введите название',
    enterShortTitle: 'Введите короткое название',
    enterSlug: 'Введите slug',
    to: 'Кому',
    isDoneTitle: 'Сделано',
    fileTitle: 'Прикрипленный файл',
    listMails: 'Список письм',
    sendMail: 'Отправить письмо',
    isDone: 'Сделано',
    mailDelete: 'Вы действительно хотите удалить письмо?',
    send: 'Отправить',
    isNotDone: 'Не сделано',
    mails: 'Письма',
    listReports: 'Список сводок',
    date: 'Дата',
    statusNotFilled: 'Отчёт не заполнен.',
    statusWaiting: 'Отчёт в режиме ожидания. Отправил {responsible}. Пожалуйста подождите',
    statusAccepted: 'Отчёт принять. Отправил {responsible}. Принял {acceptedBy}',
    statusRejected: 'Отчёт отказано. Отправил {responsible}. Отказаль {rejectedBy}. Причина {reason}',
    newReportMessage: 'Новый отчёт от {name}.',
    newReportLink: 'Перейти к отчёту.',
    accept: 'Принять',
    reject: 'Отказать',
    reason: 'Причина',
    enterReason: 'Введите причину',
    waiting: 'В ожидании',
    pumps: 'Насосы',
    pumpStation: 'Насосная станция',
    listPumpStations: 'Список насосных станций',
    reservoir: 'Водохранилище',
    listReservoir: 'Список Водохранилищ',
    aggregates: 'Кол-во агрегатов',
    energy: 'Потребляемая мощность энергии',
    waterCapability: 'Способность извлечения воды',
    waterHeight: 'Высота подъема воды',
    totalEnergy: 'Общая потребляемая мощность энергии',
    totalWaterCapability: 'Общая способность извлечения воды',
    mgwt: 'мгВт/ч',
    metr: 'метр',
    enterAggregates: 'Введите кол-во агрегатов',
    enterEnergy: 'Введите потребляемую мощность энергии',
    enterWaterCapability: 'Укажите способность извлечения воды',
    enterWaterHeight: 'Введите высоту подъема воды',
    yesterdayMorning: 'Вчера (утреннее)',
    lastYear: 'Прошлый год',
    fill: 'Заполнить',
    filled: 'Заполнено',
    activeAggregates: 'Кол-во активный агрегатов',
    enterActiveAggregates: 'Введите кол-во активных агрегатов',
    dinnerActiveAggregates: 'Кол-во активный агрегатов (вечернее)',
    enterDinnerActiveAggregates: 'Введите кол-во активных агрегатов (вечернее)',
    addingPumpStationReport: 'Информация о насосной станции {pumpStation} за сегодня',
    addingReservoirReport: 'Информация о водохранилище за сегодня',
    maxNumber: 'Невозможно указать значение больше чем лимит',
    ru: 'Русский',
    uz: 'Узбекский',
    kir: 'Кириллица (Узб)',
    en: 'Английский',
    sources: 'Источники',
    recipients: 'Получатели',
    type: 'Тип',
    knot: 'Гидрозел',
    branchLevel: 'Таблица координат',
    branchLevelCorrection: 'Поправка',
    level: 'Уровень',
    create: 'Создать',
    deleteConfirm: 'Вы действительно хотите удалить этот объект?',
    periods: 'Периоды',
    channelLevel: 'Уровень канала',
    border: 'Пограничный',
    reporter: 'Докладчик',
    notSelected: 'Не выбрано',
    waterIn: 'Приход',
    waterOut: 'Расход',
    totalAmount: 'Общий объём',
    aboutPumpStation: 'Информация о насосной станции "{pumpStation}"',
    aboutChannel: 'Информация о канале "{channel}"',
    aboutKnot: 'Информация о гидроузле "{knot}"',
    aboutReservoir: 'Информация о водохранилище "{reservoir}"',
    close: 'Закрыть',
    yes: 'Да',
    no: 'Нет',
    noInfo: 'Данные не внесены',
    showXlsx: 'Показать в отчете Excel',
    districts: 'Районы',
    parent: 'Родитель',
    listHydroposts: 'Список гидропостов',
    hydroposts: 'Гидропосты',
    hydropost: 'Гидропост',
    addingHydropostReport: 'Отчет гидропоста',
    activePeriods: 'Активные периоды',
    irrigationSystem: 'Ирригационная система управления',
    copied: 'Скопировано',
}

const en = {
    actions: 'Actions',
    FirstFillCoordinateTable: 'The coordinate table is not filled for this channel!',
    irrigationDay: 'It was decided',
    waterConsumptionDay: 'Water consumption',
    ga: 'ha',
    mln: 'mln m3',
    SFK: 'SFK',
    region: 'Area',
    fillIn: 'did not fill in the data for yesterday',
    waterRunOffRegion: 'Water supply by region',
    waterRunOffDate: 'Water supply by date',
    allData: 'All districts filled in the data for',
    forToday: 'For today',
    archive: 'Archive',
    control: 'Control',
    controlTerritory: 'Territory management',
    controlPlan: 'Plan management',
    seasons: 'Seasons',
    controlIrrigation: 'Irrigation management',
    users: 'Users',
    reporters: 'Reporters',
    reportersTitle: 'Title',
    reportersType: 'Type',
    itb: 'Irrigation System Administration',
    district_irrigation_department: 'District Irrigation Department',
    deleteReporter: 'Do you want remove reporter ?',
    district: 'Area',
    listDistricts: 'The list is close',
    listSectorDistricts: 'List of district sectors',
    listTerritories: 'List of Territories',
    listSectorTerritories: 'List of sectors of the Territory',
    listBranch: 'List of irrigation control systems',
    listChannels: 'Channel List',
    territoryReportReplenishment: 'Completing the report',
    name: 'Name',
    total: 'Total',
    channel: 'Channel',
    forMorning: 'Morning',
    forEvening: 'Evening',
    forAverage: 'Average',
    download: 'Download',
    add: 'Add',
    adding: 'Adding information',
    cancel: 'Cancel',
    m3: 'm3',
    successSave: 'Successfully saved',
    noChannels: 'No channels. Add a channel!!!',
    fillFieldsThenSave: 'Fill in the field then save',
    save: 'Save',
    noReport: 'On {date}, the report for {name} was not completed!',
    limitExpense: 'Limit and expense',
    limitWater: 'water limit',
    used: 'used',
    difference: 'Difference',
    water: 'Water Water',
    limit: 'Limit',
    dashboard: 'Dashboard',
    regions: 'Districts',
    tib: 'Channels',
    season: 'Season',
    logIn: 'Login',
    exit: 'Go out',
    editing: 'Editing',
    enterName: 'Enter the title',
    period: 'Period',
    createdAt: 'date of creation',
    vegetation: 'Vigitation',
    noVegetation: 'Not Vigitation',
    change: 'Change',
    irrigationType: 'Type of irrigation',
    irrigationCount: 'Irrigation quantity',
    status: 'Status',
    filledIn: 'filled',
    m3sek: 'm3/s',
    grain: 'Implementation irrigation',
    cotton: 'Cotton irrigation',
    cottonWash: 'Powder irrigation',
    repeatIrrigation: 'Re-watering',
    garden: 'Land irrigation, etc.',
    preparation: 'Preparing for flushing',
    saltWash: 'flushing',
    saltWashPlow: 'Washing of arable land',
    grainPreparation: 'Fulfillment of the winter order',
    potatoPreparation: 'industrial land',
    deleteSector: 'Are you sure you want to archive this sector?',
    addSeason: 'Add irrigation in season!!!',
    addTerritoryToRegion: 'Attach the territory to this region.',
    fullDate: 'On {date} including the district report is not filled!',
    channels: 'Channels',
    required: 'This is a required field',
    changeRegion: 'Change area:',
    branches: 'Branches',
    back: 'Back',
    addNewRegion: 'Add new district:',
    fullName: 'Name',
    surname: 'Surname',
    role: 'Role',
    roleProperties: 'Group property',
    deleteUser: 'Are you sure you want to delete the user?',
    changeSector: 'Change sector:',
    empty: 'empty',
    archived: 'Archive',
    archiveRegionSector: 'Are you sure you want to archive this sector and its regions?',
    addSector: 'Add new sector:',
    position: 'Job title',
    informationChanged: 'Information changed',
    passwordChanged: 'The entered passwords do not match',
    oldPassword: 'Enter the old password correctly.',
    newPassword: 'Password',
    addOldPassword: 'old password',
    placeholderOldPassword: 'Enter old password',
    addNewPassword: 'New Password',
    placeholderNewPassword: 'Enter a new password',
    addNewPasswordElse: 'New password again',
    placeholderNewPasswordElse: 'Enter the new password again',
    account: 'User account',
    selectStatus: 'Please select at least one of the statuses',
    success: 'Success',
    setting: 'Profile settings',
    welcome: 'Welcome to the system',
    logInSystem: 'Sign in',
    auth: 'Log in',
    docs: 'Documentation',
    categories: 'Categories',
    categoriesList: 'List of categories',
    topics: 'Topics',
    topicList: 'List of topics',
    title: 'Name',
    shortTitle: 'Short name',
    slug: 'Slug',
    body: 'Content',
    category: 'Category',
    changeDocs: 'Edit Documentation',
    territoryName: 'Territory name',
    plan: 'Plan',
    irrigation: 'irrigation',
    waterLimit: 'Irrigation water limit',
    amountWaterPreviousDay: 'The amount of water before the day',
    amountWater: 'The amount of water supplied',
    irrigationCapacity: 'Irrigation capacity',
    totalIrrigation: 'Total landscaped area',
    forOneDay: '1 day',
    watermanCount: 'Number of swimmers',
    themes: 'Topics',
    primaryTheme: 'Turquoise',
    linkTheme: 'Dark blue',
    infoTheme: 'Blue',
    successTheme: 'Green',
    warningTheme: 'Yellow',
    dangerTheme: 'Red',
    blackTheme: 'Black',
    darkTheme: 'light black',
    lightTheme: 'Grey',
    whiteTheme: 'White',
    print: 'Seal',
    enterTitle: 'Enter the title',
    enterShortTitle: 'Enter a short name',
    enterSlug: 'Enter slug',
    to: 'To',
    isDoneTitle: 'Made',
    fileTitle: 'Covered file',
    listMails: 'List of letters',
    sendMail: 'to send a letter',
    isDone: 'Made',
    mailDelete: 'Are you sure you want to delete the email?',
    send: 'Send',
    isNotDone: 'Not done',
    mails: 'Letters',
    listReports: 'List of bulletins',
    date: 'the date',
    statusNotFilled: 'Report is not filled.',
    statusWaiting: 'Report in standby mode. Sent by {responsible}. Wait, please',
    statusAccepted: 'Accept the report. Sent by {responsible}. Accepted {acceptedBy}',
    statusRejected: 'Report denied. Sent by {responsible}. Rejection {rejectedBy}. reason {reason}',
    newReportMessage: 'New report from {name}.',
    newReportLink: 'Go to report.',
    accept: 'To accept',
    reject: 'refuse',
    reason: 'Cause',
    enterReason: 'Enter a reason',
    waiting: 'Pending',
    pumps: 'Pumps',
    pumpStation: 'Pumping station',
    listPumpStations: 'List of pumping stations',
    reservoir: 'Reservoir',
    listReservoir: 'List of Reservoirs',
    aggregates: 'Number of units',
    energy: 'Power consumption',
    waterCapability: 'Ability to withdraw water',
    waterHeight: 'Water lifting height',
    totalEnergy: 'Total Power Consumption',
    totalWaterCapability: 'Overall Water Extraction Capacity',
    mgwt: 'mgWh',
    metr: 'meter',
    enterAggregates: 'Enter number of units',
    enterEnergy: 'Power consumption',
    enterWaterCapability: 'Indicate the discovered ability of water',
    enterWaterHeight: 'Water lifting height',
    yesterdayMorning: 'Yesterday (morning)',
    lastYear: 'Last year',
    fill: 'Fill',
    filled: 'filled',
    activeAggregates: 'Number of active units',
    enterActiveAggregates: 'Enter the number of active units',
    dinnerActiveAggregates: 'Number of active units (evening)',
    enterDinnerActiveAggregates: 'Enter the number of active units (evening)',
    addingPumpStationReport: 'Information about the pump station {pumpStation} for today',
    addingReservoirReport: 'Reservoir information for today',
    maxNumber: 'It is not possible to specify a value greater than the limit',
    ru: 'Russian',
    uz: 'Uzbek',
    kir: 'Cyrillic (Uzb)',
    sources: 'Sources',
    recipients: 'Receivers',
    type: 'Type of',
    knot: 'Hydrozel',
    branchLevel: 'Coordinate table',
    branchLevelCorrection: 'Amendment',
    level: 'Level',
    create: 'Create',
    deleteConfirm: 'Are you sure you want to delete this object?',
    periods: 'Periods',
    channelLevel: 'Channel level',
    border: 'Border',
    reporter: 'Speaker',
    notSelected: 'Not chosen',
    waterIn: 'Coming',
    waterOut: 'Consumption',
    totalAmount: 'Overall volume',
    aboutPumpStation: 'Pump station information "{pumpStation}"',
    aboutChannel: 'Information about the channel "{channel}"',
    aboutKnot: 'Information about hydroscheme "{knot}"',
    aboutReservoir: 'Information about reservoir "{reservoir}"',
    close: 'close',
    yes: 'Yes',
    no: 'Not',
    noInfo: 'Data not entered',
    showXlsx: 'Show in Excel Report',
    districts: 'Districts',
    parent: 'Parent',
    listHydroposts: 'List of gauging stations',
    hydroposts: 'Hydroposts',
    hydropost: 'Hydropost',
    addingHydropostReport: 'Hydropost report',
    activePeriods: 'Active periods',
    irrigationSystem: 'Irrigation control system',
    copied: 'Copied',
}

export const languages = { uz, ru, kir, en }
