import React from 'react'
import { Formik, Form } from 'formik'
import useTrans from '../hooks/trans'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'

export default function ReservoirReportForm({ onSubmit, onCancel, loading, initialValues }) {
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ values }) => (
                <Form>
                    <table className="table is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th />
                                <th>{t('forMorning')}</th>
                                <th>{t('forEvening')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    <span className="tag is-link is-light">
                                        <i className="icon ion-md-beaker" />
                                    </span> &nbsp;
                                    {t('totalAmount')}
                                </td>

                                <td>
                                    <Input
                                        name="morningTotal"
                                        type="number"
                                        step="0.01"
                                        placeholder={t('totalAmount')}
                                        validate={required}
                                        className="is-small" />
                                </td>

                                <td>
                                    <Input
                                        name="dinnerTotal"
                                        type="number"
                                        step="0.01"
                                        placeholder={t('totalAmount')}
                                        className="is-small" />
                                </td>
                            </tr>

                            {values.items.map((item, index) => (
                                <tr key={item.branch}>
                                    <td>
                                        {item.type === 'in' ? (
                                            <span className="tag is-success is-light">
                                                <i className="icon ion-md-arrow-down" />
                                            </span>
                                        ) : (
                                            <span className="tag is-warning is-light">
                                                <i className="icon ion-md-arrow-up" />
                                            </span>
                                        )} &nbsp;
                                        {item.name}
                                    </td>
                                    <td>
                                        <Input
                                            type="number"
                                            name={`items.${index}.morningAmount`}
                                            validate={required}
                                            step="0.01"
                                            placeholder={item.type === 'in' ? t('waterIn') : t('waterOut')}
                                            className="is-small" />
                                    </td>

                                    <td>
                                        <Input
                                            type="number"
                                            name={`items.${index}.dinnerAmount`}
                                            placeholder={item.type === 'in' ? t('waterIn') : t('waterOut')}
                                            step="0.01"
                                            className="is-small" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div>
                        <Button
                            onClick={onCancel}
                            icon="ion-md-close"
                            text={t('cancel')}
                            className="is-danger" />&nbsp;&nbsp;

                        <Button
                            loading={loading}
                            text={t('save')}
                            type="submit"
                            icon="ion-md-checkmark"
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
