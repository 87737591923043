import { Form, Formik } from 'formik'
import React, { Fragment } from 'react'
import find from 'lodash/find'
import Input from './common/Input'
import Button from './common/Button'
import useTrans from '../hooks/trans'
import { maxNumber, minNumber, required, validator } from '../utils/validators'
import { roleMain } from '../utils/role'
import { useUser } from '../hooks/user'

export default function BranchReportForm({
    onSubmit,
    onCancel,
    loading,
    onAccepted,
    onRejected,
    placeholder,
    branchReport: item,
    levelMin,
    levelMax,
}) {
    const t = useTrans()
    const [user] = useUser()

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                morning: '',
                morningLevel: '',
                dinner: '',
                dinnerLevel: '',
                ...item.reportItem,
            }}>
            {({ setFieldValue }) => (
                <Form>
                    <Input
                        name="morning"
                        label={t('forMorning')}
                        placeholder={placeholder}
                        disabled
                        type="number" />

                    <Input
                        name="dinner"
                        label={t('forEvening')}
                        placeholder={placeholder}
                        disabled
                        type="number" />

                    <Input
                        name="morningLevel"
                        label={`${t('forMorning')} (${t('level')})`}
                        placeholder={placeholder}
                        type="number"
                        validate={validator(required, maxNumber(levelMax), minNumber(levelMin))}
                        onChange={(e) => {
                            const branchLevels = find(item.branch.levels, {
                                level: Number(e.target.value),
                            })
                            setFieldValue('morningLevel', e.target.value)
                            setFieldValue('morning', branchLevels ? branchLevels.value : 0)
                        }}
                        min={levelMin}
                        max={levelMax} />

                    <Input
                        name="dinnerLevel"
                        label={`${t('forEvening')} (${t('level')})`}
                        placeholder={placeholder}
                        type="number"
                        validate={validator(required, maxNumber(levelMax), minNumber(levelMin))}
                        onChange={(e) => {
                            const value = find(item.branch.levels, {
                                level: Number(e.target.value),
                            })
                            setFieldValue('dinnerLevel', e.target.value)
                            setFieldValue('dinner', value ? value.value : 0)
                        }}
                        min={levelMin}
                        max={levelMax} />

                    <div className="is-pulled-right">
                        {item.reportItem && roleMain.includes(user.role) ? (
                            <Fragment>
                                <Button
                                    onClick={() => onRejected(true)}
                                    text={t('reject')}
                                    className="is-warning mr-2"
                                    icon="ion-md-close" />

                                <Button
                                    text={t('accept')}
                                    onClick={onAccepted}
                                    loading={loading}
                                    className="is-link mr-2"
                                    icon="ion-md-checkmark" />
                            </Fragment>
                        ) : null}

                        <Button
                            onClick={onCancel}
                            icon="ion-md-close"
                            text={t('cancel')}
                            className="is-danger" />&nbsp;&nbsp;

                        <Button
                            loading={loading}
                            text={t('save')}
                            type="submit"
                            icon="ion-md-checkmark"
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
