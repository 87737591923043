import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_DAY_AMOUNT_WATER } from '../urls'
import Card from './common/Card'
import StatisticsDayAmountWaterLine from './StatisticsDayAmountWaterLine'
import useTrans from '../hooks/trans'

export default function StatisticsDayAmountWater({ startDate, endDate }) {
    const dayAmountWater = useLoad({ url: STATISTICS_DAY_AMOUNT_WATER, params: { startDate, endDate } })
    const t = useTrans()

    return (
        <div className="column is-12">
            <Card>
                <div className="title is-5 has-text-centered">
                    {`${t('waterRunOffDate')} (${t('mln')})`}
                </div>

                <StatisticsDayAmountWaterLine
                    data={dayAmountWater.response || []}
                    loading={dayAmountWater.loading}
                    field="count" />
            </Card>
        </div>
    )
}
