import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function BranchTabs() {
    const [user] = useUser()
    const { path } = useRouteMatch()
    const t = useTrans()

    return (
        <div className="tabs mb-2">
            <ul className={cn('is-flex is-justify-content-space-between', css(styles.tabs))}>
                <div className="is-flex">
                    <li className={cn({ 'is-active': (path.startsWith('/pump-stations')) })}>
                        <NavLink className={css(styles.itemTabs)} to={user.role === 'branch' ? (
                            '/pump-stations'
                        ) : '/pump-stations'}>
                            {t('forToday')}
                        </NavLink>
                    </li>

                    <li className={cn({ 'is-active': (path.startsWith('/pump-stations/archive')) })}>
                        <NavLink className={css(styles.itemTabs)} to={user.role === 'branch' ? (
                            '/pump-stations/archive'
                        ) : '/pump-stations/archive'}>
                            {t('archive')}
                        </NavLink>
                    </li>

                    <li className={cn({ 'is-active': (path.startsWith('/pump-stations/settings')) })}>
                        <NavLink className={css(styles.itemTabs)} to={user.role === 'branch' ? (
                            '/pump-stations/settings'
                        ) : '/pump-stations/settings'}>
                            {t('control')}
                        </NavLink>
                    </li>
                </div>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    itemTabs: {
        borderStyle: 'none',
    },
    tabs: {
        height: '50px',
    },
})
