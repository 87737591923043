import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import find from 'lodash/find'
import Progress from './common/Progress'
import useTrans from '../hooks/trans'
import { Context } from './common/BaseContext'

export default function DistrictReportTableItem({ item }) {
    const t = useTrans()
    const { reports } = useContext(Context)
    const reportItem = find(reports, { id: item.id, type: 'district' })

    return (
        <tr key={item.id}>
            <td>
                <b>
                    <NavLink to={`/district/${item.id}/reports`}>
                        {item.name}
                    </NavLink>
                </b>
                <span className="has-text-warning"> {reportItem ? `(${t('waiting')})` : ''}</span>
                <p className="is-size-7">{item.sector.name}</p>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-link is-reversed is-light is-rounded">
                            <i className="icon ion-ios-speedometer" />
                        </button>
                    </div>
                    <div className="column">
                        { item.limitWater ? item.limitWater : 0 } {t('m3sek')}
                    </div>
                </div>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-info is-light is-rounded">
                            <i className="icon ion-ios-water" />
                        </button>
                    </div>
                    <div className="column">
                        { item.amountWater ? item.amountWater : 0 } {t('m3sek')}
                    </div>
                </div>
            </td>

            <td>
                <Progress
                    value={item.replenished}
                    max={100} text={`${item.replenished} %`}
                    background="#49c78e" />
            </td>
        </tr>
    )
}
