import React, { useState } from 'react'
import Layout from '../components/Layout'
import { DISTRICT_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import DistrictTabs from '../components/DistrictTabs'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleDistrictSector } from '../utils/role'
import useTrans from '../hooks/trans'
import DistrictReportTableItem from '../components/DistrictReportTableItem'

export default function DistrictReports() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const districts = useLoad({ url: DISTRICT_LIST, params: { page } }, [page])
    const t = useTrans()

    if (!roleDistrictSector.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h3 className="title is-3 has-text-weight-normal">{t('listDistricts')}</h3>
            </div>

            <Table
                loading={districts.loading}
                activePage={page}
                onPageChange={setPage}
                items={districts.response ? districts.response.results : []}
                columns={{
                    name: t('name'),
                    limit: t('limit'),
                    water: t('water'),
                    replenished: t('filledIn'),
                }}
                renderItem={(item) => (!item.isArchive ? (
                    <DistrictReportTableItem key={item.id} item={item} />
                ) : null)} />
        </Layout>
    )
}
