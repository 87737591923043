import React, { Fragment } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function BranchTabs() {
    const [user] = useUser()
    const { path } = useRouteMatch()
    const t = useTrans()

    return (
        <div className="tabs mb-2">
            <ul className={cn('is-flex is-justify-content-space-between', css(styles.tabs))}>
                <div className="is-flex">
                    <li className={cn({
                        'is-active': path.startsWith('/branch/report')
                            || path.endsWith('district')
                            || path.endsWith('report'),
                    })}>
                        <NavLink className={css(styles.itemTabs)} to={user.role === 'branch' ? (
                            `/branch/${user.branch.id}/district`
                        ) : '/branch/report'}>
                            {t('forToday')}
                        </NavLink>
                    </li>
                    { user.role !== 'district' ? (
                        <Fragment>
                            <li className={cn({ 'is-active': (
                                path.startsWith('/branch/archives')) || (path.endsWith('archives')) })}>
                                <NavLink className={css(styles.itemTabs)} to={user.role === 'branch' ? (
                                    `/branch/${user.branch.id}/archives`
                                ) : '/branch/archives'}>
                                    {t('archive')}
                                </NavLink>
                            </li>

                            <li className={cn({ 'is-active': path.startsWith('/branch/settings') })}>
                                <NavLink className={css(styles.itemTabs)} to={user.role === 'branch' ? (
                                    `/branch/settings/${user.branch.id}`
                                ) : '/branch/settings'}>
                                    {t('control')}
                                </NavLink>
                            </li>
                        </Fragment>
                    ) : null}
                </div>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    itemTabs: {
        borderStyle: 'none',
    },
    tabs: {
        height: '50px',
    },
})
