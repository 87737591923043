import React from 'react'
import { usePostRequest } from '../hooks/request'
import { TERRITORY_SECTOR_LIST } from '../urls'
import TerritorySectorForm from './TerritorySectorForm'

export default function TerritorySectorCreate({ onCancel, onSuccess }) {
    const territorySectors = usePostRequest({ url: TERRITORY_SECTOR_LIST })

    async function onSubmit(data) {
        await territorySectors.request({ data })
        onSuccess()
    }

    return (
        <div>
            <TerritorySectorForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={territorySectors.loading} />
        </div>
    )
}
