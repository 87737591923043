import React, { useState } from 'react'
import first from 'lodash/first'
import last from 'lodash/last'
import BranchReportForm from './BranchReportForm'
import { useMessage } from '../hooks/message'
import useTrans from '../hooks/trans'
import ServerError from './common/ServerError'
import BranchRejectModal from './BranchRejectModal'
import BranchReportWaitingMessage from './BranchReportWaitingMessage'
import AcceptedMessage from './AcceptedMessage'
import RejectedMessage from './RejectedMessage'
import { onAcceptedBranchReportItem } from './OnAccepBranchReportItem'

export default function BranchReportUpdate({
    onSuccess,
    onCancel,
    branchReport,
    branchReportItems,
    branchReportItemsUpdate,
}) {
    const [showMessage] = useMessage()
    const t = useTrans()
    const [rejectForm, setRejectForm] = useState(null)

    let levelMin = first(branchReport.branch.levels)
    let levelMax = last(branchReport.branch.levels)

    levelMin = levelMin ? levelMin.level : 0
    levelMin = levelMin < 0 ? 0 : levelMin
    levelMax = levelMax ? levelMax.level : 0
    const placeholder = `min: ${levelMin} - max: ${levelMax}`

    async function onSubmit(data) {
        const { response, error } = await branchReportItemsUpdate.request({ data: {
            dinner: data.dinner || 0,
            morning: data.morning || 0,
            morningLevel: +data.morningLevel || 0,
            dinnerLevel: +data.dinnerLevel || 0,
            correction: branchReport.branch.correction,
            districtBranch: branchReport.id,
            status: data.status,
        } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        const newResults = branchReportItems.response.results.map((item) => {
            if (item.id === response.districtBranch) {
                // eslint-disable-next-line no-param-reassign
                item.reportItem = response
            }
            return item
        })
        branchReportItems.setResponse({ ...branchReportItems.response, results: newResults })
        showMessage(t('successSave'), 'is-success')
        onSuccess()
    }

    return (
        <div>
            <b>{branchReport.branch.name}:</b><br /><br />

            {branchReport.reportItem ? (
                <div>
                    {branchReport.reportItem.status === 'waiting' ? (
                        <BranchReportWaitingMessage item={branchReport.reportItem} />
                    ) : null}

                    {branchReport.reportItem.status === 'accepted' ? (
                        <AcceptedMessage item={branchReport.reportItem} />
                    ) : null}

                    {branchReport.reportItem.status === 'rejected' ? (
                        <RejectedMessage item={branchReport.reportItem} />
                    ) : null}
                </div>
            ) : null}

            <ServerError error={branchReportItemsUpdate.error} />

            {rejectForm ? (
                <BranchRejectModal
                    branchReport={branchReport}
                    branchReportItems={branchReportItems}
                    branchReportItemsUpdate={branchReportItemsUpdate}
                    onSuccess={setRejectForm}
                    onCancel={onCancel} />
            ) : (
                <BranchReportForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    branchReportItems={branchReportItems}
                    placeholder={placeholder}
                    onAccepted={() => onAcceptedBranchReportItem({
                        report: branchReport.reportItem,
                        reports: branchReportItems,
                        branchReportItemsUpdate,
                        showMessage,
                        t,
                        onSuccess,
                    })}
                    onRejected={setRejectForm}
                    loading={branchReportItemsUpdate.loading}
                    branchReport={branchReport}
                    levelMin={levelMin}
                    levelMax={levelMax} />
            )}
        </div>
    )
}
