import React from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { useLoad, usePutRequest } from '../hooks/request'
import { ACTIVE_TYPE_LIST, PLAN_DETAIL, TERRITORY_LIST } from '../urls'
import DistrictTabs from '../components/DistrictTabs'
import DistrictPlanForm from '../components/DistrictPlanForm'
import Loader from '../components/common/Loader'
import { PermissionDenied } from '../components/PermissionDenied'
import { useMessage } from '../hooks/message'
import { roleMain } from '../utils/role'

export default function DistrictPlans() {
    const { districtId } = useParams()
    const territory = useLoad({ url: TERRITORY_LIST, params: { district: districtId, isArchive: false, size: 100 } })
    const planUpdate = usePutRequest({ url: PLAN_DETAIL.replace('{districtId}', districtId) })
    const planDetail = useLoad({ url: PLAN_DETAIL.replace('{districtId}', districtId) })
    const activeType = useLoad({ url: ACTIVE_TYPE_LIST })
    const user = JSON.parse(localStorage.getItem('user'))
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const values = []

        if (territory.response) {
            territory.response.results.forEach((item) => {
                Object.entries(data[item.id]).forEach(([type, value]) => (
                    values.push({ activeType: type, territory: item.id, value })
                ))
            })
        }

        const { success } = await planUpdate.request({ data: {
            irrigationManagement: user.irrigationManagement,
            district: districtId,
            items: values,
        } })

        if (success) {
            showMessage('Успешно сохранена!', 'is-success')
        }
    }

    const updatePlanItem = planDetail.response ? planDetail.response.items : []
    const initialValues = {}

    if (territory.response) {
        territory.response.results.forEach((item) => {
            const planTerritory = {}
            updatePlanItem.filter((plan) => plan.territory === item.id).forEach((i) => {
                planTerritory[i.activeType] = i.value
            })
            initialValues[item.id] = planTerritory
        })
    }

    if (territory.loading || planDetail.loading || activeType.loading) {
        return <Layout><DistrictTabs /><Loader large center /></Layout>
    }

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />
            <br />

            <div>
                {territory.response && territory.response.results && !planDetail.loading ? (
                    <DistrictPlanForm
                        loading={planUpdate.loading}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        activeType={activeType.response ? activeType.response.results : []}
                        territory={territory} />
                ) : <Loader large center />}
            </div>
        </Layout>
    )
}
