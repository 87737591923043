import React from 'react'
import { useDeleteRequest } from '../hooks/request'
import { REPORTER_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import ReportersUpdate from './ReportersUpdate'
import Loader from './common/Loader'

export default function ReportersItem({ item, onSuccess }) {
    const reporterDelete = useDeleteRequest({ url: REPORTER_DETAIL.replace('{id}', item.id) })
    const t = useTrans()
    const [showMessage] = useMessage()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ReportersUpdate
            reporter={item}
            onSuccess={() => {
                onSuccess()
                hideUpdateModal()
            }}
            onCancel={() => {
                hideUpdateModal()
            }} />,
    )

    async function onDelete() {
        if (!global.confirm(t('deleteReporter'))) return

        const { error } = await reporterDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onSuccess()
    }

    return (
        <tr>
            <td>
                {item.title}
            </td>

            <td>
                {t(item.type)}
            </td>

            <td className="has-text-right is-flex is-justify-content-flex-end is-align-items-center">
                <i className="icon pointer ion-md-create" title={t('change')} onClick={showUpdateModal} />

                {reporterDelete.loading
                    ? <Loader />
                    : <i onClick={onDelete} className="icon pointer ion-md-trash" />}
            </td>
        </tr>
    )
}
