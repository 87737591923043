import { Form, Formik } from 'formik'
import React from 'react'
import Input from './common/Input'
import Button from './common/Button'
import { required, maxNumber, validator } from '../utils/validators'
import useTrans from '../hooks/trans'

export default function PumpStationForm({ onSubmit, onCancel, loading, pumpStation, initialValues }) {
    const t = useTrans()

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ name: '',
                pumpStation: pumpStation.id,
                aggregates: pumpStation.aggregates,
                ...initialValues }}>
            <Form>
                <Input
                    name="aggregates"
                    label={t('aggregates')}
                    type="number"
                    disabled
                />

                <Input
                    name="activeAggregates"
                    label={t('activeAggregates')}
                    type="number"
                    disabled={loading}
                    validate={validator(required, maxNumber(pumpStation.aggregates))}
                    placeholder={t('enterActiveAggregates')} />

                <Input
                    name="dinnerActiveAggregates"
                    label={t('dinnerActiveAggregates')}
                    type="number"
                    disabled={loading}
                    validate={maxNumber(pumpStation.aggregates)}
                    placeholder={t('enterDinnerActiveAggregates')} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
