export async function onAcceptedBranchReportItem(
    { report, reports, branchReportItemsUpdate, showMessage, t, onSuccess },
) {
    const { response, error } = await branchReportItemsUpdate.request({ data: {
        ...report,
        status: 'accepted',
        responsible: report.responsible ? report.responsible.id : null,
        acceptedBy: report.acceptedBy ? report.acceptedBy.id : null,
        rejectedBy: report.rejectedBy ? report.rejectedBy.id : null,
    } })

    if (error) {
        showMessage(error.data[Object.keys(error.data)[0]], 'is-danger')
        return
    }

    const newResults = reports.response.results.map((item) => {
        if (item.id === response.districtBranch) {
            // eslint-disable-next-line no-param-reassign
            item.reportItem = response
        }
        return item
    })

    reports.setResponse({ ...reports.response, results: newResults })

    showMessage(t('successSave'), 'is-success')
    if (onSuccess) onSuccess()
}
