import React from 'react'
import { usePostRequest } from '../hooks/request'
import { BRANCH_LIST } from '../urls'
import BranchForm from './BranchForm'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'

export default function BranchCreate({ onCancel, onSuccess }) {
    const branchCreate = usePostRequest({ url: BRANCH_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await branchCreate.request({ data: {
            ...data,
            sources: data.sources.map((item) => item.value),
            districts: data.districts.map((item) => item.value),
        } })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('adding')}:</b><br /><br />
            <ServerError error={branchCreate.error} />
            <BranchForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={branchCreate.loading} />
        </div>
    )
}
