import React from 'react'
import { NavLink } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { DISTRICT_SECTOR_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import { useModal } from '../hooks/modal'
import DistrictSectorUpdate from './DistrictSectorUpdate'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function DistrictSectorItem({ item, onSuccess }) {
    const [user] = useUser()
    const districtSectorArchive = useDeleteRequest({ url: DISTRICT_SECTOR_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()
    const t = useTrans()
    const [showUpdateModal, hideUpdateModal] = useModal(
        <DistrictSectorUpdate item={item} hideModal={() => hideUpdateModal()} onSuccess={onSuccess} />,
    )

    async function archiveDistrictSector() {
        if (!global.confirm(t('archiveRegionSector'))) return

        const { error } = await districtSectorArchive.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onSuccess()
    }

    return (
        <tr>
            <td>
                <NavLink className={css(styles.itemLink)} to={`/district/${item.id}/settings`}>
                    {item.name}
                </NavLink>
            </td>

            {user.role === 'main' ? (
                <td className="has-text-right">
                    <i className="icon pointer ion-md-create" title={t('change')} onClick={showUpdateModal} />

                    {!districtSectorArchive.loading ? (
                        <i
                            className="icon ion-md-archive pointer"
                            title={t('archived')}
                            onClick={archiveDistrictSector} />
                    ) : <Loader className="icon" />}
                </td>
            ) : null }
        </tr>
    )
}

const styles = StyleSheet.create({
    itemLink: {
        color: '#000000',
        ':hover': {
            color: '#0000FF',
        },
    },
})
