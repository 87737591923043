import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { usePostRequest } from '../hooks/request'
import { SIGNIN } from '../urls'
import { email, required, validator } from '../utils/validators'
import Password from '../components/Password'
import { signin, isAuthenticated } from '../utils/auth'
import { useUser } from '../hooks/user'
import LayoutAuth from '../components/LayoutAuth'
import useTrans from '../hooks/trans'
import LoginWithEImzo from '../components/LoginWithEImzo'

export default function Login() {
    const history = useHistory()
    const signIn = usePostRequest({ url: SIGNIN, headers: {} })
    const [user, setUser] = useUser()
    const [loginType, setLoginType] = useState('default')
    const t = useTrans()

    if (isAuthenticated()) {
        // TODO: Check all Redirect currently and older
        if (user.role === 'district') {
            return <Redirect to={`district/${user.district.id}/reports`} />
        }

        if (user.role === 'reporter') {
            if (user.reporter.type === 'pump_station') {
                return <Redirect to="/pump-stations" />
            }

            return <Redirect to="/branch/report" />
        }

        return <Redirect to="/dashboard" />
    }

    async function onSubmit(data) {
        const { response, success } = await signIn.request({ data })

        if (success) {
            setUser(response.user)
            signin(response, history)
        }
    }

    return (
        <LayoutAuth>
            <div className={cn('tabs is-left is-boxed', css(styles.tabs))}>
                <ul>
                    <li
                        className={cn({ 'is-active': loginType === 'default' })}
                        onClick={() => setLoginType('default')}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <span>Login</span>
                        </a>
                    </li>

                    <li
                        className={cn({ 'is-active': loginType === 'digitalSignature' })}
                        onClick={() => setLoginType('digitalSignature')}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <span>ЭЦП</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div className={cn('box is-bordered', css(styles.loginBox))}>
                { loginType === 'default' ? (
                    <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
                        <Form>
                            <ServerError error={signIn.error} />
                            <Input
                                name="email"
                                icon="ion-md-mail"
                                validate={validator(required, email)}
                                placeholder="Email" />

                            <Password
                                name="password"
                                icon="ion-md-key"
                                validate={required}
                                placeholder="Password" />

                            <div className="field">
                                <div className="control">
                                    <Button
                                        loading={signIn.loading}
                                        className="button is-link is-fullwidth is-medium"
                                        text={t('auth')}
                                        type="submit" />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                ) : <LoginWithEImzo signin={signin} setUser={setUser} />}
            </div>
        </LayoutAuth>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
        marginTop: 150,
    },
    img: {
        width: '10rem',
    },
    roundedButton: {
        borderRadius: '50%',
    },
    input: {
        color: '#4a4a4a',
    },
    onlyMobile: {
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
    loginBox: {
        border: '1px solid #dbdbdb',
        borderRadius: '0 6px 6px 6px',
    },
    tabs: {
        marginBottom: '-1px',
        zIndex: 2,
    },
})
