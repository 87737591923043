import React from 'react'
import Button from './common/Button'
import { useGetRequest } from '../hooks/request'
import { DISTRICT_REPORT_TERRITORY } from '../urls'
import { useDownloadExcel } from '../hooks/downloadExcel'
import useTrans from '../hooks/trans'
import { multiReplace } from '../utils/string'

export default function DistrictDetailArchiveItem({ item, district }) {
    const districtDetail = useGetRequest({
        url: DISTRICT_REPORT_TERRITORY.replace('{districtId}', item.district),
        params: { date: item.date },
    })
    const t = useTrans()
    const downloadExcel = useDownloadExcel(districtDetail.request, item.date, district.name)

    return (
        <tr>
            <td>
                <b>
                    {item.date}
                </b>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-link is-reversed is-light is-rounded">
                            <i className="icon ion-ios-speedometer" />
                        </button>
                    </div>

                    <div className="column">
                        { item.limitWater ? item.limitWater : 0 } {t('mln')}
                    </div>
                </div>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-info is-light is-rounded">
                            <i className="icon ion-ios-water" />
                        </button>
                    </div>

                    <div className="column">
                        { item.amountWater ? item.amountWater : 0 } {t('mln')}
                    </div>
                </div>
            </td>

            <td className="has-text-right">
                <Button
                    className="is-info"
                    icon="ion-md-download"
                    text={t('download')}
                    loading={districtDetail.loading}
                    onClick={() => downloadExcel(
                        multiReplace(t('noReport'), { '{date}': item.date, '{name}': district.name }),
                    )} />
            </td>
        </tr>
    )
}
