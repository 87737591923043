import React from 'react'
import { usePostRequest } from '../hooks/request'
import { RESERVOIR_REPORT } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import ReservoirReportForm from './ReservoirReportForm'

export default function ReservoirReportCreate({ onCancel, onSuccess, reservoir, date }) {
    const t = useTrans()
    const reservoirReportCreate = usePostRequest({ url: RESERVOIR_REPORT })

    const sources = reservoir.branch.sources.map((source) => ({
        type: 'in',
        branch: source.id,
        name: source.name,
        morningAmount: '',
        dinnerAmount: '',
    }))

    const recipients = reservoir.branch.recipients.map((recipient) => ({
        type: 'out',
        branch: recipient.id,
        name: recipient.name,
        morningAmount: '',
        dinnerAmount: '',
    }))

    const initialValues = { morningTotal: '', dinnerTotal: '', items: [...sources, ...recipients] }

    async function onSubmit(data) {
        const { success } = await reservoirReportCreate.request({
            data: { reservoir: reservoir.id, date, ...data },
        })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('addingReservoirReport')}:</b><br /><br />
            <ServerError error={reservoirReportCreate.error} />
            <ReservoirReportForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={initialValues}
                loading={reservoirReportCreate.loading} />
        </div>
    )
}
