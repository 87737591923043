import React from 'react'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleBranch } from '../utils/role'

export default function Branch() {
    const [user] = useUser()

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <BranchTabs />
            <h1>Branch</h1>
        </Layout>
    )
}
