import React from 'react'
import cn from 'classnames'
import find from 'lodash/find'
import { useDeleteRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import { HYDROPOST_DETAIL } from '../urls'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import HydropostUpdate from './HydrapostUpdate'
import { useQueryParams } from '../hooks/queryString'
import { getReportDays, TODAY } from '../utils/date'
import HydropostItemReport from './HydropostItemReport'

export default function HydropostItem({ item, onDelete, onSuccess }) {
    const [user] = useUser()
    const { date } = useQueryParams()
    const remove = useDeleteRequest({ url: HYDROPOST_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()
    const t = useTrans()

    const { today, yesterday, lastYear } = getReportDays(date || TODAY)
    const todayReport = find(item.reports, { date: today })
    const yesterdayReport = find(item.reports, { date: yesterday })
    const lastYearReport = find(item.reports, { date: lastYear })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <HydropostUpdate
            item={item}
            onCancel={() => hideUpdateModal()}
            onSuccess={() => {
                hideUpdateModal()
                onSuccess()
            }} />,
    )

    async function handleRemove() {
        if (!global.confirm(t('deleteConfirm'))) return

        const { error } = await remove.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (
        <tr>
            {/* TODO: add measurement */}
            <td className={cn({ 'has-text-weight-bold': !item.parent })}>{item.name}</td>

            <td>
                <HydropostItemReport
                    hydropost={item}
                    date={today}
                    report={todayReport}
                    onUpdate={onSuccess} />
            </td>

            <td>
                <HydropostItemReport
                    hydropost={item}
                    date={yesterday}
                    report={yesterdayReport}
                    onUpdate={onSuccess} />
            </td>

            <td>
                <HydropostItemReport
                    editable={false}
                    hydropost={item}
                    date={lastYear}
                    report={lastYearReport}
                    onUpdate={onSuccess} />
            </td>

            {user.role === 'main' && !item.isArchive ? (
                <td className="has-text-right">
                    <i className="icon pointer ion-md-create" title={t('change')} onClick={showUpdateModal} />

                    {!remove.loading ? (
                        <i className="icon ion-md-trash pointer" title={t('archived')} onClick={handleRemove} />
                    ) : <Loader className="icon" />}
                </td>
            ) : null }
        </tr>
    )
}
