import React from 'react'
import { useModal } from '../hooks/modal'
import useTrans from '../hooks/trans'
import Button from './common/Button'
import ActivePeriod from './ActivePeriod'

export default function ActiveTypeItem({ item }) {
    const t = useTrans()

    const [showPeriodsModal, hidePeriodsModal] = useModal(
        <ActivePeriod
            activeType={item}
            onCancel={() => hidePeriodsModal()}
            onUpdate={() => hidePeriodsModal()} />,
    )

    return (
        <tr>
            <td>
                <span>
                    {item.type.name}
                </span>
            </td>

            <td className="has-text-right">
                <Button
                    onClick={showPeriodsModal}
                    text={t('activePeriods')}
                    icon="ion-md-grid"
                    className="is-small is-link is-light" />&nbsp;

            </td>
        </tr>
    )
}
