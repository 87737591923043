import React from 'react'
import { usePostRequest } from '../hooks/request'
import { HYDROPOST_LIST } from '../urls'
import HydropostForm from './HydropostForm'
import useTrans from '../hooks/trans'

export default function HydropostCreate({ onSuccess, onCancel }) {
    const t = useTrans()
    const hydropostCreate = usePostRequest({ url: HYDROPOST_LIST })

    async function onSubmit(data, actions) {
        const { success } = await hydropostCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('addNewRegion')}</h3>

            <HydropostForm
                onCancel={onCancel}
                loading={hydropostCreate.loading}
                onSubmit={onSubmit} />
        </div>

    )
}
