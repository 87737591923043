import React from 'react'
import useTrans from '../hooks/trans'
import Button from './common/Button'
import { usePutRequest } from '../hooks/request'
import { MAIL_DETAIL } from '../urls'

export default function MailItemModal({ item, loading, onCancel, onDone }) {
    const t = useTrans()
    const mailUpdate = usePutRequest({ url: MAIL_DETAIL.replace('{id}', item.id) })

    async function onSubmit() {
        await mailUpdate.request({ data: {
            ...item, isDone: !item.isDone, to: item.to ? item.to.id : null,
        } })
        onDone()
    }

    return (
        <div className="container">
            <div className="title">{item.title}</div>
            <div className="">
                {t('to')}: {item.to ? `${item.to.firstName} ${item.to.lastName}` : t('all')}
            </div>
            <div className="mb-3">{t('isDone')}: {item.isDone ? t('isDone') : t('isNotDone')}</div>
            <div className="">{item.body}</div>

            <div className="is-pulled-right">
                <Button
                    text={item.isDone ? t('isNotDone') : t('isDone')}
                    loading={loading}
                    onClick={onSubmit}
                    className="is-success ml-2" />&nbsp; &nbsp;

                <Button
                    text={t('cancel')}
                    className="is-danger "
                    onClick={onCancel}
                    type="submit" />
            </div>
        </div>
    )
}
