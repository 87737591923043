import React from 'react'
import { usePostRequest } from '../hooks/request'
import { IRRIGATION_TYPE_LIST } from '../urls'
import useTrans from '../hooks/trans'
import IrrigationTypeForm from './IrrigationTypeForm'

export default function IrrigationTypeCreate({ onCancel, onSuccess }) {
    const t = useTrans()
    const create = usePostRequest({ url: IRRIGATION_TYPE_LIST })

    async function onSubmit(data) {
        await create.request({ data })
        onSuccess()
    }

    return (
        <div>
            <b>{t('create')}</b><br /><br />

            <IrrigationTypeForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={create.loading} />
        </div>
    )
}
