import React from 'react'
import { usePostRequest } from '../hooks/request'
import { TOPIC_LIST } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import DocsTopicForm from './DocsTopicForm'

export default function DocsTopicCreate({ onCancel, onSuccess }) {
    const categoryCreate = usePostRequest({ url: TOPIC_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await categoryCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('adding')}:</b><br /><br />
            <ServerError error={categoryCreate.error} />
            <DocsTopicForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={categoryCreate.loading} />
        </div>
    )
}
