import { Form, Formik } from 'formik'
import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import useTrans from '../hooks/trans'
import Select from './common/Select'
import { useLoad } from '../hooks/request'
import { CATEGORY_LIST } from '../urls'

export default function DocsTopicForm({ onSubmit, onCancel, loading, initialValues }) {
    const category = useLoad({ url: CATEGORY_LIST }, [])
    const t = useTrans()

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ title: '', shortTitle: '', slug: '', body: '', category: '', ...initialValues }}>
            {({ setFieldValue, values }) => (
                <Form>
                    <Input
                        name="title"
                        label={t('title')}
                        disabled={loading}
                        validate={required}
                        placeholder={t('enterTitle')} />

                    <Input
                        name="shortTitle"
                        label={t('shortTitle')}
                        disabled={loading}
                        validate={required}
                        placeholder={t('enterShortTitle')} />

                    <Input
                        name="slug"
                        label={t('slug')}
                        disabled={loading}
                        validate={required}
                        placeholder={t('enterSlug')} />

                    <div className="field control">
                        <label htmlFor="body">
                            {t('body')}
                        </label>

                        <CKEditor
                            editor={ClassicEditor}
                            data={values.body}
                            onChange={(event, editor) => {
                                const data = editor.getData()
                                setFieldValue('body', data)
                            }}
                        />
                    </div>

                    <Select
                        empty
                        name="category"
                        label={t('category')}
                        loading={category.loading}
                        options={category.response ? category.response : []}
                        validate={required} />

                    <div className="is-pulled-right">
                        <Button
                            onClick={onCancel}
                            icon="ion-md-close"
                            text={t('cancel')}
                            className="is-danger" />&nbsp;&nbsp;

                        <Button
                            loading={loading}
                            text={t('save')}
                            type="submit"
                            icon="ion-md-checkmark"
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
