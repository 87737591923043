import React from 'react'
import { Formik, Form } from 'formik'
import useTrans from '../hooks/trans'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'

export default function HydropostReportForm({ onSubmit, onCancel, loading, initialValues }) {
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={{ morningAmount: null, dinnerAmount: null, ...initialValues }}>
            <Form>
                <Input
                    disabled
                    label={t('hydropost')}
                    name="name"
                    step="0.01" />

                <Input
                    label={t('forMorning')}
                    name="morningAmount"
                    type="number"
                    step="0.01"
                    validate={required} />

                <Input
                    label={t('forEvening')}
                    name="dinnerAmount"
                    type="number"
                    step="0.01" />

                <div>
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
