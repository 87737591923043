import React from 'react'
import { useDeleteRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import { useModal } from '../hooks/modal'
import { DISTRICT_DETAIL } from '../urls'
import DistrictUpdate from './DistrictUpdate'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function DistrictItem({ item, onDelete, onSuccess, irrigationSystem }) {
    const [user] = useUser()
    const districtArchive = useDeleteRequest({ url: DISTRICT_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()
    const t = useTrans()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <DistrictUpdate
            item={item}
            hideModal={() => hideUpdateModal()}
            onSuccess={onSuccess}
            irrigationSystem={irrigationSystem} />,
    )

    async function archiveDistrict() {
        if (!global.confirm(t('archiveRegionSector'))) return

        const { error } = await districtArchive.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (
        <tr>
            <td>{item.name}</td>

            <td>{item.branches.map((response) => response.name).join(', ')}</td>

            {user.role === 'main' && !item.isArchive ? (
                <td className="has-text-right">
                    <i className="icon pointer ion-md-create" title={t('change')} onClick={showUpdateModal} />
                    {!districtArchive.loading ? (
                        <i className="icon ion-md-archive pointer" title={t('archived')} onClick={archiveDistrict} />
                    ) : <Loader className="icon" />}
                </td>
            ) : null }
        </tr>
    )
}
