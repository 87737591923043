import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { usePostRequest } from '../hooks/request'
import { USER_SIGN_UP } from '../urls'
import Input from '../components/common/Input'
import Button from '../components/common/Button'
import { useMessage } from '../hooks/message'

export default function ResponsibleSignUp() {
    const { code } = useParams()
    const history = useHistory()
    const invitationSignUp = usePostRequest({ url: USER_SIGN_UP.replace('{code}', code) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        if (data.password !== data.confirmPassword) {
            showMessage('Пароль не совпадает!')
            return
        }

        const { success } = await invitationSignUp.request({ data })

        if (success) {
            showMessage('Ваша учетная запись была активирована, пожалуйста, войдите!!!')
            history.push('/')
        }
    }

    return (
        <div className="hero">
            <div className={cn('hero-body has-text-centered', css(styles.container))}>
                <div className="columns is-centered">
                    <div className="column is-4">
                        <h2 className={cn('is-size-4 has-text-weight-bold', css(styles.hasTextPrimary))}>
                            Введите пароль
                        </h2>
                        <br />

                        <Formik initialValues={{ password: '', confirmPassword: '' }} onSubmit={onSubmit}>
                            <Form>
                                <Input placeholder="Пароль" name="password" type="password" />

                                <Input placeholder="Подвердите Пароль" name="confirmPassword" type="password" />

                                <Button
                                    type="submit"
                                    text="Сохранить"
                                    className="button is-link is-fullwidth is-medium" />
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
        marginTop: 150,
    },
    img: {
        width: '10rem',
    },
    roundedButton: {
        borderRadius: '50%',
    },
    input: {
        color: '#4a4a4a',
    },
    onlyMobile: {
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
})
