import React from 'react'
import { usePostRequest } from '../hooks/request'
import { TERRITORY_LIST } from '../urls'
import TerritoryForm from './TerritoryForm'

export default function TerritoryCreate({ onCancel, onSuccess, params }) {
    const territories = usePostRequest({ url: TERRITORY_LIST })

    async function onSubmit(data) {
        await territories.request({ data: {
            ...data,
            sector: params.id,
        } })
        onSuccess()
    }

    return (
        <div>
            <TerritoryForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={territories.loading} />
        </div>
    )
}
