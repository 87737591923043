import React from 'react'
import { usePostRequest } from '../hooks/request'
import { CATEGORY_LIST } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import DocsCategoryForm from './DocsCategoryForm'

export default function DocsCategoryCreate({ onCancel, onSuccess }) {
    const categoryCreate = usePostRequest({ url: CATEGORY_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await categoryCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('adding')}:</b><br /><br />
            <ServerError error={categoryCreate.error} />
            <DocsCategoryForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={categoryCreate.loading} />
        </div>
    )
}
