import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import DistrictTabs from '../components/DistrictTabs'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import Table from '../components/common/Table'
import { roleDistrictWithSector } from '../utils/role'
import useTrans from '../hooks/trans'
import DistrictDetailArchiveItem from '../components/DistrictDetailArchiveItem'
import { useLoad } from '../hooks/request'
import { DISTRICT_DETAIL, DISTRICT_DETAIL_REPORT_LIST } from '../urls'

export default function DistrictDetailArchives() {
    const [user] = useUser()
    const t = useTrans()
    const [page, setPage] = useState(1)
    const params = useParams()
    const districtDetail = useLoad({ url: DISTRICT_DETAIL.replace('{id}', params.id) }, [params])
    const reports = useLoad({
        url: DISTRICT_DETAIL_REPORT_LIST.replace('{districtId}', params.id), params: { page },
    }, [params, page])
    const district = districtDetail.response ? districtDetail.response : {}

    if (!roleDistrictWithSector.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5 is-vcentered">
                <h3 className="title is-3 has-text-weight-normal">{t('listReports')}, {district.name}</h3>
            </div>

            <Table
                loading={reports.loading}
                activePage={page}
                onPageChange={setPage}
                pageSize={15}
                totalCount={reports.response ? reports.response.count : 0}
                items={reports.response ? reports.response.results : []}
                columns={{ date: t('date'), limit: t('limit'), water: t('water'), actions: '' }}
                renderItem={(item) => <DistrictDetailArchiveItem key={item.id} item={item} district={district} />} />
        </Layout>
    )
}
