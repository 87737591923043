import React from 'react'
import find from 'lodash/find'
import useTrans from '../hooks/trans'
import { getReportDays, TODAY } from '../utils/date'
import { useQueryParams } from '../hooks/queryString'
import ReservoirItemReport from './ReservoirItemReport'

export default function ReservoirItem({ item, onUpdate }) {
    const { date } = useQueryParams()
    const t = useTrans()

    const { today, yesterday, lastYear } = getReportDays(date || TODAY)
    const todayReport = find(item.reports, { date: today })
    const yesterdayReport = find(item.reports, { date: yesterday })
    const lastYearReport = find(item.reports, { date: lastYear })

    return (
        <tr>
            <td>{item.branch.name}</td>

            <td>
                {t('total')}:<br />
                {t('waterIn')}: <br />
                {t('waterOut')}: <br />
            </td>

            <td>
                <ReservoirItemReport
                    reservoir={item}
                    date={today}
                    report={todayReport}
                    onUpdate={onUpdate} />
            </td>

            <td>
                <ReservoirItemReport
                    reservoir={item}
                    date={yesterday}
                    report={yesterdayReport}
                    onUpdate={onUpdate} />
            </td>

            <td>
                <ReservoirItemReport
                    reservoir={item}
                    date={lastYear}
                    report={lastYearReport}
                    onUpdate={onUpdate}
                    editable={false} />
            </td>

            <td className="has-text-right" />
        </tr>
    )
}
