import React from 'react'
import { useLoad } from '../hooks/request'
import Card from './common/Card'
import { STATISTICS_DISTRICT_REPLENISH } from '../urls'
import Table from './common/Table'
import DistrictReplenishedItem from './DistrictReplenishedItem'
import useTrans from '../hooks/trans'

export default function StatisticsDistrictReplenish({ startDate, endDate }) {
    const districtReplenish = useLoad({ url: STATISTICS_DISTRICT_REPLENISH, params: { startDate, endDate } })
    const t = useTrans()

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                {t('limitExpense')}
            </div>

            <Table
                loading={districtReplenish.loading}
                items={districtReplenish.response ? districtReplenish.response : []}
                columns={{
                    name: t('region'),
                    limitWater: t('limitWater'),
                    amountWater: t('used'),
                    difference: t('difference'),
                }}
                renderItem={(item) => (
                    <DistrictReplenishedItem key={item.id} item={item} />
                )} />
        </Card>
    )
}
