import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import useTrans from '../hooks/trans'

export default function SettingsTabs() {
    const { path } = useRouteMatch()
    const t = useTrans()

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith('/settings/user') })}>
                    <NavLink to="/settings/user">{t('account')}</NavLink>
                </li>
            </ul>
        </div>
    )
}
