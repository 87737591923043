import React from 'react'
import cn from 'classnames'
import Navbar from './Navbar'

export default function Layout({ children, containerOff }) {
    return (
        <div>
            <Navbar />

            <div className={cn({ container: !containerOff }, 'my-5')}>
                {children}
            </div>
        </div>
    )
}
