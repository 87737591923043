import React from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import Select from './common/Select'
import useTrans from '../hooks/trans'

export default function DistrictForm({
    onCancel,
    irrigationSystem,
    onSubmit,
    loading,
    initialValues = {},
    sector,
}) {
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            sector,
            ...initialValues,
        }}>
            <Form>
                <Input
                    label={t('name')}
                    name="name"
                    validate={required} />

                <Select
                    empty
                    name="irrigationSystem"
                    label={t('irrigationSystem')}
                    validate={required}
                    options={irrigationSystem}
                />

                <div className="is-pulled-right">
                    <Button
                        text={t('back')}
                        className="is-danger ml-2"
                        onClick={onCancel} />&nbsp; &nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        className="is-success"
                        type="submit" />
                </div>
            </Form>
        </Formik>
    )
}
