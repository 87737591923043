import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { useFormikContext } from 'formik'
import { required } from '../utils/validators'
import TableInput from './TableInput'

export default function DistrictReportFormItem({ item, index, disabled, activePeriod }) {
    const { values, errors, handleChange, setFieldValue } = useFormikContext()
    const valueFormik = values.items[index]
    const totalAreaSum = useRef({})

    const calculateCapacity = async (e) => {
        handleChange(e)
        let capacity = 0

        activePeriod.forEach((i) => {
            capacity += ((e.target.value * 86400) * 0.61) / i.period.capability
        })
        capacity /= activePeriod.length

        setFieldValue(`items.${index}.capacity`, parseInt(capacity, 10))
    }

    const calculateTotalAreaDay = () => {
        const res = Object.values(totalAreaSum.current).reduce((acc, curr) => acc + curr, 0)
        setFieldValue(`items.${index}.totalAreaDay`, res)
    }

    return (
        <tr key={item.id}>
            <td className={cn(css(styles.border), 'pt-1')}>{item.name}</td>

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    name={`items.${index}.limit`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    disabled={disabled}
                    step="0.01"
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].limit
                    ) : null}
                    type="number"
                    validate={required} />
            </td>

            <td className={cn(css(styles.border), 'has-background-white-ter')}>
                <TableInput
                    name={`items.${index}.yesterdayWater`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    disabled
                    type="number" />
            </td>

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    name={`items.${index}.amountWater`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    disabled={disabled}
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].amountWater
                    ) : null}
                    onChange={calculateCapacity}
                    validate={required}
                    step="0.01"
                    type="number" />
            </td>

            <td className={cn(css(styles.border), 'has-background-white-ter')}>
                <TableInput
                    name={`items.${index}.capacity`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    disabled
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].capacity
                    ) : null}
                    validate={required}
                    step="0.01"
                    type="number" />
            </td>

            <td className={cn(css(styles.border), 'has-background-white-ter')}>
                <p className="py-2">
                    {
                        valueFormik.totalAreaDay > 0 && valueFormik.capacity > 0
                            ? Math.round((valueFormik.totalAreaDay / valueFormik.capacity) * 100) / 100
                            : 0
                    }
                </p>
            </td>

            <td className={cn(css(styles.border), 'has-background-white-ter')}>
                <TableInput
                    name={`items.${index}.totalAreaDay`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].totalAreaDay
                    ) : null}
                    min="0"
                    disabled
                    validate={required}
                    step="0.01"
                    type="number" />
            </td>

            {activePeriod.map((period) => (
                <td key={period.id} className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                    <TableInput
                        disabled={disabled}
                        name={`items.${index}.period-${period.id}`}
                        className={cn(css(styles.input), 'has-text-centered')}
                        onChange={(e) => {
                            handleChange(e)
                            totalAreaSum.current = {
                                ...totalAreaSum.current,
                                [`items.${index}.period-${period.id}`]: +e.target.value,
                            }
                            calculateTotalAreaDay(e)
                        }}
                        min="0"
                        type="number" />
                </td>
            ))}

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    disabled={disabled}
                    name={`items.${index}.watermanCount`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].watermanCount
                    ) : null}
                    validate={required}
                    type="number" />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    border: {
        border: '1px solid black',
        minWidth: 110,
    },
    input: {
        all: 'unset',
        padding: '7px 0',
        width: '100%',
    },
})
