import React from 'react'
import { usePutRequest } from '../hooks/request'
import { TERRITORY_SECTOR_DETAIL } from '../urls'
import TerritorySectorForm from './TerritorySectorForm'
import useTrans from '../hooks/trans'

export default function TerritorySectorUpdate({ values, onCancel, onSuccess }) {
    const territorySectorUpdate = usePutRequest({ url: TERRITORY_SECTOR_DETAIL.replace('{id}', values.id) })
    const t = useTrans()

    async function onSubmit(data) {
        await territorySectorUpdate.request({ data })
        onSuccess()
    }

    return (
        <div>
            <b>{t('change')}</b><br /><br />

            <TerritorySectorForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={territorySectorUpdate.loading}
                initialValues={values}
                onSuccess={onSuccess} />
        </div>
    )
}
