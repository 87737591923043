import React, { useState, useEffect, Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import Button from './common/Button'
import { getReportDays, TODAY } from '../utils/date'
import PumpStationReportCreate from './PumpStationReportCreate'
import { useQueryParams } from '../hooks/queryString'
import PumpStationReportUpdate from './PumpStationReportUpdate'

export default function PumpStationReportItem({ item, onUpdate }) {
    const t = useTrans()
    const params = useQueryParams()

    const { today, yesterday, lastYear } = getReportDays(params.date || TODAY)

    const [todayReport, setTodayReport] = useState(null)
    const [yesterdayReport, setYesterdayReport] = useState(null)
    const [lastYearReport, setLastYearReport] = useState(null)

    useEffect(() => {
        setTodayReport(item.report.find((report) => report.date === today))
        setYesterdayReport(item.report.find((report) => report.date === yesterday))
        setLastYearReport(item.report.find((report) => report.date === lastYear))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [showCreateModal, hideCreateModal] = useModal(
        <PumpStationReportCreate pumpStation={item} onSuccess={() => {
            hideCreateModal()
            onUpdate()
        }} onCancel={() => {
            hideCreateModal()
        }} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <PumpStationReportUpdate pumpStation={item} item={todayReport} onSuccess={() => {
            hideUpdateModal()
            onUpdate()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <Fragment>
            <tr>
                <td className={css(styles.branchReportItem)}>
                    {item.branch.name}
                </td>

                <td>
                    {item.aggregates}
                </td>

                <td>
                    {yesterdayReport && `${yesterdayReport.activeAggregates} / 
                        ${parseFloat(yesterdayReport.activeAggregates * item.waterCapability).toFixed(1)}`}
                </td>

                <td>
                    {todayReport && todayReport.activeAggregates && (
                        `${todayReport.activeAggregates} / 
                        ${parseFloat(todayReport.activeAggregates * item.waterCapability).toFixed(1)}`
                    )}
                </td>

                <td>
                    {todayReport && todayReport.dinnerActiveAggregates && `${todayReport.dinnerActiveAggregates} / 
                        ${parseFloat(todayReport.dinnerActiveAggregates * item.waterCapability).toFixed(1)}`}
                </td>

                <td>
                    {todayReport ? (
                        parseFloat(todayReport.activeAggregates * item.energy).toFixed(1)
                    ) : 0.0}
                    &nbsp;{t('mgwt')}
                </td>

                <td>
                    {lastYearReport ? (
                        `${lastYearReport.activeAggregates} / 
                        ${parseFloat(lastYearReport.activeAggregates * item.waterCapability).toFixed(1)}`
                    ) : ''}
                </td>

                <td>
                    { TODAY === today && (
                        todayReport ? (
                            <Button onClick={showUpdateModal}
                                text={t('change')} icon="ion-md-create" className="is-warning is-light is-small" />
                        ) : (
                            <Button onClick={showCreateModal}
                                text={t('fill')} icon="ion-md-document" className="is-info is-light is-small" />
                        ))}
                </td>
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchReportItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
