import React from 'react'
import { useModal } from '../hooks/modal'
import TerritoryUpdate from './TerritoryUpdate'
import { useDeleteRequest } from '../hooks/request'
import { TERRITORY_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function TerritoryItem({ item, onSuccess, params }) {
    const [user] = useUser()
    const territoryArchive = useDeleteRequest({ url: TERRITORY_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()
    const t = useTrans()
    const [showUpdateModal, hideUpdateModal] = useModal(
        <TerritoryUpdate
            params={params}
            values={item}
            onCancel={() => hideUpdateModal()}
            onSuccess={() => {
                hideUpdateModal()
                onSuccess()
            }} />,
    )

    async function archiveTerritory() {
        if (!global.confirm(t('deleteSector'))) return

        const { error } = await territoryArchive.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
        }
        onSuccess()
    }

    return (
        <tr>
            <td>
                <span>
                    {item.name}
                </span>
            </td>

            {user.role === 'main' ? (
                <td className="has-text-right">
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                    <i onClick={() => archiveTerritory()} className="icon pointer ion-md-archive" />
                </td>
            ) : null}
        </tr>
    )
}
