import { Form, Formik } from 'formik'
import React from 'react'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import useTrans from '../hooks/trans'
import { useLoad } from '../hooks/request'
import { DISTRICT_LIST } from '../urls'
import Select from './common/Select'

export default function PumpStationForm({ onSubmit, onCancel, loading, initialValues }) {
    const t = useTrans()
    const districts = useLoad({ url: DISTRICT_LIST }, [])

    const options = districts.response ? (
        districts.response.results.map((item) => ({ id: item.id, name: item.name }))
    ) : []

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ name: '', responsible: '', ...initialValues }}>
            <Form>
                <Input
                    name="name"
                    label={t('name')}
                    disabled={loading}
                    validate={required}
                    placeholder={t('enterName')} />

                <Input
                    name="aggregates"
                    label={t('aggregates')}
                    type="number"
                    disabled={loading}
                    validate={required}
                    placeholder={t('enterAggregates')} />

                <Input
                    name="energy"
                    label={t('energy')}
                    type="number"
                    disabled={loading}
                    validate={required}
                    help={t('mgwt')}
                    placeholder={t('enterEnergy')} />

                <Input
                    name="waterCapability"
                    label={t('waterCapability')}
                    type="number"
                    disabled={loading}
                    validate={required}
                    help={t('m3sek')}
                    placeholder={t('enterWaterCapability')} />

                <Input
                    name="waterHeight"
                    label={t('waterHeight')}
                    type="number"
                    disabled={loading}
                    validate={required}
                    help={t('metr')}
                    placeholder={t('enterWaterHeight')} />

                <Select
                    empty
                    name="district"
                    label={t('district')}
                    options={options} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
