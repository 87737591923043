import React, { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import Button from './common/Button'
import useTrans from '../hooks/trans'
import { usePostRequest } from '../hooks/request'
import { SIGNINWITHPINFL } from '../urls'

export default function LoginWithEImzo({ setUser, signin }) {
    const t = useTrans()
    const history = useHistory()
    const labelRef = useRef(null)
    const [, setSelected] = useState('')
    const signInWithPinfl = usePostRequest({ url: SIGNINWITHPINFL })
    function handleChange(e) {
        [...e.target.querySelectorAll('option')].slice(1).forEach((item) => {
            const serialNumber = e.target.value.slice(4, -2)
            const personInfo = JSON.parse(item.getAttribute('vo'))
            if (personInfo.serialNumber === serialNumber) {
                setSelected(personInfo.PINFL)
            }
        })
        labelRef.current.textContent = ''
        window.privatePINFL = ''
    }

    async function handleSubmit() {
        if (!window.privatePINFL) return

        const { response, success } = await signInWithPinfl.request({
            data: {
                pinfl: atob(window.privatePINFL),
            },
        })
        if (success) {
            setUser(response.user)
            signin(response, history)
        }
    }

    useEffect(() => {
        window.privatePINFL = ''
        const body = document.querySelector('body')
        const pathes = ['./e-imzo/core-eimzo.js', './e-imzo/e-imzo.js', './e-imzo/e-imzo-client.js']
        if (!body.querySelector('script[src*="eimzo"]')) {
            pathes.forEach((path) => {
                const scriptTag = document.createElement('script')
                scriptTag.setAttribute('src', path)
                scriptTag.setAttribute('id', 'eimzo')
                body.appendChild(scriptTag)
            })

            document.getElementById('eimzo-btn')
                .setAttribute('onclick', 'sign()')
        }
    }, [])

    return (
        <Formik onSubmit={handleSubmit} initialValues={{ name: 'name' }}>
            <Form name="testform">
                <label id="message" style={{ color: 'red' }} />
                Выберите ключ <br />
                <div className="select">
                    <select name="key" onChange={handleChange} />
                </div>
                <textarea name="data" defaultValue="123" className="is-hidden" />
                <label id="keyId" ref={labelRef} className="is-hidden" />
                <textarea name="pkcs7" className="is-hidden" />
                <br />
                <Button
                    id="eimzo-btn"
                    loading=""
                    type="submit"
                    className="button is-link is-fullwidth is-medium mt-3"
                    text={t('auth')} />
            </Form>
        </Formik>
    )
}
