import React from 'react'
import { usePutRequest } from '../hooks/request'
import { TERRITORY_DETAIL } from '../urls'
import TerritoryForm from './TerritoryForm'
import useTrans from '../hooks/trans'

export default function TerritoryUpdate({ values, onCancel, onSuccess, params }) {
    const territoryUpdate = usePutRequest({ url: TERRITORY_DETAIL.replace('{id}', values.id) })
    const t = useTrans()

    async function onSubmit(data) {
        await territoryUpdate.request({ data: {
            ...data,
            sector: params.id,
        } })
        onSuccess()
    }

    return (
        <div>
            <b>{t('change')}</b><br /><br />

            <TerritoryForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={territoryUpdate.loading}
                initialValues={values}
                onSuccess={onSuccess} />
        </div>
    )
}
