import React, { useState } from 'react'
import Table from '../components/common/Table'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { DISTRICT_LIST } from '../urls'
import PlanDistrictItem from '../components/PlanDistrictItem'
import DistrictTabs from '../components/DistrictTabs'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleMain } from '../utils/role'
import useTrans from '../hooks/trans'

export default function PlanDistrictList() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const district = useLoad({ url: DISTRICT_LIST, params: { page } }, [page])
    const t = useTrans()

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h1 className="title is-3 has-text-weight-normal"><span>{t('listTerritories')}</span></h1>
            </div>

            <Table
                loading={district.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={district.response ? district.response.count : 0}
                columns={{
                    name: t('name'),
                }}
                items={district.response ? district.response.results : []}
                renderItem={(item) => (
                    <PlanDistrictItem
                        districtItem={item}
                        key={item.id} />
                )} />
        </Layout>
    )
}
