import React from 'react'
import { Form, Formik } from 'formik'
import Select from './common/Select'
import { usePostRequest } from '../hooks/request'
import { SEASON_LIST } from '../urls'
import { required } from '../utils/validators'
import Button from './common/Button'
import { types } from '../utils/position'
import useTrans from '../hooks/trans'

export default function SeasonUpdate({ onSuccess }) {
    const seasonUpdate = usePostRequest({ url: SEASON_LIST })
    const user = JSON.parse(localStorage.getItem('user'))
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await seasonUpdate.request({ data: {
            ...data,
            irrigationManagement: user.irrigationManagement,
        } })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('adding')}:</h3>

            <Formik initialValues={{ type: '' }} onSubmit={onSubmit}>
                <Form>
                    <Select
                        name="type"
                        empty
                        validate={required}
                        optionValue="value"
                        optionLabel="title"
                        options={types.map(({ value, title }) => ({ value, title: t(title) }))} />

                    <div className="is-pulled-right">
                        <Button
                            type="submit"
                            className="is-link"
                            text={t('save')}
                            loading={seasonUpdate.loading} />
                    </div>
                </Form>
            </Formik>
        </div>
    )
}
