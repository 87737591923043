import React from 'react'
import { usePutRequest } from '../hooks/request'
import { HYDROPOST_DETAIL } from '../urls'
import HydropostForm from './HydropostForm'
import useTrans from '../hooks/trans'

export default function HydropostUpdate({ item, onSuccess, onCancel }) {
    const hydropostUpdate = usePutRequest({ url: HYDROPOST_DETAIL.replace('{id}', item.id) })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await hydropostUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('changeRegion')}</h3>

            <HydropostForm
                onCancel={onCancel}
                initialValues={item}
                loading={hydropostUpdate.loading}
                onSubmit={onSubmit} />
        </div>
    )
}
