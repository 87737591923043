import React from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import Layout from '../components/Layout'
import StatisticsWaterLimit from '../components/StatisticsWaterLimit'
import Card from '../components/common/Card'
import DateRange from '../components/common/DateRange'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import StatisticsTotalArea from '../components/StatisticsTotalArea'
import StatisticsAmountWater from '../components/StatisticsAmountWater'
import StatisticsDistrictReplenish from '../components/StatisticsDistrictReplenish'
import StatisticsDayAmountWater from '../components/StatisticsDayAmountWater'
import StatisticsEmptyDistrict from '../components/StatisticsEmptyDistrict'

export default function Dashboard() {
    const history = useHistory()
    const params = useQueryParams()
    const startDate = params.startDate || moment().startOf('month').format(DATE_FORMAT)
    const endDate = params.endDate || moment().endOf('month').format(DATE_FORMAT)

    return (
        <Layout>
            <Card>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={(dates) => {
                        history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                    }} />
            </Card><br />

            <div className="columns">
                <StatisticsTotalArea startDate={startDate} endDate={endDate} />

                <StatisticsAmountWater startDate={startDate} endDate={endDate} />

                <StatisticsEmptyDistrict />
            </div>

            <StatisticsWaterLimit startDate={startDate} endDate={endDate} />

            <StatisticsDayAmountWater startDate={startDate} endDate={endDate} />

            <StatisticsDistrictReplenish startDate={startDate} endDate={endDate} />
        </Layout>
    )
}
