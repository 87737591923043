import React from 'react'
import map from 'lodash/map'
import { usePutRequest } from '../hooks/request'
import { DISTRICT_DETAIL } from '../urls'
import DistrictForm from './DistrictForm'
import useTrans from '../hooks/trans'

export default function DistrictUpdate({ hideModal, item, onSuccess, irrigationSystem }) {
    const districtUpdate = usePutRequest({ url: DISTRICT_DETAIL.replace('{id}', item.id) })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await districtUpdate.request({ data: {
            ...data,
            channels: map(data.channels, 'value'),
        } })

        if (success) {
            actions.resetForm()
            onSuccess()
            hideModal()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('changeRegion')}</h3>

            <DistrictForm
                onCancel={hideModal}
                initialValues={{
                    ...item,
                    sector: item.sector ? item.sector.id : '',
                }}
                irrigationSystem={irrigationSystem}
                sector={item.sector ? item.sector.id : ''}
                loading={districtUpdate.loading}
                onSubmit={onSubmit} />
        </div>
    )
}
