import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'

import { roleBranch } from '../utils/role'
import { BRANCH_REPORT_ITEMS } from '../urls'
import BranchReportItem from '../components/BranchReportItem'
import { PermissionDenied } from '../components/PermissionDenied'
import useTrans from '../hooks/trans'
import { useUser } from '../hooks/user'
import { useInfiniteScroll } from '../hooks/request'
import Loader from '../components/common/Loader'
import Button from '../components/common/Button'

export default function BranchReports() {
    const t = useTrans()
    const [user] = useUser()
    const reporter = user && user.reporter && user.reporter.id

    const branchReportItems = useInfiniteScroll(
        {
            url: BRANCH_REPORT_ITEMS,
            params: { size: 30, reporter },
        }, [reporter],
    )

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <BranchTabs />
            <div className="is-flex is-flex-direction-column is-justify-content-center">
                <h1 className="title is-3 has-text-weight-normal has-text-centered mt-5 mb-0">
                    {t('territoryReportReplenishment')}
                </h1>

                <Button
                    onClick={() => branchReportItems.reload()}
                    icon="ion-md-refresh"
                    text="Yangilash"
                    className="is-align-self-flex-end mr-5 is-link" />
            </div>

            <table className={cn(css(styles.table), css(styles.element), 'table')}>
                <thead>
                    <tr>
                        <th>{t('channel')}</th>
                        <th>{t('forMorning')} ({t('level')})</th>
                        <th>{t('forEvening')} ({t('level')})</th>
                        <th>{t('forMorning')}</th>
                        <th>{t('forEvening')}</th>
                        <th>{t('forAverage')}</th>
                        <th>{t('branchLevelCorrection')}</th>
                        <th>{t('status')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                </thead>

                <tbody>
                    {branchReportItems.response && branchReportItems.response.results.map((branchReportItem, index) => (
                        <BranchReportItem
                            index={index}
                            response={branchReportItems.response}
                            item={branchReportItem}
                            key={branchReportItem.id}
                            branchReportItems={branchReportItems} />
                    ))}
                </tbody>
            </table>

            <Loader show={branchReportItems.loading} center large />
        </Layout>
    )
}

const styles = StyleSheet.create({
    table: {
        width: '100%',
    },
    container: {
        overflow: 'auto',
        padding: '0 100px 15px',
    },
    element: {
        minWidth: 100,
        scrollSnapStop: 'normal',
    },
    input: {
        all: 'unset',
        padding: '7px 0',
        width: '100%',
    },
})
