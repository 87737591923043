import React, { Fragment, useContext, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import cn from 'classnames'
import { signOut } from '../utils/auth'
import { useDeleteRequest } from '../hooks/request'
import { SIGNOUT } from '../urls'
import { useUser } from '../hooks/user'
import { roleDistrictWithSector } from '../utils/role'
import { LanguageContext } from '../contexts/LanguageContext'
import useTrans from '../hooks/trans'
import { ThemeContext } from '../contexts/ThemeContext'

export default function Navbar() {
    const [active, setActive] = useState(false)
    const history = useHistory()
    const [user, setUser] = useUser()
    const signout = useDeleteRequest({ url: SIGNOUT })
    const { path } = useRouteMatch()
    const { lang, setLang } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext)
    const t = useTrans()

    function logOut() {
        signout.request({}, true)
        signOut(history)
        setUser({})
    }

    let territoryLink = '/district-sector/reports'
    if (user.role === 'districtSector') {
        territoryLink = '/district/archives'
    }
    if (user.role === 'district') {
        territoryLink = `/district/${user.district.id}/reports`
    }

    return (
        <div className={cn(`has-background-${theme.slice(3)} mb-2`, css(styles.navbar))}>
            <nav className={`navbar container ${theme}`} role="navigation" aria-label="main navigation">
                {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/anchor-is-valid */}
                <a
                    role="button"
                    className="navbar-burger"
                    aria-label="menu"
                    aria-expanded="false"
                    onClick={() => setActive(!active)}
                    data-target="test">
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </a>

                <div id="test" className={cn('navbar-menu', { 'is-active': active })}>
                    <div className="navbar-start">
                        {user.role === 'main' ? (
                            <NavLink
                                to="/dashboard"
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/dashboard') },
                                )}>
                                <i className="icon ion-ios-pie" />&nbsp;{t('dashboard')}
                            </NavLink>
                        ) : null }

                        {roleDistrictWithSector.includes(user.role) ? (
                            <NavLink
                                to={territoryLink}
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/district')
                                        || path.startsWith('/territory-sector') },
                                )}>
                                <i className="icon ion-md-map" />&nbsp;
                                {t('regions')}
                            </NavLink>
                        ) : null }

                        {user.role === 'main' || (user.reporter && user.reporter.type !== 'pump_station') ? (
                            <NavLink
                                to="/branch/report"
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/branch') },
                                )}>
                                <i className="icon ion-md-business" />&nbsp;{t('tib')}
                            </NavLink>
                        ) : null}

                        {user.role === 'district' && user.reporter && user.reporter.type === 'pump_station' ? (
                            <NavLink
                                to="/pump-stations"
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/pump-stations') },
                                )}>
                                <i className="icon ion-md-cog" />&nbsp;{t('pumps')}
                            </NavLink>
                        ) : null }

                        {user.role === 'main' ? (
                            <Fragment>
                                <NavLink
                                    to="/season"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/season') },
                                    )}>
                                    <i className="icon ion-md-rainy" />&nbsp;{t('season')}
                                </NavLink>

                                <NavLink
                                    to="/pump-stations"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/pump-stations') },
                                    )}>
                                    <i className="icon ion-md-cog" />&nbsp;{t('pumps')}
                                </NavLink>

                                <NavLink
                                    to="/reservoir"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/reservoir') },
                                    )}>
                                    <i className="icon ion-md-water" />&nbsp;{t('reservoir')}
                                </NavLink>

                                <NavLink
                                    to="/hydroposts"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/hydroposts') },
                                    )}>
                                    <i className="icon ion-md-water" />&nbsp;{t('hydroposts')}
                                </NavLink>

                                <NavLink
                                    to="/users/responsible"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/users/re') },
                                    )}>
                                    <i className="icon ion-md-people" />&nbsp;{t('users')}
                                </NavLink>
                            </Fragment>
                        ) : null }

                        {user.role === 'reporter' ? (
                            <Fragment>
                                {user.reporter.type === 'pump_station' ? (
                                    <NavLink
                                        to="/pump-stations"
                                        className={cn(
                                            'navbar-item',
                                            { [css(styles.borderIsActive)]: path.startsWith('/pump-stations') },
                                        )}>
                                        <i className="icon ion-md-cog" />&nbsp;{t('pumps')}
                                    </NavLink>
                                ) : null }

                                {user.reporter.type !== 'pump_station' ? (
                                    <Fragment>
                                        <NavLink
                                            to="/reservoir"
                                            className={cn(
                                                'navbar-item',
                                                { [css(styles.borderIsActive)]: path.startsWith('/reservoir') },
                                            )}>
                                            <i className="icon ion-md-water" />&nbsp;{t('reservoir')}
                                        </NavLink>

                                        <NavLink
                                            to="/hydroposts"
                                            className={cn(
                                                'navbar-item',
                                                { [css(styles.borderIsActive)]: path.startsWith('/hydroposts') },
                                            )}>
                                            <i className="icon ion-md-water" />&nbsp;{t('hydroposts')}
                                        </NavLink>
                                    </Fragment>
                                ) : null }
                            </Fragment>
                        ) : null}
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <div className={cn(
                                'navbar-link',
                                { 'is-active': path.startsWith('/mails')
                                        || path.startsWith('/docs')
                                        || path.startsWith('/settings') },
                            )} title={t('setting')}>
                                <i className="icon ion-md-person" /> &nbsp;
                                { `${user.firstName} ${user.lastName}` || user.role.email}
                            </div>

                            <div className="navbar-dropdown">
                                <NavLink
                                    to="/docs/start"
                                    className={cn(
                                        'navbar-item',
                                        { 'is-active': path.startsWith('/docs') },
                                    )}>
                                    <i className="icon ion-md-rainy" /> &nbsp; {t('docs')}
                                </NavLink>

                                <NavLink
                                    to="/mails"
                                    className={cn(
                                        'navbar-item',
                                        { 'is-active': path.startsWith('/mails') },
                                    )}>
                                    <i className="icon ion-md-mail" /> &nbsp; {t('mails')}
                                </NavLink>

                                <NavLink
                                    to="/settings/user"
                                    className={cn(
                                        'navbar-item',
                                        { 'is-active': path.startsWith('/settings') },
                                    )}>
                                    <i className="icon ion-md-settings" /> &nbsp; {t('setting')}
                                </NavLink>

                                <hr className="navbar-divider" />

                                {lang !== 'uz' && (
                                    <NavLink className="navbar-item" to="#" onClick={() => setLang('uz')}>
                                        <i className="icon ion-md-globe" /> &nbsp;
                                        {t('uz')}
                                    </NavLink>
                                )}

                                {lang !== 'kir' && (
                                    <NavLink className="navbar-item" to="#" onClick={() => setLang('kir')}>
                                        <i className="icon ion-md-globe" /> &nbsp;
                                        {t('kir')}
                                    </NavLink>
                                )}

                                {lang !== 'ru' && (
                                    <NavLink className="navbar-item" to="#" onClick={() => setLang('ru')}>
                                        <i className="icon ion-md-globe" /> &nbsp;
                                        {t('ru')}
                                    </NavLink>
                                )}

                                {lang !== 'en' && (
                                    <NavLink className="navbar-item" to="#" onClick={() => setLang('en')}>
                                        <i className="icon ion-md-globe" /> &nbsp;
                                        {t('en')}
                                    </NavLink>
                                )}

                                <hr className="navbar-divider" />

                                <NavLink to="/" onClick={logOut} className="navbar-item">
                                    <i className="icon ion-md-power" />&nbsp; {t('exit')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

const styles = StyleSheet.create({
    borderIsActive: {
        backgroundColor: '#fff',
        color: '#3082c5',
    },
    image: {
        width: 20,
        height: 15,
    },
})
