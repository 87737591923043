import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_AMOUNT_WATER } from '../urls'
import Card from './common/Card'
import Loader from './common/Loader'
import useTrans from '../hooks/trans'

export default function StatisticsAmountWater({ startDate, endDate }) {
    const totalAmountWater = useLoad({ url: STATISTICS_AMOUNT_WATER, params: { startDate, endDate } })
    const amountWater = totalAmountWater.response || {}
    const t = useTrans()

    return (
        <div className="column is-3">
            <Card>
                <div className="title is-5">
                    {t('waterConsumptionDay')}
                </div>

                {!totalAmountWater.loading ? (
                    <span>
                        <div><b className="is-size-3">{amountWater.amountWater || 0}</b> {t('mln')}</div>
                    </span>
                ) : <Loader large center />}
            </Card>
        </div>
    )
}
