import React, { useState } from 'react'
import cn from 'classnames'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { StyleSheet, css } from 'aphrodite'

export default function TableInput({
    name,
    className,
    type,
    validate,
    isValid,
    help,
    component = 'input',
    loading,
    large = false,
    ...attributes
}) {
    const { errors, touched } = useFormikContext()
    const [error, setError] = useState('')

    return (
        <div className="field">
            <div className={cn('control', { 'is-loading': loading, 'is-large': large })}>
                <Field
                    className={cn(
                        component,
                        className,
                        { [css(styles.isValid)]: error && isValid },
                    )}
                    type={type || 'text'}
                    name={name}
                    validate={validate}
                    id={name}
                    component={component}
                    {...attributes} />

                <ErrorMessage
                    name={name}
                    {...attributes}
                    render={(msg) => {
                        setError(msg)
                        return <div />
                    }} />
            </div>

            {help && (!errors[Number(name[0])] || !touched[Number(name[0])]) ? (
                <p className="form-hint">{help}</p>
            ) : null}
        </div>
    )
}

const styles = StyleSheet.create({
    isValid: {
        border: '1px solid red',
        borderRadius: 0,
    },
})
