import React, { Fragment } from 'react'
import { useModal } from '../hooks/modal'
import { useDeleteRequest } from '../hooks/request'
import { IRRIGATION_TYPE_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'
import IrrigationTypeUpdate from './IrrigationTypeUpdate'
import Button from './common/Button'
import IrrigationPeriod from './IrrigationPeroid'
import { getTypeSeason } from '../utils/position'

export default function IrrigationTypeItem({ item, onSuccess }) {
    const [user] = useUser()
    const territoryArchive = useDeleteRequest({ url: IRRIGATION_TYPE_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()
    const t = useTrans()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <IrrigationTypeUpdate
            values={item}
            onCancel={() => hideUpdateModal()}
            onSuccess={() => {
                hideUpdateModal()
                onSuccess()
            }} />,
    )

    const [showPeriodsModal, hidePeriodsModal] = useModal(
        <IrrigationPeriod
            type={item}
            onCancel={() => hidePeriodsModal()}
            onUpdate={() => hidePeriodsModal()} />,
    )

    async function remove() {
        if (!global.confirm(t('deleteConfirm'))) return

        const { error } = await territoryArchive.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onSuccess()
    }

    return (
        <tr>
            <td>{item.name}</td>
            <td>{t(getTypeSeason(item.type))}</td>

            <td className="has-text-right">
                {user.role === 'main' ? (
                    <Fragment>
                        <Button
                            onClick={showPeriodsModal}
                            text={t('periods')}
                            icon="ion-md-grid"
                            className="is-small is-link is-light" />&nbsp;

                        <Button onClick={showUpdateModal} icon="ion-md-create" className="is-small is-light" />&nbsp;
                        <Button onClick={remove} icon="ion-md-trash" className="is-small is-light" />&nbsp;
                    </Fragment>
                ) : null}
            </td>
        </tr>
    )
}
