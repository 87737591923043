import React from 'react'
import useTrans from '../hooks/trans'
import { multiReplace } from '../utils/string'

export default function AcceptedMessage({ item }) {
    const t = useTrans()
    const responsible = item.responsible ? `${item.responsible.firstName} ${item.responsible.lastName}` : ''
    const acceptedBy = item.acceptedBy ? `${item.acceptedBy.firstName} ${item.acceptedBy.lastName}` : ''

    return (
        <div className="container mb-5 notification is-success">
            {multiReplace(t('statusAccepted'), {
                '{acceptedBy}': acceptedBy,
                '{responsible}': responsible,
            })}
        </div>
    )
}
