import React, { Fragment, useState } from 'react'
import { isEmpty } from 'lodash'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Button from './common/Button'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import BranchReportCreate from './BranchReportCreate'
import BranchReportUpdate from './BranchReportUpdate'
import { useMessage } from '../hooks/message'
import { usePutRequest } from '../hooks/request'
import { BRANCH_REPORT_ITEM_DETAIL } from '../urls'
import { onAcceptedBranchReportItem } from './OnAccepBranchReportItem'
import BranchRejectModal from './BranchRejectModal'
import AcceptedMessage from './AcceptedMessage'
import RejectedMessage from './RejectedMessage'
import { useUser } from '../hooks/user'
import { roleMain } from '../utils/role'
import BranchReportWaitingMessage from './BranchReportWaitingMessage'
import BranchReportNotFilledMessage from './BranchReportNotFilledMessage'
import { STATUS, STATUS_CLASS } from '../utils/status'

function BranchReportItem({ item, index, branchReportItems, response }) {
    const branchReportItemsUpdate = usePutRequest({
        url: BRANCH_REPORT_ITEM_DETAIL.replace('{id}', item.reportItem ? item.reportItem.id : 0),
    })
    const [showMessage] = useMessage()
    const [showStatus, setShowStatus] = useState(false)
    const t = useTrans()
    const [user] = useUser()

    const [showCreateModal, hideCreateModal] = useModal(
        <BranchReportCreate
            branchReport={item}
            branchReportItems={branchReportItems}
            onSuccess={() => {
                hideCreateModal()
            }} onCancel={() => {
                hideCreateModal()
            }} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <BranchReportUpdate
            branchReportItemsUpdate={branchReportItemsUpdate}
            branchReport={item}
            branchReportItems={branchReportItems}
            onSuccess={() => {
                hideUpdateModal()
            }} onCancel={() => {
                hideUpdateModal()
            }} />,
    )

    const [showRejectModal, hideRejectModal] = useModal(
        <BranchRejectModal
            branchReport={item}
            branchReportItems={branchReportItems}
            branchReportItemsUpdate={branchReportItemsUpdate}
            onSuccess={() => {
                hideRejectModal()
            }}
            onCancel={() => {
                hideRejectModal()
            }} />,
    )

    return (
        <Fragment>
            {(index === 0) || (response.results[index - 1]
                && response.results[index - 1].district.id !== item.district.id) ? (
                    <tr className="has-text-weight-bold has-text-centered">
                        <td />
                        <td />
                        <td />
                        <td className="is-size-5">{item.district.name}</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                ) : null}

            {Math.ceil(response.results.length - (response.results.length * 0.1)) === index
                ? <tr ref={branchReportItems.ref} /> : null}

            <tr>
                <td>{item.branch.name}</td>

                {item.reportItem ? (
                    <>
                        <td className="has-text-centered">{item.reportItem.morningLevel || 0}</td>
                        <td className="has-text-centered">{item.reportItem.dinnerLevel || 0}</td>
                        <td className="has-text-centered">{item.reportItem.morning || 0}</td>
                        <td className="has-text-centered">{item.reportItem.dinner || 0}</td>
                        <td className="has-text-centered">
                            {(((item.reportItem.dinner || 0) + (item.reportItem.morning || 0)) / 2)
                                .toFixed(2)}
                        </td>
                    </>
                ) : [1, 2, 3, 4, 5].map((num) => (
                    <td className="has-text-centered" key={num}>0</td>
                ))}

                <td className="has-text-centered">{item.branch.correction || 0}</td>

                {item.reportItem && roleMain.includes(user.role)
                    ? (
                        <td
                            onMouseEnter={() => setShowStatus(item.reportItem.status)}
                            onMouseLeave={() => setShowStatus(false)}
                            className="is-relative">
                            {showStatus ? (
                                <div className={css(styles.message)}>
                                    {item.reportItem.status === 'accepted' ? (
                                        <AcceptedMessage item={item.reportItem} />
                                    ) : null}

                                    {item.reportItem.status === 'rejected' ? (
                                        <RejectedMessage item={item.reportItem} />
                                    ) : null}

                                    {item.reportItem.status === 'waiting' ? (
                                        <BranchReportWaitingMessage item={item.reportItem} />
                                    ) : null}
                                </div>
                            ) : null}
                            <Button
                                onClick={showRejectModal}
                                icon="ion-md-close-circle is-size-5"
                                className={cn(css(styles.statusBtn), 'is-danger',
                                    { 'is-light': item.reportItem.status !== 'rejected' })} />
                            <Button
                                icon="ion-md-time is-size-5"
                                className={cn(css(styles.statusBtn), 'is-warning mx-6 cursorDefault',
                                    { 'is-light': item.reportItem.status !== 'waiting' })} />
                            <Button
                                onClick={() => {
                                    if (item.reportItem.status === 'accepted') {
                                        showMessage(t('successSave'), 'is-success')
                                        return
                                    }
                                    onAcceptedBranchReportItem({
                                        report: item.reportItem,
                                        reports: branchReportItems,
                                        branchReportItemsUpdate,
                                        showMessage,
                                        t,
                                    })
                                }}
                                icon="ion-md-checkbox is-size-5"
                                className={cn(css(styles.statusBtn), 'is-success',
                                    { 'is-light': item.reportItem.status !== 'accepted' })} />
                        </td>
                    ) : null }

                {!item.reportItem
                    ? (
                        <td
                            onMouseEnter={() => setShowStatus('notFilled')}
                            onMouseLeave={() => setShowStatus(false)}
                            className={cn('is-relative',
                                { 'is-flex is-justify-content-center': roleMain.includes(user.role) })}>
                            {showStatus === 'notFilled' ? (
                                <div className={css(styles.message)}>
                                    <BranchReportNotFilledMessage />
                                </div>
                            ) : null}
                            <Button
                                disabled
                                icon="ion-md-time is-size-5"
                                className={cn(css(styles.statusBtn), 'is-info mx-6  cursorDefault is-light')} />
                        </td>
                    )
                    : null }

                {item.reportItem && !roleMain.includes(user.role) && item.reportItem.status ? (
                    <td
                        onMouseEnter={() => setShowStatus(item.reportItem.status)}
                        onMouseLeave={() => setShowStatus(false)}
                        className="is-relative">
                        {showStatus ? (
                            <div className={css(styles.message)}>
                                {item.reportItem.status === 'accepted' ? (
                                    <AcceptedMessage item={item.reportItem} />
                                ) : null}

                                {item.reportItem.status === 'rejected' ? (
                                    <RejectedMessage item={item.reportItem} />
                                ) : null}

                                {item.reportItem.status === 'waiting' ? (
                                    <BranchReportWaitingMessage item={item.reportItem} />
                                ) : null}
                            </div>
                        ) : null}

                        <Button
                            icon={`ion-md-${STATUS[item.reportItem.status]} is-size-5`}
                            className={cn(css(styles.statusBtn), `is-${STATUS_CLASS[item.reportItem.status]}`)} />
                    </td>
                ) : null}

                {item.reportItem ? (
                    <td>
                        <Button
                            onClick={showUpdateModal}
                            text={t('change')}
                            icon="ion-md-create"
                            className="is-warning is-light is-small" />
                    </td>
                ) : (
                    <td>
                        <Button
                            onClick={() => {
                                if (!isEmpty(item.branch.levels)) {
                                    showCreateModal()
                                    return
                                }
                                showMessage(t('FirstFillCoordinateTable'), 'is-warning')
                            }}
                            text={t('fill')}
                            icon="ion-md-document"
                            className="is-info is-light is-small" />
                    </td>
                ) }
            </tr>
        </Fragment>
    )
}

export default BranchReportItem

const styles = StyleSheet.create({
    label: {
        position: 'absolute',
        left: '-300px',
        zIndex: 2,
        paddingRight: '120px',
    },
    statusBtn: {
        zIndex: 3,
        height: '30px',
        padding: '13px',
        margin: '0 4px',
    },
    message: {
        position: 'absolute',
        left: '-600px',
        right: '180px',
        top: 0,
    },
})
