import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import { useGetRequest, useLoad } from '../hooks/request'
import { BRANCH_DETAIL, BRANCH_REPORT_DETAIL, DISTRICT_LIST } from '../urls'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'
import Table from '../components/common/Table'
import BranchArchiveDistrictItem from '../components/BranchArchiveDistrictItem'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import Loader from '../components/common/Loader'
import Button from '../components/common/Button'
import { useDownloadExcel } from '../hooks/downloadExcel'
import { roleBranch } from '../utils/role'
import DateChoose from '../components/common/DateChoose'
import useTrans from '../hooks/trans'

export default function BranchArchiveDistrict() {
    const [user] = useUser()
    const params = useQueryParams()
    const history = useHistory()
    const { branchId } = useParams()
    const [page, setPage] = useState(1)
    const districtList = useLoad({ url: DISTRICT_LIST, params: { branch: branchId, page } }, [page])
    const branch = useLoad({ url: BRANCH_DETAIL.replace('{id}', branchId), params: { branch: branchId } })
    const date = params.date || moment().format(DATE_FORMAT)
    const branchReportDetail = useGetRequest({
        url: BRANCH_REPORT_DETAIL.replace('{branchId}', branchId),
        params: { date },
    })
    // eslint-disable-next-line no-nested-ternary
    const branchName = user.branch ? user.branch.name : branch.response ? branch.response.name : ''
    const downloadExcel = useDownloadExcel(
        branchReportDetail.request,
        date,
        branchName,
    )
    const t = useTrans()

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    if (branch.loading || districtList.loading) {
        return <Layout><BranchTabs /><Loader large center /></Layout>
    }

    return (
        <Layout>
            <BranchTabs />

            <div className="columns my-5">
                <div className="column">
                    <h1 className="title is-3 has-text-weight-normal">
                        {t('listDistricts')} "{ branch.response ? branch.response.name : '' }"
                    </h1>
                </div>

                <div className="column is-narrow">
                    <DateChoose
                        date={date}
                        onDatesChange={(dates) => {
                            history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                        }} />
                </div>

                {districtList.response && districtList.response.count > 1 ? (
                    <div className="column is-narrow">
                        <Button
                            className="is-info"
                            icon="ion-md-download"
                            text={branchName}
                            loading={branchReportDetail.loading}
                            onClick={downloadExcel} />
                    </div>
                ) : null }
            </div>

            <Table
                loading={districtList.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={districtList.response ? districtList.response.count : 0}
                items={districtList.response ? districtList.response.results : []}
                columns={{ name: t('name'), action: '' }}
                renderItem={(items) => (<BranchArchiveDistrictItem key={items.id} item={items} />)} />
        </Layout>
    )
}
