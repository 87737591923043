import React, { useState } from 'react'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { TERRITORY_SECTOR_LIST } from '../urls'
import TerritorySectorItem from '../components/TerritorySectorItem'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import TerritorySectorCreate from '../components/TerritorySectorCreate'
import DistrictTabs from '../components/DistrictTabs'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import useTrans from '../hooks/trans'
import Search from '../components/common/Search'
import { useQueryParams } from '../hooks/queryString'
import { roleMain } from '../utils/role'

export default function TerritorySectors() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const params = useQueryParams()
    const territorySectors = useLoad({ url: TERRITORY_SECTOR_LIST, params: { ...params, page } }, [page, params])
    const t = useTrans()

    const [showCreateOutcomeModal, hideCreateOutcomeModal] = useModal(
        <TerritorySectorCreate
            onCancel={() => hideCreateOutcomeModal()}
            onSuccess={() => {
                hideCreateOutcomeModal()
                territorySectors.request()
            }}
        />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h1 className="title has-text-weight-normal"><span>{t('listSectorTerritories')}</span></h1>

                <Search className="is-justify-content-flex-end is-align-content-flex-end mr-6" />

                {user.role === 'main' ? (
                    <Button
                        className="is-link is-outlined"
                        text={t('add')}
                        icon="ion-md-add"
                        onClick={showCreateOutcomeModal}
                    />
                ) : null}
            </div>

            <Table
                loading={territorySectors.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={territorySectors.response ? territorySectors.response.count : 0}
                columns={user.role === 'main' ? {
                    name: t('name'),
                    district: t('region'),
                    actions: '',
                } : {
                    name: t('name'),
                    district: t('region'),
                }}
                items={territorySectors.response ? territorySectors.response.results : []}
                renderItem={(territorySectorItem) => (
                    <TerritorySectorItem
                        onSuccess={territorySectors.request}
                        territorySectorItem={territorySectorItem}
                        key={territorySectorItem.id} />
                )} />
        </Layout>
    )
}
