import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { TERRITORY_LIST } from '../urls'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import TerritoryCreate from '../components/TerritoryCreate'
import TerritoryItem from '../components/TerritoryItem'
import DistrictTabs from '../components/DistrictTabs'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleMain } from '../utils/role'
import useTrans from '../hooks/trans'

export default function Territories() {
    const [user] = useUser()
    const params = useParams()
    const [page, setPage] = useState(1)
    const territory = useLoad({ url: TERRITORY_LIST, params: { sector: params.id, page } }, [page])
    const t = useTrans()

    const [showCreateOutcomeModal, hideCreateOutcomeModal] = useModal(
        <TerritoryCreate
            params={params}
            onCancel={() => hideCreateOutcomeModal()}
            onSuccess={() => {
                hideCreateOutcomeModal()
                territory.request()
            }}
        />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h1 className="title has-text-weight-normal"><span>{t('listTerritories')}</span></h1>
                {user.role === 'main' ? (
                    <Button
                        className="is-link is-outlined"
                        text={t('add')}
                        icon="ion-md-add"
                        onClick={showCreateOutcomeModal}
                    />
                ) : null}
            </div>

            <Table
                loading={territory.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={territory.response ? territory.response.count : 0}
                columns={user.role === 'main' ? {
                    name: t('name'),
                    actions: '',
                } : {
                    name: t('name'),
                }}
                items={territory.response ? territory.response.results : []}
                renderItem={(item) => (!item.isArchive ? (
                    <TerritoryItem
                        params={params}
                        onSuccess={territory.request}
                        item={item}
                        key={item.id}
                    />
                ) : null)} />
        </Layout>
    )
}
