import React, { useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useGetRequest, useLoad } from '../hooks/request'
import { RESERVOIR_LIST, RESERVOIR_REPORT } from '../urls'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'
import ReservoirItem from '../components/ReservoirItem'
import DateChoose from '../components/common/DateChoose'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { TODAY } from '../utils/date'
import { useDownloadExcel } from '../hooks/downloadExcel'

export default function Reservoir() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const history = useHistory()
    const params = useQueryParams()
    const date = params.date || TODAY
    const reservoir = useLoad({ url: RESERVOIR_LIST,
        params: {
            page,
            date,
            reporter: user.role === 'main' ? null : user.reporter.id,
        } }, [page])
    const reservoirExcel = useGetRequest({ url: RESERVOIR_REPORT, params }, [params])
    const t = useTrans()
    const downloadExcel = useDownloadExcel(reservoirExcel.request, date, 'Водохранилище')

    if (user.role !== 'main' && !user.reporter.id) {
        return (
            <Layout>
                <div className="notification">
                    <h1 className="title is-3 has-text-centered">For you reservoirs not found</h1>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="is-flex is-justify-content-space-between mt-5">
                <h1 className="title is-3 has-text-weight-normal">{t('listReservoir')}</h1>

                <div className="columns">
                    <div className="column is-narrow">
                        <DateChoose
                            date={date}
                            onDatesChange={(dates) => {
                                history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                            }} />
                    </div>

                    {user.role === 'main' ? (
                        <div className="column is-narrow">
                            <Button
                                className="is-info"
                                icon="ion-md-download"
                                text={t('download')}
                                loading={reservoirExcel.loading}
                                onClick={downloadExcel}
                            />
                        </div>
                    ) : null }
                </div>
            </div>

            <Table
                loading={reservoir.loading}
                totalCount={reservoir.response ? reservoir.response.count : 0}
                items={reservoir.response ? reservoir.response.results : []}
                activePage={page}
                onPageChange={setPage}
                className="is-striped"
                columns={{
                    name: t('name'),
                    sub: '',
                    morning: t('forMorning'),
                    yesterday: t('yesterdayMorning'),
                    lastYear: t('lastYear'),
                    actions: '',
                }}
                renderItem={(item) => (
                    <ReservoirItem
                        key={item.id}
                        item={item}
                        onUpdate={reservoir.request} />
                )} />
        </Layout>
    )
}
