import React from 'react'
import RejectForm from './RejectForm'
import { useMessage } from '../hooks/message'
import useTrans from '../hooks/trans'
import { useUser } from '../hooks/user'

export default function BranchRejectModal({ branchReport, branchReportItems,
    branchReportItemsUpdate,
    onCancel,
    onSuccess }) {
    const t = useTrans()
    const [user] = useUser()
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { reportItem } = branchReport
        const { response, error } = await branchReportItemsUpdate.request({ data: {
            ...reportItem,
            ...data,
            status: 'rejected',
            acceptedBy: null,
            rejectedBy: user.id,
        } })

        if (error) {
            showMessage(error.data[Object.keys(error.data)[0]], 'is-danger')
            return
        }

        const newResults = branchReportItems.response.results.map((item) => {
            if (item.id === response.districtBranch) {
                // eslint-disable-next-line no-param-reassign
                item.reportItem = response
            }
            return item
        })
        branchReportItems.setResponse({ ...branchReportItems.response, results: newResults })

        showMessage(t('successSave'), 'is-success')
        onSuccess(null)
        onCancel()
    }

    return (
        <RejectForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            loading={branchReportItemsUpdate.loading}
            initialValues={{
                reason: branchReport.reportItem && branchReport.reportItem.reason ? branchReport.reportItem.reason : '',
            }} />
    )
}
