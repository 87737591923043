import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import PumpStationUpdate from './PumpStationUpdate'
import { useLoad } from '../hooks/request'
import { DISTRICT_LIST } from '../urls'

export default function PumpStationItem({ item, onUpdate }) {
    const t = useTrans()
    const districts = useLoad({ url: DISTRICT_LIST }, [])

    const district = districts.response && districts.response.results.find((dist) => dist.id === item.district)

    const [showUpdateModal, hideUpdateModal] = useModal(
        <PumpStationUpdate item={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <Fragment>
            <tr>
                <td
                    className={css(styles.branchReportItem)}>
                    {item.name}
                </td>

                <td>
                    {item.aggregates}
                </td>

                <td>
                    {`${item.energy} ${t('mgwt')}`}
                </td>

                <td>
                    {`${item.waterCapability} ${t('m3sek')}`}
                </td>

                <td>
                    {`${item.waterHeight} ${t('metr')}`}
                </td>

                <td>
                    {`${item.totalEnergy} ${t('mgwt')}`}
                </td>

                <td>
                    {`${item.totalWaterCapability} ${t('m3sek')}`}
                </td>

                <td>
                    {district && district.name}
                </td>

                {onUpdate ? (
                    <td>
                        <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                    </td>
                ) : null}

            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchReportItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
