import React from 'react'
import DistrictSectorForm from './DistrictSectorForm'
import { usePostRequest } from '../hooks/request'
import { DISTRICT_SECTOR_LIST } from '../urls'
import useTrans from '../hooks/trans'

export default function DistrictSectorCreate({ hideModal, onSuccess }) {
    const districtSectorCreate = usePostRequest({ url: DISTRICT_SECTOR_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await districtSectorCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
            hideModal()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('addSector')}</h3>

            <DistrictSectorForm
                onCancel={hideModal}
                loading={districtSectorCreate.loading}
                onSubmit={onSubmit} />
        </div>
    )
}
