import React from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import Select from './common/Select'
import useTrans from '../hooks/trans'
import { useLoad } from '../hooks/request'
import { HYDROPOST_LIST } from '../urls'

export default function HydropostForm({
    onCancel,
    onSubmit,
    loading,
    initialValues = {},
}) {
    const hydroposts = useLoad({ url: HYDROPOST_LIST, params: { size: 300, isToplevel: true } }, [])
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', parent: '', ...initialValues }}>
            <Form>
                <Input
                    label={t('name')}
                    name="name"
                    validate={required} />

                <Select
                    empty
                    name="parent"
                    label={t('parent')}
                    loading={hydroposts.loading}
                    options={hydroposts.response ? hydroposts.response.results : []} />

                <div className="is-pulled-right">
                    <Button
                        text={t('back')}
                        className="is-danger ml-2"
                        onClick={onCancel} />&nbsp; &nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        className="is-success"
                        type="submit" />
                </div>
            </Form>
        </Formik>
    )
}
