import React from 'react'
import { usePutRequest } from '../hooks/request'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import DocsTopicForm from './DocsTopicForm'
import { TOPIC_DETAIL } from '../urls'

export default function DocsTopicUpdate({ onCancel, onUpdate, item }) {
    const url = TOPIC_DETAIL.replace('{slug}', item.slug)
    const categoryUpdate = usePutRequest({ url })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await categoryUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>{t('editing')}</b><br /><br />
            <ServerError error={categoryUpdate.error} />
            <DocsTopicForm
                initialValues={{ ...item, category: item.category ? item.category.id : '' }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={categoryUpdate.loading} />
        </div>
    )
}
