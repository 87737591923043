import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import useTrans from '../hooks/trans'
import Select from './common/Select'
import { types } from '../utils/position'

export default function IrrigationTypeForm({ onSubmit, onCancel, loading, initialValues = {} }) {
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '', type: '', ...initialValues }}>
            <Form>
                <Input name="name" label={t('name')} validate={required} />

                <Select
                    empty
                    label={t('type')}
                    name="type"
                    validate={required}
                    optionValue="value"
                    optionLabel="title"
                    options={types.map(({ value, title }) => ({ value, title: t(title) }))} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        disabled={loading}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        disabled={loading}
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
