import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useModal } from '../hooks/modal'
import BranchUpdate from './BranchUpdate'
import useTrans from '../hooks/trans'
import BranchLevels from './BranchLevels'
import Button from './common/Button'
import BranchInfo from './BranchInfo'

export default function BranchItem({ item, onUpdate }) {
    const t = useTrans()
    const types = {
        knot: { icon: 'ion-md-git-commit', suffix: 'gidrouzeli' },
        channel: { icon: 'ion-md-water', suffix: '' },
        pumpstation: { icon: 'ion-md-cog', suffix: 'n/st' },
        reservoir: { icon: 'ion-md-beaker', suffix: 'suv ombori' },
    }

    const [showInfoModal, hideInfoModal] = useModal(
        <BranchInfo item={item} onCancel={() => hideInfoModal()} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <BranchUpdate item={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showLevelsModal, hideLevelsModal] = useModal(
        <BranchLevels branch={item} onUpdate={() => {
            onUpdate()
            hideLevelsModal()
        }} onCancel={() => {
            hideLevelsModal()
        }} />,
    )

    return (
        <Fragment>
            <tr className={css(styles.branchItem)}>
                <td className="is-narrow">
                    <button className="button is-small is-info is-light is-rounded" title={t('knot')}>
                        <i className={cn('icon', types[item.type].icon)} />
                    </button>
                </td>

                <td onClick={showInfoModal}>{ item.name } {types[item.type].suffix} </td>

                <td className="has-text-grey is-text-overflow">
                    {item.sources.map((s) => s.name).join(', ')}
                </td>

                <td className="has-text-right">
                    { item.type === 'channel' && (
                        <Button
                            onClick={showLevelsModal}
                            text={t('branchLevel')}
                            icon="ion-md-grid"
                            className="is-small is-link is-light" />
                    ) }
                    &nbsp;<Button onClick={showUpdateModal} icon="ion-md-create" className="is-small is-light" />&nbsp;
                </td>
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
