import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import useTrans from '../hooks/trans'
import { reporters } from '../utils/role'
import Select from './common/Select'

export default function ReportersForm({ onSubmit, onCancel, loading, initialValues = {} }) {
    const t = useTrans()
    const options = Object.entries(reporters).map(([value]) => ({ value, label: t(value) }))

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            title: '',
            type: '',
            ...initialValues,
        }}>
            <Form>
                <Input name="title" label={t('reportersTitle')} validate={required} />

                <Select
                    name="type"
                    label={t('reportersType')}
                    optionLabel="label"
                    optionValue="value"
                    disabled={loading}
                    validate={required}
                    options={options} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        disabled={loading}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        disabled={loading}
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
