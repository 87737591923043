import React from 'react'
import moment from 'moment'
import { css, StyleSheet } from 'aphrodite'
import { NavLink } from 'react-router-dom'
import Button from './common/Button'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import { useGetRequest } from '../hooks/request'
import { DISTRICT_REPORT_TERRITORY } from '../urls'
import { useDownloadExcel } from '../hooks/downloadExcel'
import useTrans from '../hooks/trans'
import { multiReplace } from '../utils/string'

export default function DistrictArchiveItem({ item }) {
    const params = useQueryParams()
    const date = params.date || moment().format(DATE_FORMAT)
    const districtDetail = useGetRequest(
        { url: DISTRICT_REPORT_TERRITORY.replace('{districtId}', item.id), params: { date } },
    )
    const t = useTrans()
    const downloadExcel = useDownloadExcel(districtDetail.request, date, item.name)

    return (
        <tr>
            <td>
                <NavLink className={css(styles.itemLink)} to={`/district/${item.id}/archives`}>
                    <b>
                        {item.name}
                    </b>
                    <p className="is-size-7">{item.sector.name}</p>
                </NavLink>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-link is-reversed is-light is-rounded">
                            <i className="icon ion-ios-speedometer" />
                        </button>
                    </div>
                    <div className="column">
                        { item.limitWater ? item.limitWater : 0 } {t('m3sek')}
                    </div>
                </div>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-info is-light is-rounded">
                            <i className="icon ion-ios-water" />
                        </button>
                    </div>
                    <div className="column">
                        { item.amountWater ? item.amountWater : 0 } {t('m3sek')}
                    </div>
                </div>
            </td>

            <td className="has-text-right">
                <Button
                    className="is-info"
                    icon="ion-md-download"
                    text={t('download')}
                    loading={districtDetail.loading}
                    onClick={() => downloadExcel(multiReplace(t('noReport'),
                        { '{date}': date, '{name}': item.name }))} />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    itemLink: {
        color: '#000000',
        ':hover': {
            color: '#0000FF',
        },
    },
})
