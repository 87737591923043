import React from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import useTrans from '../hooks/trans'
import { useLoad } from '../hooks/request'
import { USER_LIST } from '../urls'
import Select from './common/Select'

export default function MailForm({ onCancel, onSubmit, loading, initialValues = {} }) {
    const t = useTrans()
    const userList = useLoad({ url: USER_LIST }, [])
    const users = userList.response ? (
        userList.response.results.map((item) => ({ id: item.id, name: `${item.firstName} ${item.lastName}` }))
    ) : []

    return (
        <Formik onSubmit={onSubmit} initialValues={{ title: '', body: '', to: '', isDone: false, ...initialValues }}>
            <Form>
                <Input
                    label={t('title')}
                    name="title"
                    validate={required} />

                <Input
                    label={t('body')}
                    name="body"
                    component="textarea"
                    validate={required} />

                <Select
                    label={t('user')}
                    name="to"
                    options={users}
                    validate={required} />

                <div className="is-pulled-right">
                    <Button
                        text={t('back')}
                        className="is-danger ml-2"
                        onClick={onCancel} />&nbsp; &nbsp;

                    <Button
                        loading={loading}
                        text={t('send')}
                        className="is-success"
                        type="submit" />
                </div>
            </Form>
        </Formik>
    )
}
