import React from 'react'
import { BRANCH_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import BranchForm from './BranchForm'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'

export default function BranchUpdate({ onCancel, onUpdate, item, responsibles }) {
    const url = BRANCH_DETAIL.replace('{id}', item.id)
    const branchUpdate = usePutRequest({ url })
    const t = useTrans()
    const sources = item.sources.map((s) => ({ value: s.id, label: s.name }))
    const districts = item.districts.map((d) => ({ value: d.id, label: d.name }))

    async function onSubmit(data, actions) {
        const { success } = await branchUpdate.request({
            data: {
                ...data,
                sources: data.sources.map((s) => s.value),
                districts: data.districts.map((d) => d.value),
            },
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>{t('editing')}</b><br /><br />
            <ServerError error={branchUpdate.error} />
            <BranchForm
                responsibles={responsibles}
                initialValues={{ ...item, sources, districts }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={branchUpdate.loading} />
        </div>
    )
}
