import React from 'react'
import { usePutRequest } from '../hooks/request'
import { REPORTER_DETAIL } from '../urls'
import ReportersForm from './ReportersForm'

export default function ReportersUpdate({ reporter, onCancel, onSuccess }) {
    const reporterUpdate = usePutRequest({ url: REPORTER_DETAIL.replace('{id}', reporter.id) })

    async function onSubmit(data) {
        await reporterUpdate.request({ data: {
            title: data.title,
            type: data.type,
        } })
        onSuccess()
    }

    return (
        <div>
            <ReportersForm
                initialValues={reporter}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={reporterUpdate.loading} />
        </div>
    )
}
