import React from 'react'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { CATEGORY_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import { useUser } from '../hooks/user'
import Table from '../components/common/Table'
import { PermissionDenied } from '../components/PermissionDenied'
import useTrans from '../hooks/trans'
import DocsTabs from '../components/DocsTabs'
import DocsCategoryCreate from '../components/DocsCategoryCreate'
import { useModal } from '../hooks/modal'
import DocsCategoryItem from '../components/DocsCategoryItem'

export default function DocsCategory() {
    const [user] = useUser()
    const category = useLoad({ url: CATEGORY_LIST }, [])
    const t = useTrans()

    const [showCreateModal, hideCreateModal] = useModal(
        <DocsCategoryCreate
            onSuccess={() => {
                category.request()
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    if (user.role !== 'main') {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DocsTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h3 className="title is-3 has-text-weight-normal">{t('categoriesList')}</h3>
                <Button
                    text={t('add')}
                    icon="ion-md-add"
                    className="is-link is-outlined"
                    onClick={showCreateModal}
                />
            </div>

            <Table
                loading={category.loading}
                items={category.response ? category.response : []}
                columns={{
                    name: t('name'),
                    actions: '',
                }}
                renderItem={(item) => (
                    <DocsCategoryItem key={item.id} item={item} onUpdate={category.request} />
                )} />
        </Layout>
    )
}
