import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import BranchItem from '../components/BranchItem'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { BRANCH_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import BranchCreate from '../components/BranchCreate'
import BranchTabs from '../components/BranchTabs'
import Button from '../components/common/Button'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleMain } from '../utils/role'
import useTrans from '../hooks/trans'

export default function BranchSettings() {
    const [user] = useUser()
    const { location } = useHistory()
    const [page, setPage] = useState(1)
    const branch = useLoad(
        { url: BRANCH_LIST, params: { page, withSources: true, withDistricts: true, size: 100 } }, [page],
    )
    const t = useTrans()

    const [showCreateModal, hideCreateModal] = useModal(
        <BranchCreate
            instructions={branch.response || []}
            onSuccess={() => {
                hideCreateModal()
                branch.request()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <BranchTabs showCreateModal={showCreateModal} />

            <div className="is-flex is-justify-content-space-between mt-5">
                <h3 className="title is-3 has-text-weight-normal">{t('listBranch')}</h3>

                <div>
                    {location.pathname === '/branch/settings' ? (
                        <Button
                            onClick={showCreateModal}
                            text={t('add')}
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined mb-2" />
                    ) : null}
                </div>
            </div>

            <Table
                loading={branch.loading}
                totalCount={branch.response ? branch.response.count : 0}
                activePage={page}
                pageSize={100}
                onPageChange={setPage}
                items={branch.response ? branch.response.results : []}
                columns={{ type: t('type'), name: t('name'), sources: t('sources'), actions: '' }}
                renderItem={(item) => (
                    <BranchItem
                        key={item.id}
                        item={item}
                        onUpdate={branch.request} />
                )} />
        </Layout>
    )
}
