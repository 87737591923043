import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useRouteMatch } from 'react-router-dom'
import useTrans from '../hooks/trans'

export default function DocsTabs() {
    const { path } = useRouteMatch()
    const t = useTrans()

    return (
        <div className="tabs mb-2">
            <ul className={cn('is-flex is-justify-content-space-between', css(styles.tabs))}>
                <div className="is-flex">
                    <li className={cn({ 'is-active': path.endsWith('category') })}>
                        <NavLink className={css(styles.itemTabs)} to="/docs/edit/category">
                            {t('categories')}
                        </NavLink>
                    </li>

                    <li className={cn({ 'is-active': path.endsWith('topic') })}>
                        <NavLink className={css(styles.itemTabs)} to="/docs/edit/topic">
                            {t('topics')}
                        </NavLink>
                    </li>
                </div>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    itemTabs: {
        borderStyle: 'none',
    },
    tabs: {
        height: '50px',
    },
})
