import React from 'react'
import DistrictSectorForm from './DistrictSectorForm'
import { usePutRequest } from '../hooks/request'
import { DISTRICT_SECTOR_DETAIL } from '../urls'
import useTrans from '../hooks/trans'

export default function DistrictSectorUpdate({ hideModal, item, onSuccess }) {
    const districtSectorUpdate = usePutRequest({ url: DISTRICT_SECTOR_DETAIL.replace('{id}', item.id) })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await districtSectorUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
            hideModal()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('changeSector')}</h3>

            <DistrictSectorForm
                onCancel={hideModal}
                initialValues={item}
                loading={districtSectorUpdate.loading}
                onSubmit={onSubmit} />
        </div>
    )
}
