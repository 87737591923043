import React from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useLoad } from '../hooks/request'
import { EMPTY_DISTRICT_REPORT } from '../urls'
import Loader from './common/Loader'
import { DATE_FORMAT } from '../utils/date'
import useTrans from '../hooks/trans'

export default function StatisticsEmptyDistrict() {
    const emptyReportDistricts = useLoad({ url: EMPTY_DISTRICT_REPORT })
    const emptyDistricts = emptyReportDistricts.response || []
    const t = useTrans()

    if (emptyReportDistricts.loading && !emptyReportDistricts.response) {
        return (
            <div className="column is-6">
                <div className="notification is-warning">
                    <Loader large center />
                </div>
            </div>
        )
    }

    if (isEmpty(emptyDistricts)) {
        return (
            <div className="column is-6">
                <div className="notification is-info">
                    <div className="columns is-variable is-1">
                        <div className="column is-narrow">
                            <i className="icon is-size-3 ion-ios-alert" />
                        </div>
                        <div className="column">
                            {t('allData')}&nbsp;
                            <b>{moment().subtract(1, 'days').format(DATE_FORMAT)}</b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="column is-6">
            <div className="notification is-warning">
                <div className="columns is-variable is-1">
                    <div className="column is-narrow">
                        <i className="icon is-size-3 ion-ios-alert" />
                    </div>
                    <div className="column">
                        {t('region')}
                        <b> {emptyDistricts.map((item) => `${item.name}, `)}</b>
                        {t('fillIn')}
                    </div>
                </div>
            </div>

            {/* <div className="notification is-info"> */}
            {/*    <div className="columns is-variable is-1"> */}
            {/*        <div className="column is-narrow"> */}
            {/*            <i className="icon is-size-3 ion-ios-checkmark-circle" /> */}
            {/*        </div> */}
            {/*        <div className="column"> */}
            {/*            Разрабатывается <b>новая версия</b> платформы */}
            {/*        </div> */}
            {/*    </div> */}
            {/* </div> */}
        </div>
    )
}
