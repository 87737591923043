import React from 'react'
import { Form, Formik } from 'formik'
import useTrans from '../hooks/trans'
import { useLoad, usePostRequest } from '../hooks/request'
import { ACTIVE_PERIOD_LIST } from '../urls'
import Loader from './common/Loader'
import Button from './common/Button'
import { useMessage } from '../hooks/message'
import Checkbox from './common/Checkbox'
import Table from './common/Table'

export default function ActivePeriod({ activeType, onCancel, onUpdate }) {
    const url = ACTIVE_PERIOD_LIST
    const t = useTrans()
    const [showMessage] = useMessage()
    const savePeriods = usePostRequest({ url })
    const activePeriods = useLoad({ url, params: { activeType: activeType.id } })

    if (activePeriods.loading) {
        return <Loader large center padded />
    }

    async function onSubmit(data) {
        const { error } = await savePeriods.request({ data: {
            ...data, periods: data.periods.map((i) => ({ ...i, period: i.period ? i.period.id : null })),
        } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        onUpdate()
    }

    return (
        <div>
            <b>{t('activePeriods')}</b><br /><br />

            <Formik
                onSubmit={onSubmit}
                initialValues={{ periods: activePeriods.response ? activePeriods.response.results : [] }}
                enableReinitialize>
                {({ values }) => (
                    <Form>
                        <Table
                            className="is-striped"
                            items={values.periods ? values.periods : []}
                            columns={{ name: t('name'), actions: t('status') }}
                            renderItem={(item, index) => (
                                <tr key={item.id}>
                                    <td>
                                        {item.period.name}
                                    </td>

                                    <td>
                                        <Checkbox name={`periods.${index}.isActive`} />
                                    </td>
                                </tr>
                            )} />

                        <div className="mt-3">
                            <Button
                                text={t('back')}
                                className="is-danger"
                                onClick={onCancel} />&nbsp; &nbsp;

                            <Button
                                loading={savePeriods.loading}
                                text={t('save')}
                                className="is-success"
                                type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
