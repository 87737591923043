import React from 'react'
import useTrans from '../hooks/trans'

export default function BranchReportWaitingMessage({ item }) {
    const t = useTrans()

    return (
        <div className="container mb-5 notification is-warning">
            {t('statusWaiting').replace('{responsible}', item.responsible.firstName)}
        </div>
    )
}
