import React from 'react'
import { Form, Formik } from 'formik'
import map from 'lodash/map'
import fromPairs from 'lodash/fromPairs'
import max from 'lodash/max'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import useTrans from '../hooks/trans'
import { useLoad, usePostRequest } from '../hooks/request'
import { BRANCH_LEVEL } from '../urls'
import Loader from './common/Loader'
import Input from './common/Input'
import Button from './common/Button'
import { useMessage } from '../hooks/message'
import BranchLevelsCorrection from './BranchLevelsCorrection'

export default function BranchLevels({ branch, onCancel, onUpdate }) {
    const t = useTrans()
    const [showMessage] = useMessage()
    const saveLevels = usePostRequest({ url: BRANCH_LEVEL.replace('{id}', branch.id) })
    const branchLevels = useLoad({ url: BRANCH_LEVEL.replace('{id}', branch.id) })

    if (branchLevels.loading) {
        return <Loader large center padded />
    }

    const initValues = isEmpty(branchLevels.response)
        ? { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 }
        : fromPairs(map(branchLevels.response, (x) => [x.level, x.value]))

    async function onSubmit(data) {
        const { error } = await saveLevels.request({ data: { values: data.levels } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }
        onUpdate()
    }

    function addRow(values, setValues) {
        const levelKeys = keys(values.levels).map(Number)
        const maxLevel = max(levelKeys) || 0
        const maxValue = values.levels[maxLevel] || 0
        const levels = { ...values.levels, [maxLevel + 1]: maxValue }
        setValues({ ...values, levels })
    }

    function removeRow(values, setValues) {
        const levelKeys = keys(values.levels).map(Number)
        if (levelKeys.length === 1) return
        const maxLevel = max(levelKeys)
        const levels = { ...values.levels }
        delete levels[maxLevel]
        setValues({ ...values, levels })
    }

    return (
        <div>
            <BranchLevelsCorrection branch={branch} />
            <b>{t('branchLevel')}</b><br /><br />

            <Formik onSubmit={onSubmit} initialValues={{ levels: initValues }}>
                {({ values, setValues }) => (
                    <Form>
                        {keys(values.levels).map((level, i, { length }) => (
                            <div className="columns is-variable is-1 mb-0" key={level}>
                                <div className="column is-3">
                                    <input value={level} className="input is-narrow is-small" disabled />
                                </div>

                                <div className="column">
                                    <Input
                                        name={`levels.${level}`}
                                        className="is-small"
                                        type="number"
                                        step="0.1" />
                                </div>

                                {i + 1 === length ? (
                                    <div className="column is-2">
                                        <Button
                                            onClick={() => removeRow(values, setValues)}
                                            icon="ion-md-trash"
                                            className="is-fullwidth is-light is-danger is-small" />
                                    </div>
                                ) : null}
                            </div>
                        ))}

                        <Button
                            onClick={() => addRow(values, setValues)}
                            icon="ion-md-add"
                            className="is-fullwidth is-success is-small is-light" />

                        <div className="mt-3">
                            <Button
                                text={t('back')}
                                className="is-danger"
                                onClick={onCancel} />&nbsp; &nbsp;

                            <Button
                                loading={saveLevels.loading}
                                text={t('save')}
                                className="is-success"
                                type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
