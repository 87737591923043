import React, { useState } from 'react'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { DISTRICT_SECTOR_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import DistrictSectorCreate from '../components/DistrictSectorCreate'
import Table from '../components/common/Table'
import DistrictSectorItem from '../components/DistrictSectorItem'
import DistrictTabs from '../components/DistrictTabs'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleMain } from '../utils/role'
import useTrans from '../hooks/trans'

export default function DistrictSectors() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const districtSectors = useLoad({ url: DISTRICT_SECTOR_LIST, params: { page } }, [page])
    const [showCreateModal, hideCreateModal] = useModal(
        <DistrictSectorCreate onSuccess={districtSectors.request} hideModal={() => hideCreateModal()} />,
    )
    const t = useTrans()

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h3 className="title is-3 has-text-weight-normal">{t('listSectorDistricts')}</h3>

                {user.role === 'main' ? (
                    <Button
                        text={t('add')}
                        icon="ion-md-add"
                        className="is-link is-outlined"
                        onClick={showCreateModal} />
                ) : null }
            </div>

            <Table
                loading={districtSectors.loading}
                items={districtSectors.response ? districtSectors.response.results : []}
                columns={user.role === 'main' ? { name: t('name'), actions: '' } : { name: t('name') }}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item) => (!item.isArchive ? (
                    <DistrictSectorItem
                        key={item.id}
                        item={item}
                        onSuccess={districtSectors.request} />
                ) : null)} />
        </Layout>
    )
}
