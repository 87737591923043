import React from 'react'
import { useDeleteRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import { useModal } from '../hooks/modal'
import { MAIL_DETAIL } from '../urls'
import useTrans from '../hooks/trans'
import MailUpdate from './MailUpdate'
import { useUser } from '../hooks/user'
import MailItemModal from './MailItemModal'

export default function MailItem({ item, onDelete, onSuccess }) {
    const [user] = useUser()
    const mailDelete = useDeleteRequest({ url: MAIL_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()
    const t = useTrans()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <MailUpdate
            item={item}
            hideModal={() => hideUpdateModal()}
            onSuccess={onSuccess} />,
    )

    const [showItemModal, hideItemModal] = useModal(
        <MailItemModal
            loading={false}
            item={item}
            onCancel={() => hideItemModal()}
            onDone={() => {
                onSuccess()
                hideItemModal()
            }} />,
    )

    async function mailRemove() {
        if (!global.confirm(t('mailDelete'))) return

        const { error } = await mailDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (
        <tr onClick={showItemModal}>
            <td>{item.title}</td>
            <td>{item.body}</td>
            <td>{item.to ? `${item.to.firstName} ${item.to.lastName}` : t('all')}</td>
            <td>{item.isDone ? t('isDone') : t('isNotDone')}</td>

            {user.role === 'main' ? (
                <td className="has-text-right">
                    <i className="icon pointer ion-md-create" title={t('change')} onClick={showUpdateModal} />

                    {!mailDelete.loading ? (
                        <i className="icon ion-md-trash pointer" title={t('archived')} onClick={mailRemove} />
                    ) : <Loader className="icon" />}
                </td>
            ) : null}
        </tr>
    )
}
