import { isEmpty } from 'lodash'
import { useMessage } from './message'
import { domain } from '../utils/request'
import useTrans from './trans'
import { multiReplace } from '../utils/string'

export function useDownloadExcel(request, date = new Date(), name) {
    const [showMessage] = useMessage()
    const t = useTrans()

    async function download(message) {
        const { response } = await request()

        if (!response || isEmpty(response) || !response.xlsx) {
            showMessage(
                typeof message === 'string' ? message : multiReplace(t('noReport'), { '{date}': date, '{name}': name }),
                'is-danger',
            )
            return
        }

        const a = document.createElement('a')

        a.href = [`${domain}/${response.xlsx}`]
        a.setAttribute('download', 'response')
        a.click()
    }

    return download
}
