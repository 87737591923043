import { Form, Formik } from 'formik'
import React from 'react'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import useTrans from '../hooks/trans'

export default function RejectForm({ onSubmit, onCancel, loading, initialValues }) {
    const t = useTrans()

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}>
            <Form>
                <Input
                    name="reason"
                    label={t('reason')}
                    disabled={loading}
                    component="textarea"
                    validate={required}
                    placeholder={t('enterReason')} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
