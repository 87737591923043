import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { PUMP_STATION_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import PumpsTabs from '../components/PumpsTabs'
import Button from '../components/common/Button'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleMain } from '../utils/role'
import useTrans from '../hooks/trans'
import PumpStationItem from '../components/PumpStationItem'
import PumpStationCreate from '../components/PumpStationCreate'

export default function PumpStationSettings() {
    const [user] = useUser()
    const { location } = useHistory()
    const [page, setPage] = useState(1)
    const pumpStation = useLoad({ url: PUMP_STATION_LIST, params: { page } }, [page])
    const t = useTrans()

    const [showCreateModal, hideCreateModal] = useModal(
        <PumpStationCreate
            instructions={pumpStation.response || []}
            onSuccess={() => {
                hideCreateModal()
                pumpStation.request()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <PumpsTabs showCreateModal={showCreateModal} />

            <div className="is-flex is-justify-content-space-between mt-5">
                <h1 className="title is-3 has-text-weight-normal">{t('listPumpStations')}</h1>

                <div>
                    {location.pathname === '/pump-stations/settings' ? (
                        <Button
                            onClick={showCreateModal}
                            text={t('add')}
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined mb-2" />
                    ) : null}
                </div>
            </div>

            <Table
                loading={pumpStation.loading}
                totalCount={pumpStation.response ? pumpStation.response.count : 0}
                items={pumpStation.response ? pumpStation.response.results : []}
                activePage={page}
                onPageChange={setPage}
                columns={{ name: t('name'),
                    aggregates: t('aggregates'),
                    energy: t('energy'),
                    waterCapability: t('waterCapability'),
                    waterHeight: t('waterHeight'),
                    totalEnergy: t('totalEnergy'),
                    totalWaterCapability: t('totalWaterCapability'),
                    district: t('district'),
                    actions: '' }}
                renderItem={(item) => (
                    <PumpStationItem key={item.id} item={item} onUpdate={pumpStation.request} />
                )} />
        </Layout>
    )
}
