import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { HYDROPOST_LIST, HYDROPOST_REPORT } from '../urls'
import { useGetRequest, useLoad } from '../hooks/request'
import { useUser } from '../hooks/user'
import HydropostItem from '../components/HydropostItem'
import Table from '../components/common/Table'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import HydropostCreate from '../components/HydropostCreate'
import DateChoose from '../components/common/DateChoose'
import { useDownloadExcel } from '../hooks/downloadExcel'
import { TODAY } from '../utils/date'
import { useQueryParams } from '../hooks/queryString'

export default function Hydroposts() {
    const history = useHistory()
    const params = useQueryParams()
    const date = params.date || TODAY
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const hydropost = useLoad({ url: HYDROPOST_LIST, params: { date } }, [page, params])
    const hydropostExcel = useGetRequest({ url: HYDROPOST_REPORT, params }, [params])
    const downloadExcel = useDownloadExcel(hydropostExcel.request, date, 'Гидропост')
    const t = useTrans()

    const [showCreateModal, hideCreateModal] = useModal(
        <HydropostCreate
            onCancel={() => hideCreateModal()}
            onSuccess={() => {
                hydropost.request()
                hideCreateModal()
            }} />,
    )

    return (
        <Layout>
            <div className="is-flex is-justify-content-space-between my-5">
                <h3 className="title is-3 has-text-weight-normal">{t('listHydroposts')}</h3>
                <div className="columns">
                    <div className="column is-narrow">
                        <DateChoose
                            date={date}
                            onDatesChange={(dates) => {
                                history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                            }} />
                    </div>

                    {user.role === 'main' ? (
                        <div className="column is-narrow">
                            <Button
                                className="is-info mr-5"
                                icon="ion-md-download"
                                text={t('download')}
                                loading={hydropostExcel.loading}
                                onClick={downloadExcel} />

                            <Button
                                text={t('add')}
                                icon="ion-md-add"
                                className="is-link is-outlined"
                                onClick={showCreateModal} />
                        </div>
                    ) : null }
                </div>
            </div>

            <Table
                loading={hydropost.loading}
                activePage={page}
                onPageChange={setPage}
                items={hydropost.response ? hydropost.response.results : []}
                columns={{
                    name: t('name'),
                    morning: t('forMorning'),
                    yesterday: t('yesterdayMorning'),
                    lastYear: t('lastYear'),
                    actions: '',
                }}
                renderItem={(item) => (
                    <HydropostItem
                        key={item.id}
                        item={item}
                        onSuccess={hydropost.request}
                        onDelete={hydropost.request} />
                )} />
        </Layout>
    )
}
