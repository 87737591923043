import React, { useState } from 'react'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { MAIL_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import { useUser } from '../hooks/user'
import Table from '../components/common/Table'
import useTrans from '../hooks/trans'
import MailCreate from '../components/MailCreate'
import MailItem from '../components/MailItem'

export default function Mails() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const mails = useLoad({ url: MAIL_LIST, params: { to: user.role !== 'main' ? user.id : null } }, [page])
    const t = useTrans()

    const [showCreateModal, hideCreateModal] = useModal(
        <MailCreate
            onSuccess={mails.request}
            hideModal={() => hideCreateModal()} />,
    )

    return (
        <Layout>
            <div className="is-flex is-justify-content-space-between my-5">
                <h3 className="title is-3 has-text-weight-normal">{t('listMails')}</h3>
                {user.role === 'main' ? (
                    <Button
                        text={t('sendMail')}
                        icon="ion-md-add"
                        className="is-link is-outlined"
                        onClick={showCreateModal} />
                ) : null }
            </div>

            <Table
                loading={mails.loading}
                activePage={page}
                onPageChange={setPage}
                items={mails.response ? mails.response.results : []}
                columns={user.role === 'main' ? {
                    title: t('title'),
                    body: t('body'),
                    to: t('to'),
                    isDone: t('isDoneTitle'),
                    file: t('fileTitle'),
                    actions: '',
                } : {
                    title: t('title'),
                    body: t('body'),
                    to: t('to'),
                    isDone: t('isDoneTitle'),
                    file: t('fileTitle'),
                }}
                renderItem={(item) => (
                    <MailItem
                        key={item.id}
                        item={item}
                        onSuccess={mails.request}
                        onDelete={mails.request} />
                )} />
        </Layout>
    )
}
