import React from 'react'
import { usePutRequest } from '../hooks/request'
import { IRRIGATION_TYPE_DETAIL } from '../urls'
import useTrans from '../hooks/trans'
import IrrigationTypeForm from './IrrigationTypeForm'

export default function IrrigationTypeUpdate({ values, onCancel, onSuccess }) {
    const update = usePutRequest({ url: IRRIGATION_TYPE_DETAIL.replace('{id}', values.id) })
    const t = useTrans()

    async function onSubmit(data) {
        await update.request({ data })
        onSuccess()
    }

    return (
        <div>
            <b>{t('change')}</b><br /><br />

            <IrrigationTypeForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={update.loading}
                initialValues={values}
                onSuccess={onSuccess} />
        </div>
    )
}
