import React from 'react'
import { usePostRequest } from '../hooks/request'
import { REPORTER_LIST } from '../urls'
import ReportersForm from './ReportersForm'

export default function ReportersCreate({ onCancel, onSuccess }) {
    const reporterCreate = usePostRequest({ url: REPORTER_LIST })

    async function onSubmit(data) {
        await reporterCreate.request({ data })

        onSuccess()
    }

    return (
        <div>
            <ReportersForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={reporterCreate.loading} />
        </div>
    )
}
