import React from 'react'
import { usePutRequest } from '../hooks/request'
import { USERS_DETAIL } from '../urls'
import ResponsibleForm from './ResponsibleForm'

export default function ResponsibleUpdate({ user, onCancel, onSuccess }) {
    const userUpdate = usePutRequest({ url: USERS_DETAIL.replace('{id}', user.id) })

    async function onSubmit(data) {
        await userUpdate.request({ data: {
            ...data,
            username: data.email,
        } })

        onSuccess()
    }

    return (
        <div>
            <ResponsibleForm
                initialValues={{
                    ...user,
                    branch: user.branch ? user.branch.id : null,
                    irrigationManagement: user.irrigationManagement ? user.irrigationManagement.id : null,
                    districtSector: user.districtSector ? user.districtSector.id : null,
                    district: user.district ? user.district.id : null,
                    territorySector: user.territorySector ? user.territorySector.id : null,
                    territory: user.territory ? user.territory.id : null,
                }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={userUpdate.loading} />
        </div>
    )
}
