import React from 'react'
import { useHistory } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { useModal } from '../hooks/modal'
import TerritorySectorUpdate from './TerritorySectorUpdate'
import { useDeleteRequest } from '../hooks/request'
import { TERRITORY_SECTOR_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function TerritorySectorItem({ territorySectorItem, onSuccess }) {
    const [user] = useUser()
    const history = useHistory()
    const t = useTrans()

    const territorySectorArchive = useDeleteRequest(
        { url: TERRITORY_SECTOR_DETAIL.replace('{id}', territorySectorItem.id) },
    )
    const [showMessage] = useMessage()
    const [showUpdateModal, hideUpdateModal] = useModal(
        <TerritorySectorUpdate
            values={territorySectorItem}
            onCancel={() => hideUpdateModal()}
            onSuccess={() => {
                hideUpdateModal()
                onSuccess()
            }} />,
    )

    async function archiveTerritorySector() {
        if (!global.confirm(t('deleteSector'))) return

        const { error } = await territorySectorArchive.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
        }
        onSuccess()
    }

    return (
        <tr>
            <td
                className={css(styles.navLinkItem)}
                onClick={() => history.push(`/territory-sector/${territorySectorItem.id}/territories`)}>
                {territorySectorItem.name}
            </td>

            <td>
                <span>
                    {territorySectorItem.district.name}
                </span>
            </td>

            {user.role === 'main' ? (
                <td className="has-text-right">
                    <i onClick={() => showUpdateModal()} className="icon pointer ion-md-create" />
                    <i onClick={() => archiveTerritorySector()} className="icon pointer ion-md-archive" />
                </td>
            ) : null}
        </tr>
    )
}

const styles = StyleSheet.create({
    navLinkItem: {
        cursor: 'pointer',
        color: 'black',
        ':hover': {
            color: 'blue',
        },
    },
})
