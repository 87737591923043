import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_WATER_LIMIT } from '../urls'
import StatisticsWaterLimitChart from './StatisticsWaterLimitChart'
import Card from './common/Card'
import useTrans from '../hooks/trans'

export default function StatisticsWaterLimit({ startDate, endDate }) {
    const waterLimit = useLoad({
        url: STATISTICS_WATER_LIMIT,
        params: { startDate, endDate },
    })
    const t = useTrans()

    return (
        <div className="columns">
            <div className="column">
                <Card>
                    <div className="title is-5 has-text-centered">
                        {`${t('waterRunOffRegion')} (${t('mln')})`}
                    </div>

                    <StatisticsWaterLimitChart
                        data={waterLimit.response ? waterLimit.response : []}
                        loading={waterLimit.loading}
                        field="outcomeAmount" />
                </Card>
            </div>
        </div>
    )
}
