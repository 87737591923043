import React from 'react'
import { usePostRequest } from '../hooks/request'
import { HYDROPOST_REPORT } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import HydropostReportForm from './HydropostReportForm'

export default function HydropostReportUpdate({ onCancel, onSuccess, report, hydropost }) {
    const t = useTrans()
    const hydropostReportCreate = usePostRequest({ url: HYDROPOST_REPORT })

    async function onSubmit(data) {
        const { success } = await hydropostReportCreate.request({ data: { hydropost: hydropost.id, ...data } })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('addingHydropostReport')}:</b><br /><br />
            <ServerError error={hydropostReportCreate.error} />
            <HydropostReportForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={{ name: hydropost.name, ...report }}
                loading={hydropostReportCreate.loading} />
        </div>
    )
}
