import React from 'react'
import { PUMP_STATION_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import PumpStationForm from './PumpStationForm'

export default function PumpStationUpdate({ onCancel, onUpdate, item, responsibles }) {
    const url = PUMP_STATION_DETAIL.replace('{id}', item.id)
    const pumpStationUpdate = usePutRequest({ url })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await pumpStationUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>{t('editing')}</b><br /><br />
            <ServerError error={pumpStationUpdate.error} />
            <PumpStationForm
                responsibles={responsibles}
                initialValues={item}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={pumpStationUpdate.loading} />
        </div>
    )
}
