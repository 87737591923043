import React from 'react'
import { usePutRequest } from '../hooks/request'
import { PUMP_STATION_REPORT_DETAIL } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import PumpStationReportForm from './PumpStationReportForm'

export default function PumpStationReportUpdate({ onCancel, onSuccess, item, pumpStation }) {
    const url = PUMP_STATION_REPORT_DETAIL.replace('{id}', item.id)
    const pumpStationReportUpdate = usePutRequest({ url })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await pumpStationReportUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('addingPumpStationReport').replace('{pumpStation}', pumpStation.branch.name)}:</b><br /><br />
            <ServerError error={pumpStationReportUpdate.error} />
            <PumpStationReportForm
                onSubmit={onSubmit}
                initialValues={item}
                pumpStation={pumpStation}
                onCancel={onCancel}
                loading={pumpStationReportUpdate.loading} />
        </div>
    )
}
