import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_TOTAL_AREA } from '../urls'
import Card from './common/Card'
import Loader from './common/Loader'
import useTrans from '../hooks/trans'

export default function StatisticsTotalArea({ startDate, endDate }) {
    const totalArea = useLoad({ url: STATISTICS_TOTAL_AREA, params: { startDate, endDate } })
    const totalAreaDay = totalArea.response || {}
    const t = useTrans()

    return (
        <div className="column is-3">
            <Card>
                <div className="title is-5">
                    {t('irrigationDay')}
                </div>

                {!totalArea.loading ? (
                    <span>
                        <div><b className="is-size-3">{totalAreaDay.totalArea || 0}</b> {t('ga')}</div>
                    </span>
                ) : <Loader large center />}
            </Card>
        </div>
    )
}
