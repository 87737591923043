import React, { useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'

import { PUMP_STATION_LIST, PUMP_STATION_REPORT } from '../urls'
import { useGetRequest, useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import PumpStationReportItem from '../components/PumpStationReportItem'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleBranch } from '../utils/role'
import useTrans from '../hooks/trans'
import Button from '../components/common/Button'
import DateChoose from '../components/common/DateChoose'
import { useQueryParams } from '../hooks/queryString'
import { TODAY } from '../utils/date'
import { useDownloadExcel } from '../hooks/downloadExcel'

export default function PumpStationReport() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const params = useQueryParams()
    const date = params.date || TODAY
    const pumpStation = useLoad({
        url: PUMP_STATION_LIST,
        params: { page, date, reporter: user.role === 'main' ? null : user.reporter.id },
    }, [page, date])
    const pumpStationExcel = useGetRequest({ url: PUMP_STATION_REPORT, params }, [params])
    const history = useHistory()
    const downloadExcel = useDownloadExcel(
        pumpStationExcel.request,
        date,
        'Насосная станция',
    )

    const t = useTrans()

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <div className="columns mt-3">
                <div className="column">
                    <h1 className="title is-3 has-text-weight-normal">{t('listPumpStations')}</h1>
                </div>

                <div className="column is-narrow">
                    <DateChoose
                        date={date}
                        onDatesChange={(dates) => {
                            history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                        }} />
                </div>

                {user.role === 'main' ? (
                    <div className="column is-narrow">
                        <Button
                            className="is-info"
                            icon="ion-md-download"
                            text={t('download')}
                            loading={pumpStationExcel.loading}
                            onClick={downloadExcel}
                        />
                    </div>
                ) : null }
            </div>

            <Table
                loading={pumpStation.loading}
                totalCount={pumpStation.response ? pumpStation.response.count : 0}
                items={pumpStation.response ? pumpStation.response.results : []}
                activePage={page}
                onPageChange={setPage}
                columns={{ name: t('name'),
                    aggregates: t('aggregates'),
                    yesterdayMorning: t('yesterdayMorning'),
                    forMorning: t('forMorning'),
                    forEvening: t('forEvening'),
                    totalEnergy: t('totalEnergy'),
                    lastYear: t('lastYear'),
                    actions: '' }}
                renderItem={(item) => (
                    <PumpStationReportItem key={item.id} onUpdate={pumpStation.request} item={item} />
                )} />
        </Layout>
    )
}
