import React, { Fragment } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function DistrictTabs({ className }) {
    const [user] = useUser()
    const { path } = useRouteMatch()
    const t = useTrans()

    return (
        <div className={cn('tabs mb-2', className)}>
            <ul className="is-flex is-justify-content-space-between">
                <div className="is-flex">
                    {user.role !== 'districtSector' ? (
                        <li className={cn({ 'is-active': (path.endsWith('/reports')) })}>
                            <NavLink
                                className={css(styles.itemTabs)}
                                to={user.role === 'district' ? (
                                    `/district/${user.district.id}/reports`
                                ) : '/district-sector/reports'}>
                                {t('forToday')}
                            </NavLink>
                        </li>
                    ) : null }

                    <li className={cn({ 'is-active': path.endsWith('archives') })}>
                        <NavLink
                            className={css(styles.itemTabs)}
                            to={user.role === 'district' ? (
                                `/district/${user.district.id}/archives`
                            ) : '/district/archives'}>
                            {t('archive')}
                        </NavLink>
                    </li>

                    {user.role !== 'district' ? (
                        <li className={cn({ 'is-active': path.endsWith('/settings') })}>
                            <NavLink
                                to={user.role === 'district' ? (
                                    `/district-sector/${user.district.id}/settings`
                                ) : (
                                    '/district-sector/settings'
                                )}
                                className={css(styles.itemTabs)}>
                                {t('control')}
                            </NavLink>
                        </li>
                    ) : null }

                    {user.role === 'main' ? (
                        <Fragment>
                            <li className={cn({ 'is-active': (path.startsWith('/territory-sector')) })}>
                                <NavLink
                                    to="/territory-sectors"
                                    className={css(styles.itemTabs)}>
                                    {t('controlTerritory')}
                                </NavLink>
                            </li>

                            <li className={cn({ 'is-active': path.endsWith('plan') })}>
                                <NavLink
                                    to="/district-sector/plan"
                                    className={css(styles.itemTabs)}>
                                    {t('controlPlan')}
                                </NavLink>
                            </li>
                        </Fragment>
                    ) : null}
                </div>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    itemTabs: {
        borderStyle: 'none',
    },
})
