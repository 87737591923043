import React from 'react'
import first from 'lodash/first'
import last from 'lodash/last'
import BranchReportForm from './BranchReportForm'
import { usePostRequest } from '../hooks/request'
import { BRANCH_REPORT_ITEMS } from '../urls'
import { useMessage } from '../hooks/message'
import useTrans from '../hooks/trans'
import ServerError from './common/ServerError'

export default function BranchReportCreate({
    onSuccess,
    onCancel,
    branchReport,
    branchReportItems,
}) {
    const [showMessage] = useMessage()
    const t = useTrans()
    const branchReportItemsUpdate = usePostRequest({ url: BRANCH_REPORT_ITEMS })
    let levelMin = first(branchReport.branch.levels)
    let levelMax = last(branchReport.branch.levels)

    levelMin = levelMin ? levelMin.level : 0
    levelMin = levelMin < 0 ? 0 : levelMin
    levelMax = levelMax ? levelMax.level : 0
    const placeholder = `min: ${levelMin} - max: ${levelMax}`

    async function onSubmit(data) {
        const { response, error } = await branchReportItemsUpdate.request({ data: {
            ...data,
            morningLevel: +data.morningLevel || 0,
            dinnerLevel: +data.dinnerLevel || 0,
            correction: branchReport.branch.correction,
            districtBranch: branchReport.id,
        } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        const newResults = branchReportItems.response.results.map((item) => {
            if (item.id === response.districtBranch) {
                // eslint-disable-next-line no-param-reassign
                item.reportItem = response
            }
            return item
        })
        branchReportItems.setResponse({ ...branchReportItems.response, results: newResults })
        showMessage(t('successSave'), 'is-success')
        onSuccess()
    }

    return (
        <div>
            <b>{branchReport.branch.name}:</b><br /><br />

            <ServerError error={branchReportItemsUpdate.error} />

            <BranchReportForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                branchReportItems={branchReportItems}
                placeholder={placeholder}
                loading={branchReportItemsUpdate.loading}
                branchReport={branchReport}
                levelMin={levelMin}
                levelMax={levelMax} />
        </div>
    )
}
