import React from 'react'
import { useLoad, usePostRequest } from '../hooks/request'
import { REPORTER_LIST, USER_INVITATION } from '../urls'
import ResponsibleForm from './ResponsibleForm'

export default function ResponsibleCreate({ onCancel, onSuccess }) {
    const userCreate = usePostRequest({ url: USER_INVITATION })
    const reporters = useLoad({ url: REPORTER_LIST })

    async function onSubmit(data) {
        await userCreate.request({ data: {
            ...data,
            username: data.email,
        } })

        onSuccess()
    }

    return (
        <div>
            <ResponsibleForm
                reporters={reporters}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={userCreate.loading} />
        </div>
    )
}
