import React from 'react'
import { usePostRequest } from '../hooks/request'
import useTrans from '../hooks/trans'
import { MAIL_LIST } from '../urls'
import MailForm from './MailForm'

export default function MailCreate({ hideModal, onSuccess, branch }) {
    const mailCreate = usePostRequest({ url: MAIL_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await mailCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
            hideModal()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('sendMail')}</h3>

            <MailForm
                onCancel={hideModal}
                branch={branch}
                loading={mailCreate.loading}
                onSubmit={onSubmit} />
        </div>

    )
}
