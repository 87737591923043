import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { CATEGORY_LIST, TOPIC_DETAIL } from '../urls'
import { useLoad } from '../hooks/request'
import Button from '../components/common/Button'
import { useUser } from '../hooks/user'
import useTrans from '../hooks/trans'

export default function Docs() {
    const params = useParams()
    const categories = useLoad({ url: CATEGORY_LIST })
    const topic = useLoad({ url: TOPIC_DETAIL.replace('{slug}', params.topic) })
    const history = useHistory()
    const [user] = useUser()
    const t = useTrans()
    const categoriesList = categories.response || []

    return (
        <Layout>
            <div className="container">
                <div className="columns is-gapless">
                    <div className="column is-2-desktop is-3-tablet sidebar">
                        <a href="/">
                            {/* <img src={logo} width={64} height={64} className="logo" alt="logo" /> */}
                        </a>

                        <aside className="menu">
                            {categoriesList.map((item) => (
                                <div>
                                    <p className="menu-label">{item.name}</p>
                                    {item.topics.map((i) => (
                                        <ul className="menu-list">
                                            <a href={i.slug}>
                                                {i.shortTitle}
                                            </a>
                                        </ul>
                                    ))}
                                </div>
                            ))}
                        </aside>
                    </div>

                    <div className="column content-wrapper">
                        <div className="header">
                            <div className="columns is-mobile">
                                <div className="column is-narrow">
                                    {/* <img src="{% static 'docs/img/icons/menu.svg' %}" className="icon menu-icon" */}
                                    {/*     alt="menu icon"/> */}
                                </div>

                                <div className="column">
                                    <div className="columns is-mobile search-results">
                                        <div className="column is-narrow">
                                            {/* <img src="{% static 'docs/img/icons/search.svg' %}" */}
                                            {/*     className="icon search-icon" */}
                                            {/*     alt="search icon"/> */}
                                        </div>

                                        <div className="column">
                                            <input type="text" placeholder="Поиск по документации..."
                                                className="search" />
                                            <br />

                                            <ul className="search-results-list" />
                                        </div>
                                    </div>
                                </div>

                                <div className="column is-narrow">
                                    <Button
                                        text={t('print')}
                                        onClick={() => window.print()}
                                        className="button is-small is-info" />
                                </div>

                                <div className="column is-narrow">
                                    {user.role === 'main' ? (
                                        <Button
                                            text={t('changeDocs')}
                                            onClick={() => history.push('/docs/edit/category')}
                                            className="button is-small is-link" />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="body">
                                {topic.response ? (
                                    <div>
                                        <h1>{topic.response.title}</h1>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div dangerouslySetInnerHTML={{ __html: topic.response.body }} />
                                    </div>
                                ) : <h1>Ничего не найдено</h1>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sidebar-background" />
            <div className="search-background" />
        </Layout>
    )
}
