import React, { useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'
import Table from '../components/common/Table'
import { BRANCH_LIST, BRANCH_REPORT } from '../urls'
import { useGetRequest, useLoad } from '../hooks/request'
import BranchArchiveItem from '../components/BranchArchiveItem'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleBranch } from '../utils/role'
import DateChoose from '../components/common/DateChoose'
import useTrans from '../hooks/trans'
import Button from '../components/common/Button'
import { useDownloadExcel } from '../hooks/downloadExcel'

export default function BranchArchives() {
    const params = useQueryParams()
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const branch = useLoad({ url: BRANCH_LIST, params: { page } }, [page])
    const branchExcel = useGetRequest({ url: BRANCH_REPORT, params }, [params])
    const history = useHistory()
    const t = useTrans()
    const date = params.date || moment().format(DATE_FORMAT)
    const downloadExcel = useDownloadExcel(
        branchExcel.request,
        date,
        'ИТБ',
    )

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <BranchTabs />

            <div className="columns mt-3">
                <div className="column">
                    <h3 className="title is-3 has-text-weight-normal">{t('listBranch')}</h3>
                </div>

                <div className="column is-narrow">
                    <DateChoose
                        date={date}
                        onDatesChange={(dates) => {
                            history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                        }} />
                </div>

                {user.role === 'main' ? (
                    <div className="column is-narrow">
                        <Button
                            className="is-info"
                            icon="ion-md-download"
                            text={t('download')}
                            loading={branchExcel.loading}
                            onClick={downloadExcel}
                        />
                    </div>
                ) : null }

            </div>

            <Table
                loading={branch.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={branch.response ? branch.response.count : 0}
                items={branch.response ? branch.response.results : []}
                columns={{ name: t('name'), actions: '' }}
                renderItem={(item) => (<BranchArchiveItem key={item.id} item={item} />)} />
        </Layout>
    )
}
