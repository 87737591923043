import { Form, Formik } from 'formik'
import React, { Fragment } from 'react'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import useTrans from '../hooks/trans'
import { Radio, RadioGroup } from './common/Radio'
import MultiSelect from './common/MultiSelect'
import { useLoad } from '../hooks/request'
import { BRANCH_LIST, DISTRICT_LIST, REPORTER_LIST } from '../urls'
import Select from './common/Select'
import Checkbox from './common/Checkbox'

export default function BranchForm({ onSubmit, onCancel, loading, initialValues }) {
    const t = useTrans()
    const branches = useLoad({ url: BRANCH_LIST, params: { size: 1500 } }, [])
    const districts = useLoad({ url: DISTRICT_LIST, params: { size: 300 } }, [])
    const reporters = useLoad({ url: REPORTER_LIST, params: { size: 300 } }, [])

    const reporterList = reporters.response ? (
        reporters.response.results.map((item) => ({ value: item.id, label: item.title }))
    ) : []

    const options = branches.response ? (
        branches.response.results.map((item) => ({ value: item.id, label: item.name }))
    ) : []

    const districtList = districts.response ? (
        districts.response.results.map((item) => ({ value: item.id, label: item.name }))
    ) : []

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ name: '',
                type: 'channel',
                sources: [],
                districts: [],
                reporter: '',
                ...initialValues }}>
            {({ values }) => (
                <Form>
                    <Input
                        name="name"
                        label={t('name')}
                        disabled={loading}
                        validate={required}
                        placeholder={t('enterName')} />

                    <MultiSelect
                        empty
                        name="sources"
                        label={t('sources')}
                        options={options} />

                    { !initialValues && (
                        <RadioGroup name="type">
                            <Radio value="channel" label={t('channel')} />
                            <Radio value="knot" label={t('knot')} />
                            <Radio value="pumpstation" label={t('pumpStation')} />
                            <Radio value="reservoir" label={t('reservoir')} />
                        </RadioGroup>
                    ) }

                    <Fragment>
                        <Select
                            empty
                            name="reporter"
                            label={t('reporter')}
                            optionValue="value"
                            optionLabel="label"
                            options={reporterList} />

                        <Checkbox label={t('border')} name="isBorder" />
                    </Fragment>

                    {values.type === 'pumpstation' ? (
                        <Fragment>
                            <Input
                                name="pumpStation.aggregates"
                                label={t('aggregates')}
                                type="number"
                                disabled={loading}
                                validate={required}
                                placeholder={t('enterAggregates')} />

                            <Input
                                name="pumpStation.energy"
                                label={t('energy')}
                                type="number"
                                disabled={loading}
                                validate={required}
                                help={t('mgwt')}
                                placeholder={t('enterEnergy')} />

                            <Input
                                name="pumpStation.waterCapability"
                                label={t('waterCapability')}
                                type="number"
                                disabled={loading}
                                validate={required}
                                help={t('m3sek')}
                                placeholder={t('enterWaterCapability')} />

                            <Input
                                name="pumpStation.waterHeight"
                                label={t('waterHeight')}
                                type="number"
                                disabled={loading}
                                validate={required}
                                help={t('metr')}
                                placeholder={t('enterWaterHeight')} />
                        </Fragment>
                    ) : null}

                    <MultiSelect
                        empty
                        name="districts"
                        validate={required}
                        label={t('districts')}
                        options={districtList} />

                    <Checkbox label={t('showXlsx')} name="showXlsx" />

                    <div className="is-pulled-right">
                        <Button
                            onClick={onCancel}
                            icon="ion-md-close"
                            text={t('cancel')}
                            className="is-danger" />&nbsp;&nbsp;

                        <Button
                            loading={loading}
                            text={t('save')}
                            type="submit"
                            icon="ion-md-checkmark"
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
