import React from 'react'
import { usePutRequest } from '../hooks/request'
import useTrans from '../hooks/trans'
import { MAIL_DETAIL } from '../urls'
import MailForm from './MailForm'

export default function MailUpdate({ hideModal, item, onSuccess }) {
    const mailUpdate = usePutRequest({ url: MAIL_DETAIL.replace('{id}', item.id) })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await mailUpdate.request({ data: { ...data } })

        if (success) {
            actions.resetForm()
            onSuccess()
            hideModal()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('changeMail')}</h3>

            <MailForm
                onCancel={hideModal}
                initialValues={item}
                onSubmit={onSubmit} />
        </div>
    )
}
