import React from 'react'
import { usePostRequest } from '../hooks/request'
import { RESERVOIR_REPORT } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import ReservoirReportForm from './ReservoirReportForm'

export default function ReservoirReportUpdate({ onCancel, onSuccess, report, reservoir }) {
    const t = useTrans()
    const reservoirReportCreate = usePostRequest({ url: RESERVOIR_REPORT })

    async function onSubmit(data) {
        const { success } = await reservoirReportCreate.request({ data: { reservoir: reservoir.id, ...data } })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('addingReservoirReport')}:</b><br /><br />
            <ServerError error={reservoirReportCreate.error} />
            <ReservoirReportForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={report}
                loading={reservoirReportCreate.loading} />
        </div>
    )
}
