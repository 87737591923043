import React from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import useTrans from '../hooks/trans'

export default function DistrictSectorForm({ onCancel, onSubmit, loading, initialValues = {} }) {
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            ...initialValues,
        }}>
            <Form>
                <Input
                    label={t('name')}
                    name="name"
                    validate={required} />

                <div className="is-pulled-right">
                    <Button
                        text={t('cancel')}
                        className="is-danger ml-2"
                        onClick={onCancel} />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        className="is-success"
                        type="submit" />
                </div>
            </Form>
        </Formik>
    )
}
