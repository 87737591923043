import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import { useGetRequest } from '../hooks/request'
import { BRANCH_REPORT_DETAIL } from '../urls'
import Button from './common/Button'
import { useDownloadExcel } from '../hooks/downloadExcel'
import useTrans from '../hooks/trans'

export default function BranchArchiveItem({ item }) {
    const params = useQueryParams()
    const history = useHistory()
    const date = params.date || moment().format(DATE_FORMAT)
    const branchReportDetail = useGetRequest(
        { url: BRANCH_REPORT_DETAIL.replace('{branchId}', item.id), params: { date } },
    )
    const downloadExcel = useDownloadExcel(branchReportDetail.request, date, item.name)
    const t = useTrans()

    return (
        <Fragment>
            <tr className={css(styles.branchArchiveItem)}>
                <td onClick={() => history.push(`/branch/${item.id}/archives`)}>
                    <p className="mt-2">{item.name}</p>
                </td>

                <td>
                    <Button
                        className={cn('is-pulled-right is-info ')}
                        icon="ion-md-download"
                        text={t('download')}
                        loading={branchReportDetail.loading}
                        onClick={() => downloadExcel()} />
                </td>
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchArchiveItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },
})
