import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PUMP_STATION_REPORT } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import PumpStationReportForm from './PumpStationReportForm'

export default function PumpStationReportCreate({ onCancel, onSuccess, pumpStation }) {
    const pumpStationReportCreate = usePostRequest({ url: PUMP_STATION_REPORT })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await pumpStationReportCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('addingPumpStationReport').replace('{pumpStation}', pumpStation.branch.name)}:</b><br /><br />
            <ServerError error={pumpStationReportCreate.error} />
            <PumpStationReportForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                pumpStation={pumpStation}
                loading={pumpStationReportCreate.loading} />
        </div>
    )
}
