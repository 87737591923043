import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Input from './common/Input'
import Button from './common/Button'
import useTrans from '../hooks/trans'

export default function UserForm({ onSubmit, user, loading }) {
    const t = useTrans()

    return (
        <Formik initialValues={user} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="email"
                    label="Email"
                    placeholder=""
                    disabled
                    validate={required} />

                <Input
                    name="lastName"
                    label={t('surname')}
                    placeholder="Введите фамилию"
                    validate={required} />

                <Input
                    name="firstName"
                    label={t('fullName')}
                    placeholder="Введите имя"
                    disabled={loading}
                    validate={required} />

                <Button
                    loading={loading}
                    text={t('save')}
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
