import React from 'react'
import map from 'lodash/map'
import Chart from './common/Chart'
import useTrans from '../hooks/trans'

export default function StatisticsDayAmountWaterLine({ data, loading }) {
    const t = useTrans()

    const newData = data ? data.map((item, index, array) => {
        if (index !== 0) {
            return ({
                ...item,
                amountWater: array.slice(0, index + 1).reduce((i, n) => i + n.amountWater, 0),
                limitWater: array.slice(0, index + 1).reduce((i, n) => i + n.limitWater, 0),
            })
        }
        return item
    }) : []

    return (
        <div>
            <Chart
                loading={loading}
                type="line"
                height="25rem"
                data={{
                    labels: newData.map((item) => item.date),
                    datasets: [{
                        data: map(newData, 'amountWater'),
                        backgroundColor: '#48c774',
                        fill: false,
                        borderColor: '#48c774',
                        label: t('water'),
                    }, {
                        data: map(newData, 'limitWater'),
                        backgroundColor: '#f14668',
                        fill: false,
                        borderColor: '#f14668',
                        label: t('limit'),
                    },
                    ],
                }}
                options={{
                    legend: { position: 'bottom' },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                    plugins: {
                        datalabels: {
                            color: 'black',
                            font: { weight: 'bold' },
                        },
                    },
                }} />
        </div>
    )
}
