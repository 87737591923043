import React, { useState } from 'react'
import Layout from '../components/Layout'
import { REPORTER_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import useTrans from '../hooks/trans'
import { roleMain } from '../utils/role'
import UsersTabs from './UsersTabs'
import ReportersItem from '../components/ReportersItem'
import ReportersCreate from '../components/ReportersCreate'

export default function Reporters() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const t = useTrans()
    const reporters = useLoad({ url: REPORTER_LIST, params: { page } }, [page])
    const reportersResults = reporters.response ? reporters.response.results : []

    const [showCreateModal, hideCreateModal] = useModal(
        <ReportersCreate
            reporters={reporters.response ? reporters.response.results : []}
            onSuccess={() => {
                reporters.request()
                hideCreateModal()
            }} onCancel={() => {
                hideCreateModal()
            }} />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <UsersTabs />

            <div className="is-flex is-justify-content-space-between is-align-items-center mt-5 mb-0">
                <h1 className="title is-3 has-text-weight-normal">
                    <span>
                        {t('reporters')}
                    </span>
                </h1>

                <Button
                    onClick={showCreateModal}
                    text={t('add')}
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined mb-2" />
            </div>

            <Table
                loading={reporters.loading}
                activePage={page}
                pageSize={15}
                totalCount={reporters.response ? reporters.response.count : 0}
                onPageChange={setPage}
                items={reportersResults}
                columns={{
                    title: t('reportersTitle'),
                    type: t('reportersType'),
                    actions: '',
                }}
                renderItem={(item) => (
                    <ReportersItem
                        reporters={reporters.response ? reporters.response.results : []}
                        onSuccess={reporters.request}
                        key={item.id}
                        item={item} />
                )} />
        </Layout>
    )
}
