import React, { Fragment } from 'react'
import { useModal } from '../hooks/modal'
import DocsCategoryUpdate from './DocsCategoryUpdate'

export default function DocsCategoryItem({ item, onUpdate }) {
    const [showUpdateModal, hideUpdateModal] = useModal(
        <DocsCategoryUpdate
            item={item}
            onUpdate={() => {
                onUpdate()
                hideUpdateModal()
            }} onCancel={() => {
                hideUpdateModal()
            }} />,
    )

    return (
        <Fragment>
            <tr>
                <td>
                    {item.name}
                </td>

                <td className="is-flex is-justify-content-flex-end">
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                </td>
            </tr>
        </Fragment>
    )
}
