import React from 'react'
import useTrans from '../hooks/trans'

export default function BranchReportNotFilledMessage() {
    const t = useTrans()

    return (
        <div className="container mb-5 notification is-info">
            {t('statusNotFilled')}
        </div>
    )
}
