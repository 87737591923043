import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Select from './common/Select'
import { useLoad } from '../hooks/request'
import { DISTRICT_LIST } from '../urls'
import Input from './common/Input'
import useTrans from '../hooks/trans'

export default function TerritorySectorForm({ onSubmit, onCancel, loading, initialValues = {} }) {
    const districts = useLoad({ url: DISTRICT_LIST })
    const t = useTrans()

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            ...initialValues,
            district: initialValues.district ? initialValues.district.id : '',
            responsible: initialValues.responsible ? initialValues.responsible.id : '',
        }}>
            <Form>
                <Input name="name" label={t('name')} validate={required} autoFocus autoComplete="off" />

                <Select
                    empty
                    name="district"
                    label={t('region')}
                    loading={districts.loading}
                    options={districts.response ? districts.response.results : []}
                    validate={required} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text={t('cancel')}
                        disabled={loading}
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text={t('save')}
                        type="submit"
                        icon="ion-md-checkmark"
                        disabled={loading}
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
