import React from 'react'
import Button from './common/Button'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import ReservoirReportCreate from './ReservoirReportCreate'
import ReservoirReportUpdate from './ReservoirReportUpdate'
import { TODAY } from '../utils/date'

export default function ReservoirItemReport({ reservoir, report, date, editable = true, onUpdate }) {
    const t = useTrans()

    const [showReportCreateModal, hideReportCreateModal] = useModal(
        <ReservoirReportCreate reservoir={reservoir} date={date} onSuccess={() => {
            onUpdate()
            hideReportCreateModal()
        }} onCancel={() => {
            hideReportCreateModal()
        }} />,
    )

    const [showReportUpdateModal, hideReportUpdateModal] = useModal(
        <ReservoirReportUpdate reservoir={reservoir} report={report} onSuccess={() => {
            onUpdate()
            hideReportUpdateModal()
        }} onCancel={() => {
            hideReportUpdateModal()
        }} />,
    )

    return (
        <div>
            {report && report.morningTotal ? report.morningTotal : '-'}<br />
            {report && report.morningInTotal ? report.morningInTotal : '-'}<br />
            {report && report.morningOutTotal ? report.morningOutTotal : '-'}<br />

            {editable && report && TODAY === report.date ? (
                <Button
                    onClick={showReportUpdateModal}
                    text={t('change')}
                    icon="ion-md-create"
                    className="is-warning is-light is-small" />
            ) : null}

            { editable && !report ? (
                <Button
                    onClick={showReportCreateModal}
                    text={t('fill')}
                    icon="ion-md-document"
                    className="is-info is-light is-small" />
            ) : null}
        </div>

    )
}
