import React from 'react'
import map from 'lodash/map'
import Chart from './common/Chart'
import useTrans from '../hooks/trans'

export default function StatisticsWaterLimitChart({ data, loading }) {
    const t = useTrans()

    return (
        <Chart
            loading={loading}
            type="bar"
            height="25rem"
            data={{
                labels: data ? data.map((item) => item.name) : [],
                datasets: [
                    {
                        data: data ? map(data, 'water') : [],
                        backgroundColor: '#48c774',
                        fill: false,
                        borderColor: '#48c774',
                        label: t('water'),
                    },
                    {
                        data: data ? map(data, 'waterLimit') : [],
                        backgroundColor: '#f14668',
                        fill: false,
                        borderColor: '#f14668',
                        label: t('limitWater'),
                    },
                ],
            }}
            options={{
                legend: { position: 'bottom' },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: { weight: 'bold' },
                    },
                },
            }} />
    )
}
