import React, { useState } from 'react'
import Layout from '../components/Layout'
import SeasonTabs from '../components/SeasonTabs'
import useTrans from '../hooks/trans'
import { roleMain } from '../utils/role'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { useLoad } from '../hooks/request'
import { ACTIVE_TYPE_LIST } from '../urls'
import Table from '../components/common/Table'
import ActiveTypeItem from '../components/ActiveTypeItem'

export default function ActiveType() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const activeTypes = useLoad({ url: ACTIVE_TYPE_LIST })
    const t = useTrans()

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <SeasonTabs />

            <div className="is-flex is-justify-content-space-between is-align-items-center my-5">
                <h1 className="title has-text-weight-normal"><span>{t('controlIrrigation')}</span></h1>
            </div>

            <Table
                loading={activeTypes.loading}
                activePage={page}
                pageSize={100}
                onPageChange={setPage}
                totalCount={activeTypes.response ? activeTypes.response.count : 0}
                columns={{ name: t('name'), actions: '' }}
                items={activeTypes.response ? activeTypes.response.results : []}
                renderItem={(item) => (!item.isArchive ? (
                    <ActiveTypeItem
                        onSuccess={activeTypes.request}
                        item={item}
                        key={item.id} />
                ) : null)} />
        </Layout>
    )
}
