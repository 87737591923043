import React, { Fragment } from 'react'
import useTrans from '../hooks/trans'
import Button from './common/Button'

export default function BranchInfo({ item, onCancel }) {
    const t = useTrans()

    return (
        <div>
            <b>
                { item.type === 'knot' && t('aboutKnot').replace('{knot}', item.name) }
                { item.type === 'channel' && t('aboutChannel').replace('{channel}', item.name) }
                { item.type === 'pumpstation' && t('aboutPumpStation').replace('{pumpStation}', item.name) }
                { item.type === 'reservoir' && t('aboutReservoir').replace('{reservoir}', item.name) }
            </b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tr>
                    <td className="has-text-weight-medium">
                        <span className="tag is-primary is-light"><i className="icon ion-md-document" /></span>
                        &nbsp; {t('name')}:
                    </td>
                    <td>{item.name}</td>
                </tr>

                <tr>
                    <td className="has-text-weight-medium">
                        <span className="tag is-primary is-light"><i className="icon ion-md-git-branch" /></span>
                        &nbsp; {t('sources')}:
                    </td>
                    <td>{item.sources.map((s) => (<span> {s.name} <br /></span>))}</td>
                </tr>

                { item.type === 'pumpstation' && item.pumpStation ? (
                    <Fragment>
                        <tr>
                            <td className="has-text-weight-medium">
                                <span className="tag is-info is-light"><i className="icon ion-md-filing" /></span>
                                &nbsp; {t('aggregates')}:
                            </td>
                            <td>{item.pumpStation.aggregates}</td>
                        </tr>

                        <tr>
                            <td className="has-text-weight-medium">
                                <span className="tag is-info is-light"><i className="icon ion-md-flash" /></span>
                                &nbsp; {t('energy')}:
                            </td>
                            <td>{`${item.pumpStation.energy} ${t('mgwt')}`}</td>
                        </tr>

                        <tr>
                            <td className="has-text-weight-medium">
                                <span className="tag is-info is-light"><i className="icon ion-md-water" /></span>
                                &nbsp; {t('waterCapability')}:
                            </td>
                            <td>{`${item.pumpStation.waterCapability} ${t('m3sek')}`}</td>
                        </tr>

                        <tr>
                            <td className="has-text-weight-medium">
                                <span className="tag is-info is-light">
                                    <i className="icon ion-md-arrow-round-up" />
                                </span>
                                &nbsp; {t('waterHeight')}:
                            </td>
                            <td>{`${item.pumpStation.waterHeight} ${t('metr')}`}</td>
                        </tr>

                        <tr>
                            <td className="has-text-weight-medium">
                                <span className="tag is-link is-light"><i className="icon ion-md-flash" /></span>
                                &nbsp; {t('totalEnergy')}:
                            </td>
                            <td>{`${item.pumpStation.totalEnergy} ${t('mgwt')}`}</td>
                        </tr>

                        <tr>
                            <td className="has-text-weight-medium">
                                <span className="tag is-link is-light"><i className="icon ion-md-water" /></span>
                                &nbsp; {t('totalWaterCapability')}:
                            </td>
                            <td>{`${item.pumpStation.totalWaterCapability} ${t('m3sek')}`}</td>
                        </tr>
                    </Fragment>
                ) : null}

                { item.type === 'channel' ? (
                    <tr>
                        <td className="has-text-weight-medium">
                            <span className="tag is-info is-light"><i className="icon ion-md-globe" /></span>
                            &nbsp; {t('border')}:
                        </td>
                        <td>{item.isBorder ? t('yes') : t('no')}</td>
                    </tr>
                ) : null}

                <tr>
                    <td className="has-text-weight-medium">
                        <span className="tag is-success is-light"><i className="icon ion-md-map" /></span>
                        &nbsp; {t('districts')}:
                    </td>
                    <td>{item.districts.map((d) => (<span> {d.name} <br /></span>))}</td>
                </tr>

                <tr>
                    <td className="has-text-weight-medium">
                        <span className="tag is-info is-light"><i className="icon ion-md-person" /></span>
                        &nbsp; {t('reporter')}:
                    </td>
                    <td>{item.reporter}</td>
                </tr>
            </table>

            <div className="is-pulled-right">
                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text={t('close')}
                    className="is-danger" />
            </div>
        </div>
    )
}
