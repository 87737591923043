import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import useTrans from '../hooks/trans'

export default function UsersTabs() {
    const { path } = useRouteMatch()
    const { location } = useHistory()
    const t = useTrans()

    return (
        <div className="tabs mb-2">
            <ul className={cn('is-flex', css(styles.tabs))}>
                <li className={cn({ 'is-active': location.pathname === '/users/responsible' }, css(styles.li))}>
                    <NavLink to="/users/responsible" className={css(styles.itemTabs)}>
                        {t('users')}
                    </NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith('/season/period') })}>
                    <NavLink to="/users/reporters" className={css(styles.itemTabs)}>
                        {t('reporters')}
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    itemTabs: {
        borderStyle: 'none',
    },
    tabs: {
        height: '50px',
    },
    li: {
        padding: '0.5em',
    },
})
