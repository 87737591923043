import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PUMP_STATION_LIST } from '../urls'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'
import PumpStationForm from './PumpStationForm'

export default function PumpStationCreate({ onCancel, onSuccess }) {
    const pumpStationCreate = usePostRequest({ url: PUMP_STATION_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await pumpStationCreate.request({ data })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('adding')}:</b><br /><br />
            <ServerError error={pumpStationCreate.error} />
            <PumpStationForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={pumpStationCreate.loading} />
        </div>
    )
}
