import React from 'react'
import { Form, Formik } from 'formik'
import useTrans from '../hooks/trans'
import { useLoad, usePostRequest } from '../hooks/request'
import { IRRIGATION_PERIOD } from '../urls'
import Loader from './common/Loader'
import Input from './common/Input'
import Button from './common/Button'
import { useMessage } from '../hooks/message'
import { required } from '../utils/validators'

export default function IrrigationPeriod({ type, onCancel, onUpdate }) {
    const url = IRRIGATION_PERIOD.replace('{id}', type.id)
    const t = useTrans()
    const [showMessage] = useMessage()
    const savePeriods = usePostRequest({ url })
    const typePeriods = useLoad({ url })

    if (typePeriods.loading) {
        return <Loader large center padded />
    }

    async function onSubmit(data) {
        const { error } = await savePeriods.request({ data })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        onUpdate()
    }

    function addRow(values, setValues) {
        setValues({ periods: [
            ...values.periods,
            { name: '', capability: '' },
        ] })
    }

    function removeRow(index, values, setValues) {
        const periods = values.periods.filter((_, i) => i !== index)
        setValues({ periods })
    }

    return (
        <div>
            <b>{t('periods')}</b><br /><br />

            <Formik onSubmit={onSubmit} initialValues={{ periods: typePeriods.response }} enableReinitialize>
                {({ values, setValues }) => (
                    <Form>
                        {values.periods && values.periods.map((period, index) => (
                            /* eslint-disable-next-line react/no-array-index-key */
                            <div className="columns is-variable is-1 mb-0" key={`${period.id}_${index}`}>
                                <div className="column">
                                    <Input
                                        name={`periods.${index}.name`}
                                        validate={required}
                                        className="is-small"
                                        step="0.1" />
                                </div>

                                <div className="column">
                                    <Input
                                        name={`periods.${index}.capability`}
                                        className="is-small"
                                        validate={required}
                                        type="number"
                                        step="0.1" />
                                </div>

                                <div className="column is-2">
                                    <Button
                                        onClick={() => removeRow(index, values, setValues)}
                                        icon="ion-md-trash"
                                        className="is-fullwidth is-danger is-small is-light" />
                                </div>
                            </div>
                        ))}

                        <Button
                            onClick={() => addRow(values, setValues)}
                            icon="ion-md-add"
                            text={t('add')}
                            className="is-fullwidth is-success is-small is-light mb-1" />

                        <div className="mt-3">
                            <Button
                                text={t('back')}
                                className="is-danger"
                                onClick={onCancel} />&nbsp; &nbsp;

                            <Button
                                loading={savePeriods.loading}
                                text={t('save')}
                                className="is-success"
                                type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
