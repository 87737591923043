import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useLoad } from '../hooks/request'
import { DISTRICT_LIST } from '../urls'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'
import Table from '../components/common/Table'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleBranch } from '../utils/role'
import useTrans from '../hooks/trans'

export default function BranchReportDistrict() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const districtList = useLoad({ url: DISTRICT_LIST, params: { page } }, [page])
    const t = useTrans()

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <BranchTabs />

            <h5 className="title is-3 has-text-weight-normal pt-3">
                {t('listDistricts')}
            </h5>

            <Table
                loading={districtList.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={districtList.response ? districtList.response.count : 0}
                items={districtList.response ? districtList.response.results : []}
                columns={{ name: t('name') }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>
                            <NavLink to={`/branch/district/${item.id}/report`}>
                                {item.name}
                            </NavLink>
                        </td>
                    </tr>
                )} />
        </Layout>
    )
}
