import React from 'react'
import { Form, Formik } from 'formik'
import useTrans from '../hooks/trans'
import { usePutRequest } from '../hooks/request'
import { BRANCH_DETAIL } from '../urls'
import Input from './common/Input'
import Button from './common/Button'
import { useMessage } from '../hooks/message'

export default function BranchLevelsCorrection({ branch }) {
    const t = useTrans()
    const [showMessage] = useMessage()
    const url = BRANCH_DETAIL.replace('{id}', branch.id)
    const branchUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const { success } = await branchUpdate.request({
            data: {
                ...data,
                sources: data.sources.map((s) => s.id),
                districts: data.districts.map((district) => district.id),
            },
        })

        if (success) {
            showMessage(t('successSave'), 'is-success')
        }
    }

    return (
        <div>
            <b>{t('branchLevelCorrection')}</b><br /><br />

            <Formik onSubmit={onSubmit} initialValues={branch}>
                <Form>
                    <div className="columns is-variable is-1 mb-0">
                        <div className="column">
                            <Input
                                name="correction"
                                type="number"
                                step="0.1" />
                        </div>

                        <div className="column is-narrow">
                            <Button
                                loading={branchUpdate.loading}
                                text={t('save')}
                                className="is-success"
                                type="submit" />
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}
