import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { getEnv } from './utils/environment'

import ProtectedRoute from './components/common/ProtectedRoute'
import ConfirmEmail from './pages/ConfirmEmail'
import NotFound from './pages/NotFound'
import BaseContextWrapper from './components/common/BaseContext'
import ResetPassword from './pages/ResetPassword'
import ResetLink from './pages/ResetLink'
import ResponsibleSignUp from './pages/ResponsibleSignUp'
import Season from './pages/Season'
import ActiveType from './pages/ActiveType'
import EmailConfirmationMessage from './pages/EmailConfirmationMessage'
import ResetPasswordMessage from './pages/ResetPasswordMessage'
import SettingsUser from './pages/SettingsUser'
import DistrictPlans from './pages/DistrictPlans'
import DistrictReportItem from './pages/DistrictReportItem'
import DistrictReports from './pages/DistrictReports'
import DistrictSectors from './pages/DistrictSectors'
import DistrictArchives from './pages/DistrictArchives'
import PlanDistrictList from './pages/PlanDistrictList'
import Districts from './pages/Districts'
import TerritorySectors from './pages/TerritoySectors'
import Territory from './pages/Territory'
import BranchArchives from './pages/BranchArchives'
import Branch from './pages/Branch'
import BranchSettings from './pages/BranchSettings'
import BranchReports from './pages/BranchReports'
import BranchReportDistrict from './pages/BranchReportDistrict'
import BranchArchiveDistrict from './pages/BranchArchiveDistrict'
import Dashboard from './pages/Dashboard'
import { LanguageProvider } from './contexts/LanguageContext'
import Docs from './pages/Docs'
import DocsCategory from './pages/DocsCategory'
import DocsTopic from './pages/DocsTopic'
import { ThemeProvider } from './contexts/ThemeContext'
import Mails from './pages/Mails'
import DistrictDetailArchives from './pages/DistrictDetailArchives'
import PumpStationReport from './pages/PumpStationReport'
import PumpStationSettings from './pages/PumpStationSettings'
import IrrigationType from './pages/IrrigationType'
import Reservoir from './pages/Reservoir'
import Hydroposts from './pages/Hydroposts'
import Login from './pages/Login'
import Responsible from './pages/Responsible'
import Reporters from './pages/Reporters'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
        document.querySelector("link[rel='shortcut icon']").href = `/${getEnv()}.favicon.png`
    }, [])

    return (
        <BrowserRouter>
            <ThemeProvider>
                <LanguageProvider>
                    <BaseContextWrapper>
                        <Switch>
                            <Route path="/" component={Login} exact />
                            <ProtectedRoute path="/dashboard" component={Dashboard} exact />
                            <ProtectedRoute path="/users/responsible" component={Responsible} exact />
                            <ProtectedRoute path="/users/reporters" component={Reporters} exact />
                            <ProtectedRoute path="/invitation/:code" component={ResponsibleSignUp} exact />

                            <ProtectedRoute path="/season" component={Season} exact />
                            <ProtectedRoute path="/season/period" component={ActiveType} exact />
                            <ProtectedRoute path="/season/type" component={IrrigationType} exact />

                            <ProtectedRoute path="/territory-sectors" component={TerritorySectors} exact />
                            <ProtectedRoute path="/territory-sector/:id/territories" component={Territory} exact />

                            <ProtectedRoute path="/district-sector/plan" component={PlanDistrictList} exact />
                            <ProtectedRoute path="/district/:districtId/plan" component={DistrictPlans} exact />
                            <ProtectedRoute path="/district-sector/reports" component={DistrictReports} exact />
                            <ProtectedRoute path="/district/:id/reports" component={DistrictReportItem} exact />
                            <ProtectedRoute path="/district-sector/settings" component={DistrictSectors} exact />
                            <ProtectedRoute path="/district/:id/settings" component={Districts} exact />
                            <ProtectedRoute path="/district/archives" component={DistrictArchives} exact />
                            <ProtectedRoute
                                path="/district/:id/archives"
                                component={DistrictDetailArchives}
                                exact />

                            <ProtectedRoute path="/pump-stations" component={PumpStationReport} exact />
                            <ProtectedRoute path="/pump-stations/archive" component={PumpStationReport} exact />
                            <ProtectedRoute path="/pump-stations/settings" component={PumpStationSettings} exact />

                            <ProtectedRoute path="/reservoir" component={Reservoir} exact />

                            <ProtectedRoute path="/branch" component={Branch} exact />
                            <ProtectedRoute
                                path="/branch/report/district"
                                component={BranchReportDistrict}
                                exact />
                            <ProtectedRoute path="/branch/archives" component={BranchArchives} exact />
                            <ProtectedRoute
                                path="/branch/:branchId/archives"
                                component={BranchArchiveDistrict}
                                exact />
                            <ProtectedRoute path="/branch/settings" component={BranchSettings} exact />

                            <ProtectedRoute
                                path="/branch/report"
                                component={BranchReports}
                                exact />

                            <ProtectedRoute path="/reset-password/:key" component={ResetPassword} exact />
                            <ProtectedRoute path="/reset-link" component={ResetLink} exact />
                            <ProtectedRoute path="/confirm/:confirmationCode" component={ConfirmEmail} exact />
                            <ProtectedRoute
                                path="/email-confirmation-message"
                                component={EmailConfirmationMessage}
                                exact />
                            <ProtectedRoute path="/reset-password-message" component={ResetPasswordMessage} exact />
                            <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                            <ProtectedRoute path="/docs/:topic" component={Docs} exact />
                            <ProtectedRoute path="/docs/edit/category" component={DocsCategory} exact />
                            <ProtectedRoute path="/docs/edit/topic" component={DocsTopic} exact />
                            <ProtectedRoute path="/mails" component={Mails} exact />

                            <ProtectedRoute path="/hydroposts" component={Hydroposts} exact />
                            <Route path="" component={NotFound} exact />
                        </Switch>
                    </BaseContextWrapper>
                </LanguageProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}
