import React, { Fragment } from 'react'
import moment from 'moment'
import Button from './common/Button'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import { useGetRequest } from '../hooks/request'
import { BRANCH_REPORT_DISTRICT } from '../urls'
import { useDownloadExcel } from '../hooks/downloadExcel'
import useTrans from '../hooks/trans'
import { multiReplace } from '../utils/string'

export default function BranchArchiveDistrictItem({ item }) {
    const params = useQueryParams()
    const date = params.date || moment().format(DATE_FORMAT)
    const branchDistrictDetail = useGetRequest(
        { url: BRANCH_REPORT_DISTRICT.replace('{districtId}', item.id), params: { date } },
    )
    const downloadExcel = useDownloadExcel(branchDistrictDetail.request, date, item.name)
    const t = useTrans()

    return (
        <Fragment>
            <tr>
                <td>
                    <p className="pt-2">{item.name}</p>
                </td>

                <td className="has-text-right">
                    <Button
                        className="is-info"
                        icon="ion-md-download"
                        text={t('download')}
                        loading={branchDistrictDetail.loading}
                        onClick={() => downloadExcel(
                            multiReplace(t('noReport'), { '{date}': date, '{name}': item.name }),
                        )} />
                </td>
            </tr>
        </Fragment>
    )
}
