import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { DISTRICT_LIST, IRRIGATION_SYSTEM_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import DistrictCreate from '../components/DistrictCreate'
import DistrictTabs from '../components/DistrictTabs'
import { useUser } from '../hooks/user'
import DistrictItem from '../components/DistrictItem'
import Table from '../components/common/Table'
import { PermissionDenied } from '../components/PermissionDenied'
import { roleDistrictSector } from '../utils/role'
import useTrans from '../hooks/trans'

export default function Districts() {
    const params = useParams()
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const district = useLoad({ url: DISTRICT_LIST,
        params: { withBranches: true, sector: params.id, page } }, [page, params])
    const irrigationSystem = useLoad({ url: IRRIGATION_SYSTEM_LIST })
    const t = useTrans()

    const [showCreateModal, hideCreateModal] = useModal(
        <DistrictCreate
            onSuccess={district.request}
            sector={params.id}
            hideModal={() => hideCreateModal()}
            irrigationSystem={irrigationSystem.response ? irrigationSystem.response.results : []} />,
    )

    if (!roleDistrictSector.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5">
                <h3 className="title is-3 has-text-weight-normal">{t('listDistricts')}</h3>
                {user.role === 'main' ? (
                    <Button
                        text={t('add')}
                        icon="ion-md-add"
                        className="is-link is-outlined"
                        onClick={showCreateModal} />
                ) : null }
            </div>

            <Table
                loading={district.loading}
                activePage={page}
                onPageChange={setPage}
                items={district.response ? district.response.results : []}
                columns={user.role === 'main' ? ({
                    name: t('name'),
                    channel: t('channels'),
                    actions: '',
                }) : { name: t('name'), channel: t('channels') }}
                renderItem={(item) => (
                    <DistrictItem
                        key={item.id}
                        item={item}
                        irrigationSystem={irrigationSystem.response ? irrigationSystem.response.results : []}
                        onSuccess={district.request}
                        onDelete={district.request} />
                )} />
        </Layout>
    )
}
